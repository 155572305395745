import * as React from "react";
import {Appointment, Auth, IconButton} from "../types/type";
import {persistor, store} from "../store";
import {useLoaderData, useNavigate} from "react-router-dom";
import {Provider, useDispatch} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import CustomTable from "../Components/Table/CustomTable";
import {iconUserPlus} from "../types/Icons";
import ChoixEmployeAffectation from "../Components/ChoixEmployeAffectation";
import {fetchAPI} from "../Components/API";
import {flash} from "react-universal-flash";
import {eraseStaticNotifications} from "../Utils/functionManager";

export default function GestionAffectationEmployeRDV(): React.ReactElement {

    const auth: Auth = fetchAPI.authObject;

    const [appointmentAffect, setAppointmentAffect] = React.useState<Appointment>();

    const dispatch = useDispatch();

    const rdvs: Appointment[] = useLoaderData() as Appointment[];

    const [appointments, setAppointments] = React.useState<Appointment[]>(rdvs);

    const headers: string[] = [
        "Service",
        "Nom client",
        "Prénom client"
    ]

    const keys: string[] = [
        "service_name",
        "client_nom",
        "client_prenom"
    ];

    const actions: IconButton[] = [
        {
            icon: iconUserPlus,
            nameEventClick: "select"
        }
    ]


    React.useEffect(() => {

        async function onEmployeConfirm(event: any): Promise<void> {
            const idEmploye = event.detail.idEmploye;
            const idRdv = event.detail.rdv.idRdv;

            await fetchAPI.updateRdv(idRdv, idEmploye.toString(), null, null, auth.jwt).then(response => {
                if (response.status !== 401 && response.status !== 404 && response.status !== 400) {
                    flash(5000, "success", "L'employe à bel et bien été affecté au rendez-vous.");
                }
            });
            const tempAppointments: Appointment[] = [...appointments];

            tempAppointments.splice(appointments.indexOf(appointmentAffect), 1);

            setAppointments(tempAppointments);
            setAppointmentAffect(undefined);
        }

        const idRdvNotifications: string[] = auth.notifications.map(notification => notification.idRdv.toString());

        eraseNotifications(idRdvNotifications);
        dispatch({type: 'ERASE_ALL_NOTIFICATIONS'});

        document.addEventListener("onChoixAffecterEmploye", onEmployeConfirm);

        return () => {
            document.removeEventListener("onChoixAffecterEmploye", onEmployeConfirm);
        }
    }, [appointmentAffect]);

    async function eraseNotifications(idRdvs: string[]): Promise<void> {
        await eraseStaticNotifications(idRdvs);
    }

    function handlerClick(eventName: string, appointment: Appointment): void {
        console.log("Appointment affect !")
        setAppointmentAffect(appointment);
    }

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <div className="customCard">
                    {appointmentAffect ?
                        <ChoixEmployeAffectation jwt={auth.jwt} idAdmin={Number(auth.identifiant)} rdv={appointmentAffect}></ChoixEmployeAffectation>
                        :
                        <CustomTable<Appointment> headers={headers} keys={keys} keyForKeyAttribute="idRdv" objects={appointments}
                                                  actions={actions} handlerClick={handlerClick}></CustomTable>
                    }

                </div>
            </PersistGate>
        </Provider>
    );
}
