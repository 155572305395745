import * as React from 'react';

/**
 * @description Component managing video display
 * @param videoStream Stream video object sending the stream to the video tag
 * @return {JSX.Element}
 * @constructor
 */
export default function Participant ({videoStream}): React.ReactElement {

    let videoRef = React.useRef<HTMLVideoElement>();

    React.useEffect(() => {
        videoRef.current.srcObject = videoStream;
    }, []);

    return (
        <>
            <video style={{width: "30vw", height: "30vh", border: "5px solid green"}} key={videoStream.id} ref={videoRef} autoPlay={true} playsInline={true} controls={true}></video>
        </>
    );
}



