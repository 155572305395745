import {MDBBtn, MDBContainer, MDBInput} from "mdb-react-ui-kit";
import {Form, useLoaderData} from "react-router-dom";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import * as React from 'react';
import {Adress, InterventionSite, LoaderDataGestionInterventionSite} from "../types/type";
import {fetchAPI} from '../Components/API';
import {MapContainer, Marker, TileLayer, useMap} from "react-leaflet";
import {Button} from "react-bootstrap";


const POSITION_CLASSES = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
}

function ChangeView({center, zoom}): null {
    const map = useMap();
    map.setView(center, zoom);
    return null;
}

function ButtonControl({position}) {
    const map = useMap();

    // Memoize the button so he don't rerender with position change of the map
    const button = React.useMemo(
        () => (
            <Button onClick={() => {
                navigator.geolocation.getCurrentPosition((position) => {
                    map.setView([position.coords.latitude, position.coords.longitude], 13)
                }, (e: any) => console.log(e), {enableHighAccuracy: true})

            }}>Ma position</Button>
        ),
        [],
    )

    const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright

    return (
        <div className={positionClass}>
            <div className="leaflet-control leaflet-bar">{button}</div>
        </div>
    );
}

export default function GestionInterventionSite(): React.ReactElement {

    const [isLoading, setIsLoading] = React.useState(false);
    const [options, setOptions] = React.useState<Adress[]>([]);

    const data = useLoaderData() as LoaderDataGestionInterventionSite;

    const initialInterventionSite = data.interventionSite ? data.interventionSite :
        {
            id: -1,
            clientId: -1,
            locationSiteIntervention: "",
            nomSiteIntervention: "",
            longitude: 0,
            latitude: 0
        }

    const [selected, setSelected] = React.useState<[]>([]);

    const [coordinates, setCoordinates] = React.useState<[number, number]>([initialInterventionSite.latitude, initialInterventionSite.longitude]);
    const [cityName, setCityName] = React.useState<string>(initialInterventionSite.locationSiteIntervention);

    const [interventionSite, setInterventionSite] = React.useState<InterventionSite>(initialInterventionSite);


    async function handleSearch(adress: string) {
        if (adress.replace(" ", "").length >= 3) {

            setIsLoading(true);

            const options: Adress[] = await fetchAPI.getAdress(adress);

            setOptions(options);
            setIsLoading(false);
        }
    }

    const filterBy = () => true;

    function citySelected(choice: any) {
        const city = choice[0];

        if (city) {
            setSelected(choice);
            setCoordinates([city.coordinates[1], city.coordinates[0]]);
            setCityName(city.cityName);

            console.log(city);
        } else {
            setSelected([]);
            setCityName("");
            setCoordinates([0, 0]);
        }
    }


    return (
        <div className="customCard">

            <div className="siteChoiceContainer">
                <section className="interventionSiteSection">
                    <h2 style={{textAlign: "center"}}>{data.interventionSite ? "Formulaire de modification site d'intervention" : "Formulaire de création de site d'intervention"}</h2>

                    <Form method="post" action="">
                        <MDBContainer className="p-3 my-5 d-flex flex-column w-75">

                            <MDBInput required={true} wrapperClass='mb-4' name="nom" label='Nom' id='form1' type='text'
                                      value={interventionSite.nomSiteIntervention}
                                      onChange={(e) => setInterventionSite({
                                          ...interventionSite,
                                          "nomSiteIntervention": e.target.value
                                      })}/>

                            <AsyncTypeahead
                                filterBy={filterBy}
                                isLoading={isLoading}
                                highlightOnlyResult
                                minLength={3}
                                className="mb-4"
                                id="basic-typeahead-single"
                                labelKey="cityName"
                                onSearch={handleSearch}
                                options={options}
                                defaultInputValue={interventionSite.locationSiteIntervention}
                                placeholder="Adresse"
                                selected={selected}
                                onChange={citySelected}
                            />

                            <MDBInput hidden readonly id='form2' name="cityName" type="text"
                                      value={cityName}></MDBInput>
                            <MDBInput hidden readonly name="coordinates" value={coordinates}></MDBInput>
                            <MDBInput hidden readonly name="action" value={data.action}></MDBInput>
                            <MDBInput hidden readonly name="idSite" value={data.idSite}></MDBInput>

                            <MDBBtn className="mb-4"
                                    type="submit">{data.interventionSite ? "Modifier le site d'intervention" : "Enregistrer nouveau site d'intervention"}</MDBBtn>
                        </MDBContainer>
                    </Form>
                </section>

                <aside className="asideMapContainer">
                    <MapContainer center={coordinates} zoom={15} scrollWheelZoom={true}>
                        <ChangeView center={coordinates} zoom={15}></ChangeView>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <ButtonControl position="topright"></ButtonControl>

                        <Marker position={coordinates}>

                        </Marker>

                    </MapContainer>

                </aside>

            </div>


        </div>
    );
}

/*
 {sites.map((site) => (
                            <Marker eventHandlers={{
                                click: (e: any) => {
                                    choiceSite(site.id, [site.latitude, site.longitude]);
                                }
                            }} key={site.id} position={[site.latitude, site.longitude]}>
                                <Popup>
                                    {site.nomSiteIntervention}
                                </Popup>
                            </Marker>
                        ))}
 */
