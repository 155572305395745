import {fetchAPI} from "../../../Components/API";
import {flash} from "react-universal-flash";
import {redirect} from "react-router-dom";
import {Auth} from "../../../types/type";

export default async function modifUser ({request}): Promise<Response> {
    const formData = await request.formData();
    const authObject: Auth = fetchAPI.authObject;

    const data = {
        nom: formData.get("nom"),
        prenom: formData.get("prenom"),
        login: formData.get("login"),
        mdp: formData.get("mdp"),
        mdpConfirm: formData.get("mdpConfirm"),
        wantEmploye: formData.get("wantEmploye"),
        idChef: formData.get("idChef"),
        authObject: authObject,
        idUser: formData.get("idUser")
    }

    let success: boolean = await verifUser(data);

    return success ? redirect("/gestionUser") : null;
}

async function verifUser(data: any): Promise<boolean> {

    let success: boolean = false;
    let pwdTested: boolean = false;

    if (data.mdp.length !== 0) {
        if (data.mdp.length < 8) {
            flash(5000, "warning", "Le mot de passe doit contenir au moins 8 caractères.");
        }else if (!/\d/.test(data.mdp)){
            flash(5000, "warning", "Le mot de passe doit contenir au moins un chiffre.");
        } else if (!/[A-Z]/.test(data.mdp)){
            flash(5000, "warning", "Le mot de passe doit contenir au moins une majuscule.");
        } else if (data.mdp !== data.mdpConfirm){
            flash(5000, "warning", "Les deux mots de passe saisis sont distincts.")
        } else {
            pwdTested = true;
        }
    } else pwdTested = true;

    if (pwdTested) {
        let nom = data.nom === "" ? null : data.nom;
        let prenom = data.prenom === "" ? null : data.prenom;
        let login = data.login === "" ? null : data.login;
        let mdp = data.mdp === "" ? null : data.mdp;
        let idChef = !data.idChef ? null : data.idChef === "" ? null : data.idChef;

        const response = await fetchAPI.updateUser(data.idUser, nom, prenom, login, mdp, idChef, data.authObject.jwt, null);

        if (response.status === 422) {
            flash(5000, "danger", response.detail);
        } else {
            success = true;
            flash(5000, "success", "L'utilisateur à été modifié avec succès !");
        }
    }

    return success;
}
