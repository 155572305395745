import React, {useEffect} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
//Elements graphiques
import OnlineDetect from "./OnlineDetection/OnlineDetect";
import {PopupIOS} from "./Components/PopupIOS";

function App() {


    useEffect(() => {

    }, []);

    return (
        <>
            <PopupIOS></PopupIOS>
            <div>

            </div>
            <OnlineDetect></OnlineDetect>

        </>
    );
}

export default App;
