import * as React from "react";
import {IconButton, Ticket} from "../types/type";
import {redirect, useLoaderData, useNavigate} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "../store";
import {Provider} from "react-redux";
import CustomTable from "../Components/Table/CustomTable";
import {iconCalendarCheck, iconEdit, iconSearch} from "../types/Icons";
import {getNumberOfPages, sortRdvs} from "../Utils/functionManager";
import PaginationTable from "../Components/PaginationTable";
import Row from "react-bootstrap/Row";


const MAX_ELEMENT_PAGINATION: number = 12;

export default function TicketManagement(): React.ReactElement {

    const tickets: Ticket[] = useLoaderData() as Ticket[];

    const [nbPages, setNbPages] = React.useState<number>();
    const [currentPage, setCurrentPage] = React.useState<number>(1);

    tickets.sort((a, b) => a.date.getTime() - b.date.getTime());

    React.useEffect(() => {
        setNbPages(getNumberOfPages(tickets, MAX_ELEMENT_PAGINATION));
    }, [])

    const navigate = useNavigate();

    const buttonAction: IconButton[] = [
        {
            icon: iconSearch,
            nameEventClick: "detail"
        }
    ]

    const buttonActionAppointmentAssigned: IconButton = {
        icon: iconCalendarCheck,
        nameEventClick: "seeAppointment"
    }

    function handler(eventName: string, target: Ticket): void {
        switch (eventName) {
            case "detail":
                navigate(`/ticketManagement/${target.id}`,{replace: true});
                break;
            case "seeAppointment":
                break;
        }


    }

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <div className="customCard">
                    <CustomTable<Ticket> headers={["Titre", "Nom", "Prenom", "Adresse"]}
                                         keys={["name", "client_lastName", "client_firstName", "adress_locationSiteIntervention"]}
                                         keyForKeyAttribute="id" objects={tickets.slice((currentPage - 1) * MAX_ELEMENT_PAGINATION, currentPage * MAX_ELEMENT_PAGINATION)} actions={buttonAction}
                                         handlerClick={handler} event={{key: "appointment", action: buttonActionAppointmentAssigned}}></CustomTable>

                    <Row style={{marginTop: '5%'}}>
                        <PaginationTable style={{justifyContent: "center"}} nbPages={nbPages} currentPage={currentPage}
                                         setCurrentPage={setCurrentPage}/>
                    </Row>
                </div>
            </PersistGate>
        </Provider>
    );
}