import {redirect} from "react-router-dom";
import {flash} from "react-universal-flash";
import {fetchAPI} from "../../../Components/API";
import {Auth} from "../../../types/type";

export default async function createEmploye ({request}): Promise<Response> {
    const formData = await request.formData();
    const authObject: Auth = fetchAPI.authObject;



    const data = {
        nom: formData.get("nom"),
        prenom: formData.get("prenom"),
        login: formData.get("login"),
        mdp: formData.get("mdp"),
        mdpConfirm: formData.get("mdpConfirm"),
        wantEmploye: formData.get("wantEmploye"),
        wantClientPro: formData.get("clientPro"),
        authObject: authObject
    }

    let success: boolean = await verifEmploye(data);


    return success ? redirect("/gestionUser") : null;
}


async function verifEmploye(data): Promise<boolean> {

    let success: boolean = false;

    if (data.mdp.length < 8) {
        flash(5000, "warning", "Le mot de passe doit contenir au moins 8 caractères.");
    }else if (!/\d/.test(data.mdp)){
        flash(5000, "warning", "Le mot de passe doit contenir au moins un chiffre.");
    } else if (!/[A-Z]/.test(data.mdp)){
        flash(5000, "warning", "Le mot de passe doit contenir au moins une majuscule.");
    } else if (data.mdp !== data.mdpConfirm){
        flash(5000, "warning", "Les deux mots de passe saisis sont distincts.")
    } else if (data.nom.length === 0 || data.prenom.length === 0 || data.login.length === 0) {
        flash(5000, "warning", "Il manque des informations pour créer l'employé.")
    } else {

        let role: string[] = [];
        if (data.wantEmploye === "true") role.push("ROLE_EMPLOYE");
        else {
            if (data.wantClientPro !== null) role.push("ROLE_CLIENTPRO");
        }
        const response = await fetchAPI.creerUser(data.nom, data.prenom, data.login, data.mdp, data.authObject.jwt, role);

        if (response.status === 422) {
            flash(5000, "danger", response.detail);
        } else {
            success = true;
            flash(5000, "success", "L'employé à été enregistré avec succès !");
        }

    }

    return success;
}
