import * as React from "react";
import {InputForm, Ticket} from "../types/type";
import {useLoaderData} from "react-router-dom";
import {Provider} from "react-redux";
import {persistor, store} from "../store";
import {PersistGate} from "redux-persist/integration/react";
import {Table, Tbody, Th, Thead, Tr} from "react-super-responsive-table";
import CustomTable from "../Components/Table/CustomTable";
import {getEmptyUserGestion, getNumberOfPages} from "../Utils/functionManager";
import TableUserTicket from "../Components/TableUserTicket";
import {Button, Card, CardHeader} from "react-bootstrap";
import {MDBIcon} from "mdb-react-ui-kit";
import CustomForm from "../Components/Forms/CustomForm";
import TicketFormCreate from "../Components/TicketFormCreate";
import {DataUserTickets} from "../types/loaderType";
import {useMediaQuery} from "react-responsive";




export default function UserTicket(): React.ReactElement {

    const {tickets, sites} = useLoaderData() as DataUserTickets;

    const isMobile: boolean = useMediaQuery({query: `(max-width: 700px)`});

    const [creatingTicket, setCreatingTicket] = React.useState<boolean>(false);

    React.useEffect(() => {
        setCreatingTicket(false);
    }, [tickets.length]);

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <div className="customCard">
                    {creatingTicket ?
                        <div>
                            <TicketFormCreate sites={sites} isMobile={isMobile}></TicketFormCreate>
                        </div>
                        :
                        <div>
                            <Card>
                                <CardHeader style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    fontSize: "large",
                                    fontWeight: "600"
                                }}>
                                    <p style={{
                                        margin: "0",
                                        alignSelf: "center"
                                    }}>Gestion des tickets</p>
                                    <Button onClick={() => setCreatingTicket(true)}>
                                        <MDBIcon style={{marginRight: "0.3vw"}} fas icon="plus"></MDBIcon>
                                        Nouveau ticket
                                    </Button>
                                </CardHeader>
                            </Card>
                            <TableUserTicket tickets={tickets}></TableUserTicket>
                        </div>
                    }
                </div>
            </PersistGate>
        </Provider>
    );
}
