import * as React from 'react';
import {Form, useLoaderData} from "react-router-dom";
import {MDBBtn, MDBContainer, MDBInput} from "mdb-react-ui-kit";
import {Accordion, Button, Card} from "react-bootstrap";
import {Provider} from "react-redux";
import {persistor, store} from "../store";
import OnlineDetect from "../OnlineDetection/OnlineDetect";
import {PersistGate} from "redux-persist/integration/react";
import {DataUserUpdate} from "../types/loaderType";

export default function ModifUser (): React.ReactElement {

    const [isVisible, setIsVisible] = React.useState<boolean>(false);
    const [renderClientPro, setRenderClientPro] = React.useState<React.ReactElement[]>([]);

    const data = useLoaderData() as DataUserUpdate;


    let initialInfosEmploye;
    if (data.typeUser === "employe") {
        initialInfosEmploye = {
            nom: "",
            prenom: "",
            login: "",
            mdp: "",
            mdpConfirm: ""
        };
    } else {
        initialInfosEmploye = {
            nom: "",
            prenom: "",
            login: "",
            mdp: "",
            mdpConfirm: "",
            idChef: ""
        };
    }


    const [infosUser, setInfosUser] = React.useState(initialInfosEmploye);





    React.useEffect(() => {
        getUser().then();
        getRenderClientsPro().then();
    }, []);


    async function getRenderClientsPro() {
        let tabRenderTemp = [];

        const clients = data.usersPro;

        console.log(clients)

        if (clients.length === 0) {
            setRenderClientPro([
                <h2 key="1">Aucun client correspondant</h2>
            ]);
        } else {

            for (let client of clients) {
                tabRenderTemp.push(
                    <Card key={client.id}>
                        <Card.Body>
                            Client pro : {client.firstName} {client.lastName}
                            <Button className="mx-3" variant="primary" onClick={() => setInfosUser({...infosUser, "idChef":client.id})}>Choisir</Button>
                        </Card.Body>
                    </Card>
                );
            }

            setRenderClientPro(tabRenderTemp);
        }
    }

    async function getUser() {
        const user = data.user;

        if (data.typeUser === "employe") {
            setInfosUser({
                nom: user.lastName,
                prenom: user.firstName,
                login: user.login,
                mdp: "",
                mdpConfirm: ""
            });
        } else {
            setInfosUser({
                nom: user.lastName,
                prenom: user.firstName,
                login: user.login,
                mdp: "",
                mdpConfirm: "",
                idChef: user.boss ? user.boss.id : ""
            });
        }
    }


    return (
        <>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>

                    <div className="customCard">

                        <Form method="post" action="">

                            <MDBContainer className="p-3 my-5 d-flex flex-column w-75">

                                <MDBInput value={infosUser.prenom} wrapperClass='mb-4' label='Prénom' id='form1' type='text' name="prenom"
                                          onChange={(e) => setInfosUser({...infosUser, "prenom":e.target.value})}/>

                                <MDBInput value={infosUser.nom} wrapperClass='mb-4' label='Nom' id='form2' type='text' name="nom"
                                          onChange={(e) => setInfosUser({...infosUser, "nom":e.target.value})}/>

                                <MDBInput value={infosUser.login} wrapperClass='mb-4' label='Login' id='form3' type='text' name="login"
                                          onChange={(e) => setInfosUser({...infosUser, "login":e.target.value})}/>


                                <div className="input-group mb-3">
                                    <input
                                        type={isVisible ? "text" : "password"}
                                        className="form-control rounded"
                                        placeholder="Mot de passe"
                                        aria-label="Mot de passe"
                                        aria-describedby="search-addon"
                                        value={infosUser.mdp}
                                        name="mdp"
                                        onChange={(e) => setInfosUser({...infosUser, "mdp":e.target.value})}
                                    />
                                    <span onClick={() => setIsVisible(!isVisible)} className="input-group-text border-0"
                                          id="search-addon"><i className="fas fa-eye"></i></span>
                                </div>

                                <div className="input-group mb-3">
                                    <input
                                        type={isVisible ? "text" : "password"}
                                        className="form-control rounded"
                                        placeholder="Mot de passe"
                                        aria-label="Mot de passe"
                                        aria-describedby="search-addon"
                                        value={infosUser.mdpConfirm}
                                        name="mdpConfirm"
                                        onChange={(e) => setInfosUser({...infosUser, "mdpConfirm":e.target.value})}
                                    />
                                    <span onClick={() => setIsVisible(!isVisible)} className="input-group-text border-0"
                                          id="search-addon"><i className="fas fa-eye"></i></span>
                                </div>

                                {data.typeUser !== "employe" &&
                                    <div className="mb-3">
                                        <MDBInput name="idChef" value={infosUser.idChef ? infosUser.idChef : ""} wrapperClass='mb-4' label='Identifiant client chef' id='form5' type='text'/>
                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Voir clients assignables</Accordion.Header>
                                                <Accordion.Body>
                                                    {renderClientPro}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                }

                                <input readOnly={true} hidden={true} value={(data.typeUser === "employe").toString()} name="wantEmploye"></input>

                                <input readOnly={true} hidden={true} value={data.idUser} name="idUser"></input>

                                <MDBBtn className="mb-4">{data.typeUser === "employe" ? "Modifier employe" : "Modifier client"}</MDBBtn>


                            </MDBContainer>
                        </Form>

                    </div>

                    <OnlineDetect></OnlineDetect>

                </PersistGate>
            </Provider>

        </>
    )


}
