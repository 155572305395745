import * as React from "react";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "../store";
import {Provider} from "react-redux";
import {Appointment, Auth, Ticket} from "../types/type";
import {useLoaderData, useNavigate} from "react-router-dom";
import TicketView from "../Components/TicketView";
import {useMediaQuery} from "react-responsive";
import Planning from "../Components/RDV/Planning";
import {fetchAPI} from "../Components/API";
import PlanningTicket from "../Components/RDV/PlanningTicket";
import {flash} from "react-universal-flash";
import ChoixEmployeAffectation from "../Components/ChoixEmployeAffectation";
import {formatDateToFetchApi, getEmptyAppointment, getEmptySignature} from "../Utils/functionManager";

export default function TicketViewManagement(): React.ReactElement {

    const ticket: Ticket = useLoaderData() as Ticket;

    const [selectHour, setSelectHour] = React.useState<boolean>(false);

    const [selectEmploye, setSelectEmploye] = React.useState<boolean>(false);

    const [rdv, setRdv] = React.useState<Appointment>(getEmptyAppointment());

    const navigate = useNavigate();

    const auth: Auth = fetchAPI.authObject;

    const isMobile: boolean = useMediaQuery({query: `(max-width: 700px)`});

    React.useEffect(() => {

        if (!ticket.view) {
            fetchAPI.patch.ticket(ticket.id.toString(), undefined, true, undefined, undefined, auth.jwt).then();
        }

        function onRdvConfirm(event: any): void {
            setRdv({
                ...rdv,
                horaires: event.detail.horaires,
                date: event.detail.date
            })

            setSelectEmploye(true);
        }

        async function onEmployeConfirm(event: any): Promise<void> {
            try {
                const rdv: Appointment = event.detail.rdv;
                const appointment: Appointment = await fetchAPI.takeRdv(formatDateToFetchApi(rdv.date), rdv.horaires, `Rendez-vous en réponse au ticket #${ticket.id}`, ticket.adress.id.toString(), undefined, auth.jwt, ticket.client.id.toString());
                await fetchAPI.patch.ticket(ticket.id.toString(), appointment.idRdv.toString(), undefined, undefined, undefined, auth.jwt);
                const idEmploye: number = event.detail.idEmploye;

                await fetchAPI.updateRdv(appointment.idRdv, idEmploye.toString(), null, null, auth.jwt);

                flash(3000, "success", "Le ticket à bien été prit en charge.");
                navigate("/ticketManagement");
            } catch(error: any) {
                flash(3000, "danger", error.message);
            }
        }

        document.addEventListener("onRdvConfirm", onRdvConfirm);
        document.addEventListener("onChoixAffecterEmploye", onEmployeConfirm);


        return () => {
            document.removeEventListener("onRdvConfirm", onRdvConfirm);
            document.removeEventListener("onChoixAffecterEmploye", onEmployeConfirm);
        }
    }, [])

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <div className="customCard">
                    {selectEmploye ?
                        <ChoixEmployeAffectation jwt={auth.jwt} idAdmin={Number(auth.identifiant)} rdv={rdv}></ChoixEmployeAffectation>
                        :
                        selectHour ?
                            <PlanningTicket ticket={ticket} jwt={auth.jwt} isMobile={isMobile}></PlanningTicket>
                            :
                            <TicketView jwt={auth.jwt} isMobile={isMobile} ticket={ticket} acceptTicket={() => setSelectHour(true)}></TicketView>
                    }
                </div>
            </PersistGate>
        </Provider>
    );
}
