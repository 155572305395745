import { io } from 'socket.io-client';
import {fetchAPI} from "./Components/API";

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.NODE_ENV === 'production' ? undefined : 'https://pwa.securitys.fr';

export const socket = io(URL, {
    autoConnect: false,
    path: "/chat/socket.io"
});


function onConnect(): void {
    console.info("SOCKET CONNECTED !");
}

function onReady(msg: any): void {
    fetchAPI.authObject.socketConnected = true;
    fetchAPI.authObject.sid = msg.sid;
    socket.emit("ready", fetchAPI.authObject.identifiant);
}

socket.on("ready", onReady);
socket.on("connect", onConnect);




