import * as React from "react";
import {useDispatch} from "react-redux";
import {ReactElement, useRef} from "react";
import {Appointment, Notification, Schedule, Ticket} from "../../types/type";
import {fetchAPI} from "../API";
import {
    capitalizeFirstLetter,
    formatDateToFetchApi, formatDecimalTimeToDisplay,
    getDayNameFromDate,
    getMonthNameFromDate
} from "../../Utils/functionManager";
import ErrorJWT from "../../Errors/ErrorJWT";
import {Button, Form, FormLabel, Table} from "react-bootstrap";
import {MDBAccordion, MDBAccordionItem, MDBInput} from "mdb-react-ui-kit";
import {flash} from "react-universal-flash";
import {socket} from "../../socket";
import {PatternClassic, PatternMessage} from "../../types/regexPattern";
import ModalConfirmation from "../ModalConfirmation";


let dateRdv: Date,
    horaireRdv,
    hoursTakens: number[][],
    dateToHoursAvailable: Map<string, number[]>;


export default function PlanningTicket(
    {
        jwt,
        isMobile,
        ticket
    }: {
        jwt: string,
        isMobile: boolean,
        ticket: Ticket
    }
): React.ReactElement {
    const todayConst: Date = new Date();

    const maxDate: Date = new Date();

    const dispatch = useDispatch();

    const [descriptionModal, setDescriptionModal] = React.useState<string>("");
    const [modalShow, setModalShow] = React.useState<boolean>(false);



    //Journée de départ du tableau qui peut changer suivant le parcours de l'utilisateur (n'est pas forcement aujourd'hui)
    const [today, setToday] = React.useState<Date>(new Date());

    const [render, setRender] = React.useState<ReactElement[]>([]);


    let schedulesWeek: Schedule[];

    /**
     * @description Triggers an event confirming the user's choice of appointment
     */
    function launchEvent(horaires: string[]): void {
        const confirmationRdv = new CustomEvent("onRdvConfirm", {
            detail: {
                date: dateRdv,
                horaires,
            }
        });
        document.dispatchEvent(confirmationRdv);
    }

    React.useEffect(() => {
        getContentTab().then();
    }, [today]);


    async function setSchedules(): Promise<void> {
        schedulesWeek = await getSchedules();
    }

    /**
     * @description Asynchronous function returning all the times of a day that are already in use
     * @param day The day for which you want to know which times are already booked
     * @return {Promise<*[]>}
     */
    async function getRdvTakenForWeek(day: string): Promise<number[][]> {
        return await fetchAPI.getAll.hoursUnavailableForTickets(day, jwt);
    }


    /**
     * @description Retrieves all schedules already taken for each day of the next 7 days, starting today.
     * @return {Promise<*[]>}
     */
    async function getHoraireRdvTaken(): Promise<number[][]> {
        const date: Date = new Date(today.getTime());
        try {
            return await getRdvTakenForWeek(formatDateToFetchApi(date));
        } catch (err: unknown) {
            if (err instanceof ErrorJWT) {
                err.flashError();
                dispatch({type: 'LOGOUT'});
            }
        }

    }

    async function getSchedules(): Promise<Schedule[]> {
        try {
            return await fetchAPI.getSchedules(fetchAPI.authObject.jwt, formatDateToFetchApi(today));
        } catch (err: unknown) {
            if (err instanceof ErrorJWT) {
                err.flashError();
                dispatch({type: 'LOGOUT'});
            }
        }
    }

    function setRenderComputer(): void {
        const render: ReactElement[] = [];

        for (let i = 0; i < 10; i++) {

            render.push(
                <tr key={i}>
                    {Array.from({length: 7}).map((_, index) => {

                        const date: Date = new Date(today.getTime());
                        date.setDate(date.getDate() + index);

                        const hoursAvailable: number[] = dateToHoursAvailable.get(date.toDateString());

                        if (hoursAvailable.includes(8 + i)) {
                            return (
                                <td key={index}>
                                    <Button onClick={handleChoixHoraire} data-horaire={8 + i}
                                            data-date={date}
                                            className="boutonTime">{`${8 + i}h`}</Button>
                                </td>
                            );
                        } else {
                            return (
                                <td key={index}>
                                    <Button style={{visibility: "hidden"}} className="boutonTime">{`${8 + i}h`}</Button>
                                </td>
                            )
                        }
                    })}
                </tr>
            );
        }

        setRender(render);
    }

    function setRenderMobile(): void {

        const render: ReactElement[] = [];

        render.push(
            <MDBAccordion>
                {Array.from({length: 7}).map((_, index) => {
                    let date = new Date(today.getTime());
                    date.setDate(date.getDate() + index);

                    const dayName: string = capitalizeFirstLetter(getDayNameFromDate(date));

                    const dayNumber: number = date.getDate();

                    const monthName: string = getMonthNameFromDate((date));

                    const title: string = `${dayName} ${dayNumber} ${monthName}`;

                    const hoursAvailable: number[] = dateToHoursAvailable.get(date.toDateString());

                    const renderItem: ReactElement[] = [];

                    for (let i = 0; i < 10; i++) {
                        if (hoursAvailable.includes(8 + i)) {
                            renderItem.push(
                                <Button onClick={handleChoixHoraire} data-horaire={8 + i}
                                        data-date={date}
                                        className="boutonTime">{`${8 + i}h`}</Button>
                            );
                        }
                    }

                    return (
                        <MDBAccordionItem key={index} collapseId={index} headerTitle={title}>
                            {renderItem}
                        </MDBAccordionItem>
                    )
                })}
            </MDBAccordion>
        )

        setRender(render);
    }

    /**
     * @description Rendering the schedule in relation to the times already taken for the next 7 days
     * @return {Promise<void>}
     */
    async function getContentTab(): Promise<void> {

        hoursTakens = await getHoraireRdvTaken();
        await setSchedules();

        dateToHoursAvailable = getHoursAvailable();

        if (isMobile) setRenderMobile();
        else setRenderComputer();
    }

    /**
     * Return all hours available for the Appointment for a specific day in the current week the customer see
     */
    function getHoursAvailable(): Map<string, number[]> {
        const hoursAvailable: Map<string, number[]> = new Map<string, number[]>();

        for (let i = 0; i < 7; i++) {
            const dateTemp: Date = new Date(today.getTime());

            dateTemp.setDate(dateTemp.getDate() + i);

            const horairesTaken: number[] = hoursTakens[i];

            const dayInWeek: number = dateTemp.getDay();

            const timeTable: number[] = getTimeTableForDay(dayInWeek);

            let hoursFree: number[] = timeTable;

            if (horairesTaken) {
                hoursFree = getDiffBetweenArray(timeTable, horairesTaken);
            }

            const hoursAvailableTab: number[] = hoursFree.filter((hour) => verifHoraireValide(hour, hoursFree, dateTemp.toDateString()));

            hoursAvailable.set(dateTemp.toDateString(), hoursAvailableTab);
        }

        return hoursAvailable;
    }


    function getDiffBetweenArray(array_1: number[], array_2: number[]): number[] {
        return array_1.filter((element) => !array_2.includes(element));
    }

    function getTimeTableForDay(day: number): number[] {
        const scheduleEdited: Schedule = schedulesWeek.filter((schedule) => schedule.date.getDay() === day)[0];

        if (scheduleEdited) return scheduleEdited.timeTable;
        else if (day !== 6 && day !== 0) return [8, 9, 10, 11, 14, 15, 16, 17]
        else return [];
    }

    function verifHoraireValide(horaire: number, hoursAvailable: number[], dateString: string): boolean {
        const todayHour: number = todayConst.getHours();
        const todayDateString: string = todayConst.toDateString();

        if (todayDateString === dateString && todayHour >= horaire) return false;

        return hoursAvailable.includes(horaire);
    }

    /**
     * Validates the customer's choice of schedule by displaying a confirmation bubble.
     * @param e Event containing all the information about the appointment and times chosen by the customer.
     */
    function handleChoixHoraire(e) {
        dateRdv = new Date(e.target.dataset.date);
        horaireRdv = e.target.dataset.horaire;
        setModalShow(true);
        setDescriptionModal(`Souhaitez vous confirmer la prise d'un rendez vous pour le ${dateRdv.toLocaleDateString('fr-Fr', {weekday: 'long'})} ${dateRdv.toLocaleDateString()} à ${horaireRdv}h`);
    }


    /**
     * @description Function to carry out processing when the desired appointment is validated by the customer
     */
    async function handleConfirmModal() {

        let horaires: string[] = [];

        let maxHour: number;
        const startHour = horaireRdv;

        maxHour = startHour < 14 ? 11 : 17;

        for (let hour = startHour; hour <= maxHour; hour++) {
            horaires.push(hour.toString());
        }

        setModalShow(false);

        await handlePaymentComplete(horaires);
    }

    /**
     * @description Function recording the customer's appointment when validating payment for the requested Service
     * @param horaires Appointment times
     */
    async function handlePaymentComplete(horaires: string[]): Promise<void> {
        setModalShow(false);
        launchEvent(horaires);
    }


    /**
     * Function to obtain the body of the appointment confirmation bubble
     * @return {Element} An element representing the modal body
     */
    function getBodyModal(): React.ReactElement {
        return (
            <>
                {descriptionModal}
            </>
        )


    }

    /**
     * @description Function to advance the schedule by one week
     */
    function nextWeek(): void {
        const tomorow: Date = new Date(today.getTime());
        tomorow.setDate(today.getDate() + 7);

        const lastDayOfTheWeek: Date = new Date(tomorow.getTime());

        lastDayOfTheWeek.setDate(tomorow.getDate() + 6);

        if (maxDate.getTime() >= lastDayOfTheWeek.getTime()) setToday(tomorow);
    }

    /**
     * @description Function to move the schedule back one week
     */
    function previousWeek(): void {
        let yesterday: Date = new Date(today.getTime());
        yesterday.setDate(today.getDate() - 7);

        if (yesterday.getFullYear() >= todayConst.getFullYear()) {
            if (yesterday.getMonth() === todayConst.getMonth()) {
                if (yesterday.getDate() >= todayConst.getDate()) setToday(yesterday);
            } else if (yesterday.getMonth() > todayConst.getMonth()) setToday(yesterday);
        }
    }


    return (
        <>
            {isMobile ?
                <div>

                    <h3 className="tabTimeTitle">Choix de la date & heure</h3>

                    <div className="row" style={{marginBottom: '25px'}}>
                        <div className="col">
                            <Button onClick={previousWeek} style={{float: "left"}} className="primary">{"<"}</Button>
                        </div>
                        <div className="col">
                            <Button onClick={nextWeek} style={{float: "right"}} className="primary">{">"}</Button>
                        </div>
                    </div>

                    {render}
                </div>
                :
                <div>

                    <h3 className="tabTimeTitle">Choix de la date pour le rendez-vous</h3>

                    <div className="row">
                        <div className="col">
                            <Button onClick={previousWeek} style={{float: "left"}} className="primary">{"<"}</Button>
                        </div>
                        <div className="col">
                            <Button onClick={nextWeek} style={{float: "right"}} className="primary">{">"}</Button>
                        </div>
                    </div>

                    <Table responsive>
                        <thead>
                        <tr>
                            {Array.from({length: 7}).map((_, index) => {
                                let date = new Date(today.getTime());
                                date.setDate(date.getDate() + index);
                                return (
                                    <th key={index}>
                                        <div>
                                            {date.toLocaleDateString('fr-Fr', {weekday: 'long'})}
                                        </div>
                                        <span className="dateSpan">
                                                        {date.toLocaleDateString()}
                                                    </span>
                                    </th>
                                )
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        {render}
                        </tbody>
                    </Table>
                </div>
            }

            <ModalConfirmation titre="Confirmation de rendez vous" body={getBodyModal()}
                               fonctionBtnAccept={handleConfirmModal} fonctionBtnRefuse={() => setModalShow(false)}
                               show={modalShow}></ModalConfirmation>


        </>
    );
}
