import {fetchAPI} from "../../../Components/API";
import {redirect} from "react-router-dom";
import {flash} from "react-universal-flash";
import {Auth, DataActionService, Service} from "../../../types/type";
import {compareServices} from "../../../Utils/functionManager";
import {DataActionsHisto} from "../../../types/actionType";

export default async function actionsHistoriqueRdv({request}): Promise<Response> {
    const formData = await request.formData();
    const authObject: Auth = fetchAPI.authObject;

    const data: DataActionsHisto = {
        billNumber: formData.get("billNumber"),
        customerPaid: formData.get("customerPaid"),
        idAppointment: formData.get("idAppointment"),
        auth: authObject,
    }

    let success: boolean;

    success = await verifUpdateAppointment(data);


    return success ? redirect("/historique") : null;
}


async function verifUpdateAppointment(data: DataActionsHisto): Promise<boolean> {

    let success: boolean = false;


    if (data.billNumber && data.billNumber.length === 0) flash(3000, "warning", "Le numéro de facture doit être renseigné.");
    else await updateService();

    async function updateService(): Promise<void> {
        try {
            await fetchAPI.updateRdv(data.idAppointment, null, null, null, data.auth.jwt, null, null, data.billNumber ? data.billNumber : null, data.customerPaid ? data.customerPaid === "true" : null);
            success = true;
        } catch (error: any) {
            console.log(error);
            flash(3000, "danger", `Erreur lors de la mise à jour du rendez-vous ${error.code}`);
        }
    }

    return success;
}
