import {Picture, Signature} from "../types/type";
import * as React from "react";
import {Document, Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer";

const styles = StyleSheet.create({
    page: {
        padding: 30,
    },
    header: {
        fontSize: 20,
        textAlign: 'center',
        marginBottom: 20,
    },
    section: {
        marginBottom: 10,
    },
    table: {
        display: 'flex',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        flexDirection: 'row',
    },
    sectionTable: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    tableCol: {
        width: '25%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCell: {
        margin: 5,
        fontSize: 10,
    },
    footer: {
        marginTop: 20,
        textAlign: 'center',
        fontSize: 12,
    },
});

export default function PdfSignature({ signature }: { signature: Signature }): React.ReactElement {

    if (signature) {
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <Text style={styles.header}>Résumé de l'intervention</Text>

                    <View style={styles.section}>
                        <Text>Intervention #{signature.id}</Text>
                        <Text>Entreprise: Servitys</Text>
                        <Text>Adresse: 1883, route de Nîmes 30560 St Hilaire de Brethmas</Text>
                        <Text>Date: {signature.dateSignature.toLocaleDateString()}</Text>
                        <Text>Nombres d'heures: {signature.nbHours}</Text>
                    </View>

                    <View style={styles.section}>
                        <Text>Description de l'employé : {signature.description}</Text>
                    </View>

                    <View style={styles.section}>
                        <Text style={styles.header}>
                            Photos
                        </Text>

                        {signature.pictures ?
                            signature.pictures.map((picture: Picture) => <Image src={{ uri: picture.imagePath, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}></Image>)
                            :
                            <Text>Aucunes photos disponibles</Text>
                        }
                    </View>

                    <View style={styles.section}>
                        <View style={styles.tableRow}>
                            <View style={styles.sectionTable}>
                                <Image src={{ uri: signature.signatureTechnicien.imagePath, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}></Image>
                            </View>
                            <View style={styles.sectionTable}>
                                <Image src={{ uri: signature.signatureClient.imagePath, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}></Image>
                            </View>
                        </View>
                    </View>

                    <Text style={styles.footer}>Merci pour votre confiance !</Text>
                </Page>
            </Document>
        )
    } else return null;

}