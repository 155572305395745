import * as React from 'react';
import {MDBBtn, MDBContainer, MDBInput, MDBTextArea} from "mdb-react-ui-kit";
import {Form} from "react-router-dom";
import {CustomFormProps, InputForm} from "../../types/type";
import {FormCheck, FormLabel, FormSelect} from "react-bootstrap";
import {PatternMessage, PatternName} from "../../types/regexPattern";

export default function CustomForm(
    {inputs, titleButtonSend, styleButtonSend, classButtonSend, customElement}: CustomFormProps
): React.ReactElement {

    /**
     * @description Check the validity of the form before submit
     * @param e the event of the submit action
     */
    function submit(e) {
        if (!e.target.checkValidity()) {
            e.preventDefault();
            e.target.reportValidity();
        }
    }

    /**
     * @description Checks the validity of the information sent before displaying an error message if the information does not pass the validator
     * @param target
     * @param inputData
     */
    function updateInput(target: any, inputData: InputForm): void {
        const validityState = target.validity;
        if (validityState.patternMismatch) {
            target.setCustomValidity(PatternMessage(target.pattern))
            target.reportValidity();
        } else {
            target.setCustomValidity("")
        }

        target.reportValidity();

        inputData.setValue({[inputData.name]: target.value})
    }

    /**
     * @description Recovers the correct display for the type of input requested
     * @param inputData
     */
    function getDisplayableInput(inputData: InputForm): React.ReactElement {
        const type: string = inputData.type;
        if (type === "textarea") return getTextAreaInput(inputData);
        else if (type === "hidden") return getHiddenInput(inputData);
        else if (type === "checkbox") return getCheckboxInput(inputData);
        else if (type === "select") return getSelectInput(inputData);
        else return getDefaultInput(inputData);
    }

    /**
     * @description Recovers the correct display for a TextArea type input
     * @param inputData
     */
    function getTextAreaInput(inputData: InputForm): React.ReactElement {
        return <MDBTextArea required={inputData.required !== undefined ? inputData.required : true}
                            name={inputData.name} key={inputData.name} wrapperClass='mb-4' value={inputData.value}
                            onChange={(e) => inputData.setValue({[inputData.name]: e.target.value})}
                            label={inputData.label}></MDBTextArea>
    }

    /**
     * @description Recovers the correct display for a hidden input
     * @param inputData
     */
    function getHiddenInput(inputData: InputForm): React.ReactElement {
        return <input required={inputData.required !== undefined ? inputData.required : true} key={inputData.name}
                      readOnly={true}
                      hidden={true} value={inputData.value} name={inputData.name}></input>;
    }

    /**
     * @description Recovers the correct display for a checkbox input
     * @param inputData
     */
    function getCheckboxInput(inputData: InputForm): React.ReactElement {
        return <FormCheck required={inputData.required !== undefined ? inputData.required : true} type="switch"
                          className="mb-4" label={inputData.label} name={inputData.name} key={inputData.name}
                          value={inputData.value} defaultChecked={inputData.value}
                          onChange={(e) => inputData.setValue({[inputData.name]: e.target.checked})}></FormCheck>
    }

    /**
     * @description Recovers the correct display for a select input
     * @param inputData
     */
    function getSelectInput(inputData: InputForm): React.ReactElement {
        return (
            <>
                <FormLabel>{inputData.label}</FormLabel>
                <FormSelect required={inputData.required !== undefined ? inputData.required : true} key={inputData.name}
                            className="mb-4" name={inputData.name} value={inputData.value}
                            onChange={(e) => inputData.setValue({
                                [inputData.name]: {
                                    id: e.target.value,
                                    lastName: ""
                                }
                            })}>
                    <option value="undefined">Rien</option>
                    {inputData.selectItems.map(item => (
                        <option key={item.value} value={item.value}>{item.label}</option>
                    ))}
                </FormSelect>
            </>
        );
    }

    /**
     * Recovers the correct display for a default input
     * @param inputData
     */
    function getDefaultInput(inputData: InputForm): React.ReactElement {
        return <MDBInput required={inputData.required !== undefined ? inputData.required : true}
                         pattern={inputData.pattern ? inputData.pattern : undefined} step="any" min={1}
                         key={inputData.name} wrapperClass='mb-4' label={inputData.label} value={inputData.value}
                         onChange={(e) => updateInput(e.target, inputData)} type={inputData.type}
                         name={inputData.name}></MDBInput>
    }

    return (
        <Form method="post" action="" onSubmit={submit}>
            <MDBContainer className="p-1 my-4 d-flex flex-column w-75">

                {inputs.map((input) => getDisplayableInput(input))}

                {customElement}

                <MDBBtn style={styleButtonSend} className={`mb-4 ${classButtonSend}`} type="submit">{titleButtonSend}</MDBBtn>

            </MDBContainer>
        </Form>
    )
}
