import SignaturePad from "react-signature-pad-wrapper";
import {Button, FloatingLabel, Form} from "react-bootstrap";
import * as React from 'react';
import {fetchAPI} from "./API";
import {flash} from "react-universal-flash";
import {Appointment, Picture, Signature} from "../types/type";
import Slider from "rc-slider";
import 'rc-slider/assets/index.css';
import ButtonIcon from "./Table/tableParts/ButtonIcon";
import {MDBFile, MDBIcon} from "mdb-react-ui-kit";
import {iconHome} from "../types/Icons";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ModalConfirmation from "./ModalConfirmation";
import {PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image, BlobProvider} from '@react-pdf/renderer';
import PdfSignature from "./PdfSignature";


let generatingPdf: boolean = false;

export default function SignatureIntervention({rdv, jwt}: { rdv: Appointment, jwt: string }): React.ReactElement {

    const refSigPadTechnicien = React.useRef<SignaturePad>();

    const refSigPadClient = React.useRef<SignaturePad>();

    const textArea = React.useRef<HTMLTextAreaElement>();

    const [descIntervention, setDescIntervention] = React.useState<string>("");

    const [nbHours, setNbHours] = React.useState<number>(1);

    const [pictures, setPictures] = React.useState<File[]>([]);

    const [showModalConfirm, setShowModalConfirm] = React.useState<boolean>(false);

    const [signatureApi, setSignatureApi] = React.useState<Signature>();


    const responsive = {
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };


    function clearSigPadTechnicien(): void {
        if (refSigPadTechnicien.current) refSigPadTechnicien.current.clear();
    }

    function clearSigPadClient(): void {
        if (refSigPadClient.current) refSigPadClient.current.clear();
    }

    function updateInput(newText: string): void {
        setDescIntervention(newText);
        if (textArea.current) {
            textArea.current.style.height = "inherit";
            textArea.current.style.height = `${textArea.current.scrollHeight}px`;
        }
    }

    function getFile(event: any) {
        let file = event.target.files[0];
        setPictures([...pictures, file])
        //sendMessageAPI(file.name.substring(0, file.name.length - 4), file);
    }

    function updateInputHours(newHours: number | number[]): void {
        const hour: number = newHours as number;
        if (hour < 1) setNbHours(1);
        else if (hour > 10) setNbHours(10);
        else setNbHours(hour);
    }

    function launchEvent(): void {
        let event = new CustomEvent("onSigEnregistred");
        document.dispatchEvent(event);
    }

    function validateIntervention(): void {
        const signatureTechnicien: SignaturePad = refSigPadTechnicien.current;
        const signatureClient: SignaturePad = refSigPadClient.current;
        const canvasTechnicien: HTMLCanvasElement = signatureTechnicien.canvas.current;
        const canvasClient: HTMLCanvasElement = signatureClient.canvas.current;


        const date: Date = new Date();
        const dateToday: string = date.toISOString().slice(0, 10);
        const time: string = `${date.getHours()}:${date.getMinutes()}`;

        canvasTechnicien.toBlob((blob: Blob): void => {
            const nomSignature: string = `sig_${rdv.employe.nom}_${rdv.idRdv}_${dateToday}`;
            const fileTechnicien: File = new File([blob], `${nomSignature}.png`, {type: "image/png"})

            canvasClient.toBlob(async (blob: Blob): Promise<void> => {
                const nomSignature: string = `sig_${rdv.client.nom}_${rdv.idRdv}_${dateToday}`;
                const fileClient: File = new File([blob], `${nomSignature}.png`, {type: "image/png"})

                const pictureSignatureTechnicien: Picture = await fetchAPI.post.picture(fileTechnicien, jwt);
                const pictureSignatureClient: Picture = await fetchAPI.post.picture(fileClient, jwt);

                navigator.geolocation.getCurrentPosition(async (geo: GeolocationPosition) => {
                    const signature: Signature = await fetchAPI.makeSignature(nomSignature, rdv.idRdv.toString(), dateToday, time, pictureSignatureTechnicien.id, pictureSignatureClient.id, geo.coords.latitude, geo.coords.longitude, descIntervention, nbHours, jwt);
                    for (const picture of pictures) {
                        await fetchAPI.post.picture(picture, jwt, signature.id.toString());
                    }
                    const signatureApi: Signature = await fetchAPI.get.signature(signature.id.toString(), jwt);
                    setSignatureApi(signatureApi);
                }, () => {}, {enableHighAccuracy: true})


            }, 'image/png');
        }, 'image/png');
    }

    function validateSig() {
        const signatureTechnicien: SignaturePad = refSigPadTechnicien.current;
        const signatureClient: SignaturePad = refSigPadClient.current;

        if (signatureClient && signatureTechnicien) {
            if (signatureClient.isEmpty() || signatureTechnicien.isEmpty()) flash(3000, "warning", "Il manque une des signatures.");
            else setShowModalConfirm(true);
        }
    }

    function getBodyModalConfirm(): React.ReactElement {

        const missingElements: string[] = [];

        if (descIntervention.length == 0) missingElements.push("Description de l'intervention");
        if (pictures.length == 0) missingElements.push("Photos de l'intervention");

        return (
            <div>
                <div>
                    Voulez-vous valider l'intervention ?
                </div>
                <div>
                    {missingElements.length > 0 &&
                        <ul>
                            {missingElements.map(missingElement => <li>{missingElement}</li>)}
                        </ul>
                    }
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="containerSig">
                <h2 className="text-responsive" style={{textAlign: 'center'}}>Signature technicien</h2>
                <div className="conteneurSignature">
                    <SignaturePad ref={refSigPadTechnicien}></SignaturePad>
                </div>
                <div className="d-flex justify-content-center mt-3">
                    <Button onClick={clearSigPadTechnicien} className="mx-5 text-btn-responsive"
                            variant="danger">Effacer</Button>
                </div>
            </div>

            <div className="containerSig">
                <h2 className="text-responsive" style={{textAlign: 'center'}}>Signature client</h2>
                <div className="conteneurSignature">
                    <SignaturePad ref={refSigPadClient}></SignaturePad>
                </div>
                <div className="d-flex justify-content-center mt-3">
                    <Button onClick={clearSigPadClient} className="mx-5 text-btn-responsive"
                            variant="danger">Effacer</Button>
                </div>
            </div>

            <div className="containerSig">
                <h2 className="text-responsive" style={{textAlign: 'center'}}>Informations sur l'intervention</h2>

                <div className="d-flex justify-content-center">
                    <div className="w-100vh">
                        <h4 className="mt-4 text-responsive">Déroulé de l'intervention</h4>
                        <div>
                            <FloatingLabel
                                controlId="floatingTextarea"
                                label="Description de l'intervention"
                                className="mb-3"
                            >
                                <Form.Control ref={textArea} onChange={(e) => updateInput(e.target.value)}
                                              value={descIntervention}
                                              as="textarea"
                                              placeholder="Ecrivez ici les éléments qui ont selon vous assez d'importances pour être enregistrés afin de décrire l'intervention qui vient d'être réalisée."/>
                            </FloatingLabel>
                        </div>

                        <h4 className="mt-4 text-responsive">Nombre d'heures de travail</h4>
                        <div>
                            <Slider min={1} max={10} value={nbHours} onChange={(e) => updateInputHours(e)}/>
                            <div className="d-flex justify-content-center mt-4 fs-4">
                                <p className="text-responsive">
                                    Vous avez travaillé <input style={{width: '50px'}} min={1} max={10}
                                                               type="number" value={nbHours}
                                                               onChange={(e) => updateInputHours(Number(e.target.value))}/> {nbHours > 1 ? 'heures' : 'heure'}
                                </p>

                            </div>
                        </div>

                        <h4 className="mt-3 text-responsive">Photos</h4>
                        <div>
                            <MDBFile accept="image/png, image/gif, image/jpeg" onChange={getFile}
                                     label="Photos de l'intervention" id='customFile'/>
                            <div className="mt-3">
                                <Carousel swipeable
                                          draggable
                                          showDots
                                          containerClass="carousel-container"
                                          infinite
                                          ssr
                                          responsive={responsive}
                                          deviceType="mobile"
                                          itemClass="carousel-item-padding-40-px">
                                    {pictures.map((img, i) => (
                                        <div key={i}>
                                            <img draggable={false}
                                                 style={{width: "100%", height: "100%", position: "relative"}}
                                                 alt="image" src={URL.createObjectURL(img)}/>
                                        </div>
                                    ))}
                                </Carousel>
                            </div>
                        </div>

                    </div>


                </div>

            </div>


            <div className="conteneurBoutonSignature">
                <Button onClick={validateSig} className="mx-5" variant="primary">Valider intervention</Button>
            </div>

            {signatureApi &&
                <BlobProvider document={<PdfSignature signature={signatureApi} />}>
                    {({ blob, url, loading, error }) => {

                        if (!loading && !generatingPdf) {
                            if (blob) {
                                generatingPdf = true;

                                const pdf: File = new File([blob], `${rdv.idRdv}-${rdv.date.toLocaleDateString()}.pdf`, {type: "application/pdf"});

                                fetchAPI.post.picture(pdf, jwt).then(picture => {
                                    fetchAPI.patch.signature(signatureApi.id.toString(), picture.id.toString(), jwt).then(() => {
                                        flash(3000, "success", 'Intervention validée !');
                                        launchEvent();
                                    });
                                });
                            }
                        }
                        return null;
                    }}
                </BlobProvider>
            }


            <ModalConfirmation titre="Validation de l'intervention" fonctionBtnAccept={validateIntervention}
                               fonctionBtnRefuse={() => setShowModalConfirm(false)} show={showModalConfirm}
                               body={getBodyModalConfirm()}></ModalConfirmation>
        </>
    );
}
