import * as React from 'react';

export default function BadgeNotif({nbNotif, height, width}: { nbNotif: number, height?: number, width?: number }): React.ReactElement {
    const style: React.CSSProperties = {}

    if (height) style.height = `${height}px`;
    if (width) style.width = `${width}px`;

    return (
        <>
            {nbNotif > 0 &&
                <div className="badge wrapper">
                    <div style={style} className="pulse">
                        <div>{nbNotif}</div>
                    </div>
                </div>
            }
        </>
    )
}
