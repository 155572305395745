import * as React from 'react';
import TableUser from "./TableUser";
import {Appointment, ButtonTable, User} from "../types/type";
import {ChoixEmployeAffectationProps} from "../types/propsType";
import {fetchAPI} from "./API";
import {formatDateToFetchApi} from "../Utils/functionManager";


export default function ChoixEmployeAffectation({jwt, idAdmin, rdv}: ChoixEmployeAffectationProps): React.ReactElement {
    const [users, setUsers] = React.useState<User[]>([]);

    const buttons: ButtonTable[] = [
        {
            name: "Affecter",
            eventName: "onChoixEmploye",
            variant: "primary"
        }
    ];

    React.useEffect(() => {
        function launchEvent(event) {
            let idEmploye = event.detail;
            const clickRdv = new CustomEvent("onChoixAffecterEmploye", {
                detail: {
                    idEmploye: idEmploye,
                    rdv: rdv
                }
            });
            document.dispatchEvent(clickRdv);
        }

        document.addEventListener("onChoixEmploye", launchEvent);

        getUsers().then();

        return () => {
            document.removeEventListener("onChoixEmploye", launchEvent);
        }
    }, []);

    async function getUsers(): Promise<void> {
        const rdvsForWeeks: Appointment[] = await fetchAPI.getRdvForWeek(formatDateToFetchApi(rdv.date), jwt);
        const employees: User[] = await fetchAPI.getAllEmployes(jwt, idAdmin);

        const rdvsSameDay: Appointment[] = rdvsForWeeks.filter(appointment => appointment.date.toDateString() == rdv.date.toDateString() && appointment.idRdv != rdv.idRdv && appointment.horaires.filter(value => rdv.horaires.includes(value)).length > 0);

        setUsers(
            employees.filter((employe: User) => {
                const rdvInProgress: Appointment[] = rdvsSameDay.filter(appointment => appointment.employe.id === employe.id);
                return rdvInProgress.length == 0;
            })
        );
    }


    return (
        <TableUser jwt={jwt} idAdmin={idAdmin} buttons={buttons} wantEmploye={true} users={users}></TableUser>
    )
}

