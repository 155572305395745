import {IconMDB} from "../../../types/type";
import * as React from 'react';
import {MDBIcon} from "mdb-react-ui-kit";
import {Button} from "react-bootstrap";

export default function ButtonIcon(
    {icon, handlerClick}: {icon: IconMDB, handlerClick: () => void}
): React.ReactElement {


    return (
        <Button onClick={handlerClick} className="buttonIcon">
            <MDBIcon className="customTableIcon" far={icon.far} fas={icon.fas} icon={icon.name}/>
        </Button>

    )
}
