import {useSelector} from "react-redux";
import {Location, Navigate, Outlet, useLoaderData, useLocation} from "react-router-dom";
import {fetchAPI} from "../Components/API";
import * as React from "react";
import {Auth, InterventionSite} from "../types/type";

export default function ProtectedRoute ({authObject}: {authObject: Auth}): React.ReactElement {

    //TODO FAIRE LA SECURISATION DES NOUVELLES ROUTES AJOUTEES DEPUIS LONGTEMPS LA

    const location: Location<any> = useLocation();

    const regexGestionClient: RegExp = new RegExp(/\/gestionClient\/.+/);
    const regexCreationUser: RegExp = new RegExp(/\/creationUser\/.+/);
    const regexModifUser: RegExp = new RegExp(/\/modifUser\/.+/);
    const regexUpdateSite: RegExp = new RegExp(/\/updateInterventionSite\/.+/);

    if (!authObject.isConnected) return <Navigate to="/login" replace={true}></Navigate>;


    if (location.pathname === "/rdvListe")
        if (!authObject.isEmploye) return <Navigate to="/" replace={true}></Navigate>;


    if (location.pathname === "/gestionUser" ||
        location.pathname === "/affect" ||
        location.pathname === "/gestionRdv" ||
        location.pathname === "/historique" ||
        location.pathname === "/gestionService" ||
        location.pathname === "/gestionHoraires" ||
        location.pathname === "/rdvNonAffect" ||
        regexCreationUser.test(location.pathname) ||
        regexModifUser.test(location.pathname)) if (!authObject.isAdmin) return <Navigate to="/" replace={true}></Navigate>;


    if (location.pathname === "/siteIntervention" ||
        location.pathname === "/gestionClient" ||
        regexGestionClient.test(location.pathname) ||
        location.pathname === "/creationInterventionSite") if (!authObject.isClientPro) return <Navigate replace={true} to="/"></Navigate>;

    if (regexUpdateSite.test(location.pathname)) {
        fetchAPI.getInterventionSiteById(location.pathname.split("/")[1], authObject.jwt).then(async (interventionSite) => {
            if (interventionSite.clientId !== Number(authObject.identifiant)) return <Navigate replace={true} to="/"></Navigate>;
        });
    }

    if (location.pathname === "/rdv" || location.pathname === "/myRdv")
        if (authObject.isEmploye || authObject.isAdmin || authObject.isClientPro) return <Navigate to="/" replace={true}></Navigate>;


    return <Outlet/>;
}
