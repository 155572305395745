import {useLoaderData, useNavigate} from "react-router-dom";
import {Provider} from "react-redux";
import {persistor, store} from "../store";
import OnlineDetect from "../OnlineDetection/OnlineDetect";
import {PersistGate} from "redux-persist/integration/react";
import HistoriqueRdv from "../Components/HistoriqueRdv";
import * as React from 'react';
import {Appointment} from "../types/type";

export default function HistoriqueAdminRdv(): React.ReactElement {

    const navigate = useNavigate();

    const rdvs = useLoaderData() as Appointment[];


    React.useEffect(() => {
        function onDetailRdv(event: any): void {
            navigate(`/gestionRdv/${event.detail.idRdv}`);
        }

        document.addEventListener("onDetailRdv", onDetailRdv);

        return () => {
            document.removeEventListener("onDetailRdv", onDetailRdv);
        }
    }, []);


    return (

        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>

                <div className="customCard">
                    <HistoriqueRdv rdvs={rdvs}></HistoriqueRdv>
                </div>


            </PersistGate>

            <OnlineDetect></OnlineDetect>
        </Provider>

    );
}
