import {Button, Form, FormLabel, Table} from "react-bootstrap";
import {fetchAPI} from "../API";
import ModalConfirmation from "../ModalConfirmation";
import {flash} from "react-universal-flash";
import {useDispatch} from "react-redux";
import * as React from 'react';
import {ReactElement, useRef} from 'react';
import {Appointment, ErrorAPI, isValidErrorAPI, Notification, Schedule} from "../../types/type";
import ErrorJWT from "../../Errors/ErrorJWT";
import {
    capitalizeFirstLetter,
    formatDateToFetchApi,
    formatDecimalTimeToDisplay,
    getDayNameFromDate,
    getMonthNameFromDate
} from "../../Utils/functionManager";
import {MDBAccordion, MDBAccordionItem, MDBInput, MDBTextArea} from "mdb-react-ui-kit";
import {socket} from "../../socket";
import {PatternMessage, PatternClassic} from "../../types/regexPattern";

let dateRdv: Date,
    horaireRdv,
    hoursTakens: number[][],
    dateToHoursAvailable: Map<string, number[]>;


/**
 * A component displaying a calendar allowing customers to choose a time for their appointment
 * @param jwt The jwt retrieved when the user logs in
 * @param durationRdv Appointment duration
 * @param idSite Customer-selected site identifier
 * @param idService
 * @param schedules
 * @param modifRdv Boolean indicating whether to modify an existing appointment
 * @param rdvModif The object representing the appointment you want to modify if you want to modify a rendering
 * @param durationStringRdv
 * @return {Element}
 * @constructor
 */
export default function Planning(
    {
        jwt,
        durationRdv,
        idSite,
        idService,
        maxMonthInterval,
        modifRdv = false,
        rdvModif = null,
        durationStringRdv,
        isMobile,
    }:
        {
            jwt: string,
            durationRdv: number,
            idSite: number,
            idService: number,
            modifRdv?: boolean,
            durationStringRdv?: string
            rdvModif?: Appointment,
            maxMonthInterval: number,
            isMobile: boolean
        }
): React.ReactElement {

    const todayConst: Date = new Date();

    const maxDate: Date = new Date();

    maxDate.setMonth(maxDate.getMonth() + maxMonthInterval);

    const dispatch = useDispatch();

    const [descriptionModal, setDescriptionModal] = React.useState<string>("");
    const [modalShow, setModalShow] = React.useState<boolean>(false);
    const [sujetTextArea, setSujetTextArea] = React.useState<string>("");
    const [showModalPayment, setShowModalPayment] = React.useState<boolean>(true);

    const formSubject = useRef<HTMLFormElement>(null);
    const inputSubject = useRef<HTMLInputElement>(null);

    const refModalPayment = React.useRef<HTMLElement>();


    //Journée de départ du tableau qui peut changer suivant le parcours de l'utilisateur (n'est pas forcement aujourd'hui)
    const [today, setToday] = React.useState<Date>(new Date());

    const [render, setRender] = React.useState<ReactElement[]>([]);

    //const [modalPaiment, setModalPaiment] = React.useState<ReactElement>(<></>);

    let schedulesWeek: Schedule[];

    /**
     * @description Triggers an event confirming the user's choice of appointment
     */
    function launchEvent(): void {
        const confirmationRdv = new CustomEvent("onRdvConfirm");
        document.dispatchEvent(confirmationRdv);
    }

    React.useEffect(() => {
        getContentTab().then();
    }, [today]);


    async function setSchedules(): Promise<void> {
        schedulesWeek = await getSchedules();
    }

    /**
     * @description Asynchronous function returning all the times of a day that are already in use
     * @param day The day for which you want to know which times are already booked
     * @return {Promise<*[]>}
     */
    async function getRdvTakenForWeek(day: string): Promise<number[][]> {
        return await fetchAPI.getAll.hoursUnavailable(day, jwt);
    }


    /**
     * @description Retrieves all schedules already taken for each day of the next 7 days, starting today.
     * @return {Promise<*[]>}
     */
    async function getHoraireRdvTaken(): Promise<number[][]> {
        const date: Date = new Date(today.getTime());
        try {
            return await getRdvTakenForWeek(formatDateToFetchApi(date));
        } catch (err: unknown) {
            if (err instanceof ErrorJWT) {
                err.flashError();
                dispatch({type: 'LOGOUT'});
            }
        }

    }

    async function getSchedules(): Promise<Schedule[]> {
        try {
            return await fetchAPI.getSchedules(fetchAPI.authObject.jwt, formatDateToFetchApi(today));
        } catch (err: unknown) {
            if (err instanceof ErrorJWT) {
                err.flashError();
                dispatch({type: 'LOGOUT'});
            }
        }
    }

    function setRenderComputer(): void {
        const render: ReactElement[] = [];

        for (let i = 0; i < 10; i++) {

            render.push(
                <tr key={i}>
                    {Array.from({length: 7}).map((_, index) => {

                        const date: Date = new Date(today.getTime());
                        date.setDate(date.getDate() + index);

                        const hoursAvailable: number[] = dateToHoursAvailable.get(date.toDateString());

                        if (hoursAvailable.includes(8 + i)) {
                            return (
                                <td key={index}>
                                    <Button onClick={handleChoixHoraire} data-horaire={8 + i}
                                            data-date={date}
                                            className="boutonTime">{`${8 + i}h`}</Button>
                                </td>
                            );
                        } else {
                            return (
                                <td key={index}>
                                    <Button style={{visibility: "hidden"}} className="boutonTime">{`${8 + i}h`}</Button>
                                </td>
                            )
                        }
                    })}
                </tr>
            );
        }

        setRender(render);
    }

    function setRenderMobile(): void {

        const render: ReactElement[] = [];

        render.push(
            <MDBAccordion>
                {Array.from({length: 7}).map((_, index) => {
                    let date = new Date(today.getTime());
                    date.setDate(date.getDate() + index);

                    const dayName: string = capitalizeFirstLetter(getDayNameFromDate(date));

                    const dayNumber: number = date.getDate();

                    const monthName: string = getMonthNameFromDate((date));

                    const title: string = `${dayName} ${dayNumber} ${monthName}`;

                    const hoursAvailable: number[] = dateToHoursAvailable.get(date.toDateString());

                    const renderItem: ReactElement[] = [];

                    for (let i = 0; i < 10; i++) {
                        if (hoursAvailable.includes(8 + i)) {
                            renderItem.push(
                                <Button onClick={handleChoixHoraire} data-horaire={8 + i}
                                        data-date={date}
                                        className="boutonTime">{`${8 + i}h`}</Button>
                            );
                        }
                    }

                    return (
                        <MDBAccordionItem key={index} collapseId={index} headerTitle={title}>
                            {renderItem}
                        </MDBAccordionItem>
                    )
                })}
            </MDBAccordion>
        )

        setRender(render);
    }

    /**
     * @description Rendering the schedule in relation to the times already taken for the next 7 days
     * @return {Promise<void>}
     */
    async function getContentTab(): Promise<void> {

        hoursTakens = await getHoraireRdvTaken();
        await setSchedules();

        dateToHoursAvailable = getHoursAvailable();

        if (isMobile) setRenderMobile();
        else setRenderComputer();
    }

    /**
     * Return all hours available for the Appointment for a specific day in the current week the customer see
     */
    function getHoursAvailable(): Map<string, number[]> {
        const hoursAvailable: Map<string, number[]> = new Map<string, number[]>();


        for (let i = 0; i < 7; i++) {
            const dateTemp: Date = new Date(today.getTime());

            dateTemp.setDate(dateTemp.getDate() + i);

            const horairesTaken: number[] = hoursTakens[i];

            const dayInWeek: number = dateTemp.getDay();

            const timeTable: number[] = getTimeTableForDay(dayInWeek);

            let hoursFree: number[] = timeTable;

            if (horairesTaken) {
                hoursFree = getDiffBetweenArray(timeTable, horairesTaken);
            }

            const hoursAvailableTab: number[] = hoursFree.filter((hour) => verifHoraireValide(hour, hoursFree, dateTemp.toDateString()));

            hoursAvailable.set(dateTemp.toDateString(), hoursAvailableTab);
        }

        return hoursAvailable;
    }


    function getDiffBetweenArray(array_1: number[], array_2: number[]): number[] {
        return array_1.filter((element) => !array_2.includes(element));
    }

    function getTimeTableForDay(day: number): number[] {
        const scheduleEdited: Schedule = schedulesWeek.filter((schedule) => schedule.date.getDay() === day)[0];

        if (scheduleEdited) return scheduleEdited.timeTable;
        else if (day !== 6 && day !== 0) return [8, 9, 10, 11, 14, 15, 16, 17]
        else return [];
    }

    function verifHoraireValide(horaire: number, hoursAvailable: number[], dateString: string): boolean {
        const todayHour: number = todayConst.getHours();
        const todayDateString: string = todayConst.toDateString();
        const duration: number = Math.ceil(durationRdv);

        if (todayDateString === dateString && todayHour >= horaire) return false;

        for (let i = 1; i < duration; i++) {
            if (!hoursAvailable.includes(horaire + i)) return false;
        }

        return true;
    }

    /**
     * @description Checks whether the customer's schedule coincides with the constraints of the type of Service chosen
     * @return {boolean} true if the schedules requested by the customer are correct and false otherwise
     */

    /*
    function verifHoraireValide(): boolean {
        let duration: number = Math.ceil(durationRdv);

        const hourAppointment: number = Number(horaireRdv);

        const horairesTaken: string[] = rdvTakens.get(dateRdv.toDateString());

        const dayInWeek: number = dateRdv.getDay();

        let timeTable: number[] = [];

        const scheduleEdited: Schedule = schedulesWeek.filter((schedule) => schedule.date.getDay() === dayInWeek)[0];

        if (scheduleEdited) timeTable = scheduleEdited.timeTable;
        else if (dayInWeek !== 6 && dayInWeek !== 0) timeTable = [8, 9, 10, 11, 14, 15, 16, 17]

        let hoursAvailable: number[] = timeTable;

        if (horairesTaken) {
            hoursAvailable = timeTable.filter((hour) => !horairesTaken.includes(hour.toString()));
        }

        for (let i = 1; i < duration; i++) {
            if (!hoursAvailable.includes(hourAppointment+i)) return false;
        }

        return true;
    }

     */

    /**
     * Validates the customer's choice of schedule by displaying a confirmation bubble.
     * @param e Event containing all the information about the appointment and times chosen by the customer.
     */
    function handleChoixHoraire(e) {
        dateRdv = new Date(e.target.dataset.date);
        horaireRdv = e.target.dataset.horaire;


        setModalShow(true);

        if (modifRdv) setDescriptionModal(`Souhaitez vous confirmer la modification du rendez-vous pour le ${dateRdv.toLocaleDateString('fr-Fr', {weekday: 'long'})} ${dateRdv.toLocaleDateString()} à ${horaireRdv}h`)
        else setDescriptionModal(`Souhaitez vous confirmer la prise d'un rendez vous pour le ${dateRdv.toLocaleDateString('fr-Fr', {weekday: 'long'})} ${dateRdv.toLocaleDateString()} à ${horaireRdv}h`);

    }


    /**
     * @description Function to carry out processing when the desired appointment is validated by the customer
     */
    async function handleConfirmModal() {
        const dateFormated = dateRdv.toISOString().slice(0, 10);

        let horaires = [];

        horaires.push(horaireRdv.toString());

        for (let i = 1; i < Math.ceil(durationRdv); i++) {
            horaires.push((Number(horaireRdv) + i).toString());
        }

        if (modifRdv) {
            fetchAPI.updateRdv(rdvModif.idRdv, null, dateFormated, horaires, jwt)
                .then(response => {
                    console.log(response);
                    setModalShow(false);
                    flash(5000, "success", "Le rendez-vous à bien été modifié !");
                    launchEvent();
                });
        } else {
            if (formSubject.current) {
                if (formSubject.current.checkValidity()) {
                    setModalShow(false);
                    setShowModalPayment(true);

                    await handlePaymentComplete(dateFormated, horaires); //Pour le moment en attendant le vrai moyen de paiment
                } else {
                    formSubject.current.reportValidity();
                }
            }
        }

    }

    /**
     * @description Function recording the customer's appointment when validating payment for the requested Service
     * @param date The date of the requested appointment
     * @param horaires Appointment times
     */
    async function handlePaymentComplete(date, horaires: string[]): Promise<void> {
        const appointment: Appointment = await fetchAPI.takeRdv(date, horaires, sujetTextArea, idSite.toString(), idService.toString(), jwt);

        console.log(appointment);

        const notif: Notification = await sendNotification(appointment.idRdv);

        socket.emit('takeAppointment', {
            idAppointment: notif.idRdv,
            idReceiver: notif.idUser
        });

        setModalShow(false);
        flash(5000, "success", "Le rendez-vous à bien été confirmé !");
        launchEvent();
    }

    async function sendNotification(idRdv: number): Promise<Notification> {
        try {
            return await fetchAPI.post.notification(idRdv.toString(), "admin", jwt);
        } catch (error: any) {
            console.log(error);
            flash(3000, "danger", "Une ereur est survenue (cf. voir la console)");
            //TODO gestion erreur
        }
    }

    function setSujet(target: any): void {
        const validityState = target.validity;
        if (validityState.patternMismatch) {
            target.setCustomValidity(PatternMessage(target.pattern))
            target.reportValidity();
        } else {
            target.setCustomValidity("")
        }

        target.reportValidity();
        setSujetTextArea(target.value);
    }


    /**
     * Function to obtain the body of the appointment confirmation bubble
     * @return {Element} An element representing the modal body
     */
    function getBodyModal(): React.ReactElement {
        if (modifRdv) {
            return (
                <>
                    {descriptionModal}
                </>
            )
        } else {
            return (
                <>
                    <Form ref={formSubject}>
                        <FormLabel>
                            Sujet
                        </FormLabel>
                        <MDBInput ref={inputSubject} pattern={PatternClassic} required={true}
                                  onChange={(e) => setSujet(e.target)} value={sujetTextArea}
                                  placeholder="Ecrivez quelques indications ici" style={{resize: "none"}}


                        />
                        {descriptionModal}
                    </Form>
                </>
            )
        }

    }

    /**
     * @description Function to retrieve appointment duration in hours and minutes
     * @return {string} A string representing the appointment time in hours and minutes
     */
    function getDuration(): string {
        console.log(`Duration rdv : ${durationRdv}`)
        if (durationRdv > 1 || durationRdv < 1) {
            let splitedDuration = [Math.floor(durationRdv), durationRdv - Math.floor(durationRdv)]
            if (durationRdv > 1) return splitedDuration[0] + "h" + splitedDuration[1] * 60;
            else return splitedDuration[1] + "m"
        } else return durationRdv + "h";
    }

    /**
     * @description Function to advance the schedule by one week
     */
    function nextWeek(): void {
        const tomorow: Date = new Date(today.getTime());
        tomorow.setDate(today.getDate() + 7);

        const lastDayOfTheWeek: Date = new Date(tomorow.getTime());

        lastDayOfTheWeek.setDate(tomorow.getDate() + 6);

        if (maxDate.getTime() >= lastDayOfTheWeek.getTime()) setToday(tomorow);
    }

    /**
     * @description Function to move the schedule back one week
     */
    function previousWeek(): void {
        let yesterday: Date = new Date(today.getTime());
        yesterday.setDate(today.getDate() - 7);

        if (yesterday.getFullYear() >= todayConst.getFullYear()) {
            if (yesterday.getMonth() === todayConst.getMonth()) {
                if (yesterday.getDate() >= todayConst.getDate()) setToday(yesterday);
            } else if (yesterday.getMonth() > todayConst.getMonth()) setToday(yesterday);
        }
    }


    return (
        <>
            {isMobile ?
                <div>

                    <h3 className="tabTimeTitle">Choix de la date & heure</h3>

                    <div className="row" style={{marginBottom: '25px'}}>
                        <div className="col">
                            <Button onClick={previousWeek} style={{float: "left"}} className="primary">{"<"}</Button>
                        </div>
                        <div className="col">
                            <Button onClick={nextWeek} style={{float: "right"}} className="primary">{">"}</Button>
                        </div>
                    </div>

                    {render}
                </div>
                :
                <div>
                    {modifRdv ?
                        <h3 className="tabTimeTitle">Modification du rendez-vous de {rdvModif.horaires[0]}h
                            du {rdvModif.date.toLocaleDateString("fr", {weekday: "long"})} {rdvModif.date.toLocaleDateString()} (durée {durationStringRdv})</h3>
                        :
                        <h3 className="tabTimeTitle">Choix de la date pour rendez-vous d'une durée
                            de {formatDecimalTimeToDisplay(durationRdv)}</h3>

                    }
                    <div className="row">
                        <div className="col">
                            <Button onClick={previousWeek} style={{float: "left"}} className="primary">{"<"}</Button>
                        </div>
                        <div className="col">
                            <Button onClick={nextWeek} style={{float: "right"}} className="primary">{">"}</Button>
                        </div>
                    </div>

                    <Table responsive>
                        <thead>
                        <tr>
                            {Array.from({length: 7}).map((_, index) => {
                                let date = new Date(today.getTime());
                                date.setDate(date.getDate() + index);
                                return (
                                    <th key={index}>
                                        <div>
                                            {date.toLocaleDateString('fr-Fr', {weekday: 'long'})}
                                        </div>
                                        <span className="dateSpan">
                                                        {date.toLocaleDateString()}
                                                    </span>
                                    </th>
                                )
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        {render}
                        </tbody>
                    </Table>
                </div>
            }

            <ModalConfirmation titre="Confirmation de rendez vous" body={getBodyModal()}
                               fonctionBtnAccept={handleConfirmModal} fonctionBtnRefuse={() => setModalShow(false)}
                               show={modalShow}></ModalConfirmation>


        </>
    );
}

