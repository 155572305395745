import {Auth} from "../../../types/type";
import {fetchAPI} from "../../../Components/API";
import {DataActionsHisto, DataActionsTickets} from "../../../types/actionType";
import {redirect} from "react-router-dom";
import {flash} from "react-universal-flash";

export default async function actionsTickets({request}): Promise<Response> {
    const formData = await request.formData();
    const authObject: Auth = fetchAPI.authObject;

    const data: DataActionsTickets = {
        name: formData.get("name"),
        description: formData.get("description"),
        idAdress: formData.get("idAdress"),
        auth: authObject,
    }

    let success: boolean;

    success = await verifCreateTicket(data);

    return success ? redirect("/ticket") : null; //TODO CA NE REFRESH PAS !

}

async function verifCreateTicket(data: DataActionsTickets): Promise<boolean> {

    let success: boolean = false;

    if (!data.name) flash(3000, "warning", "Le titre du ticket doit être renseigné.");
    else if (!data.description) flash(3000, "warning", "La description du ticket doit être renseignée.");
    else if (!data.idAdress) flash(3000, "warning", "L'adresse doit être renseignée.")
    else await createTicket();

    async function createTicket(): Promise<void> {
        try {
            await fetchAPI.post.ticket(data.name, data.description,data.idAdress, data.auth.jwt);
            success = true;
        } catch (error: any) {
            console.log(error);
            flash(3000, "danger", `Erreur lors de la création du ticket ${error.code}`);
        }
    }
    return success;
}
