import * as React from "react";
import {Adress} from "../types/type";
import {fetchAPI} from "./API";
import {MDBBtn, MDBContainer, MDBInput} from "mdb-react-ui-kit";
import {AsyncTypeahead} from "react-bootstrap-typeahead";

export default function AdressSearch({handlerAdressSelected, handlerAdressConfirm}: {
    handlerAdressSelected: (adress: Adress) => void,
    handlerAdressConfirm: (adress: Adress) => void
}): React.ReactElement {

    const [isLoading, setIsLoading] = React.useState(false);
    const [options, setOptions] = React.useState<Adress[]>([]);

    const [selected, setSelected] = React.useState<[]>([]);

    const [cityName, setCityName] = React.useState<string>("");
    const [coordinates, setCoordinates] = React.useState<[number, number]>();

    /**
     * @description Retrieves all addresses matching the search
     * @param adress The name of the address used as a basis for the search
     */
    async function handleSearch(adress: string) {
        if (adress.replace(" ", "").length >= 3) {

            setIsLoading(true);

            const options: Adress[] = await fetchAPI.getAdress(adress);

            setOptions(options);
            setIsLoading(false);
        }
    }

    const filterBy = () => true;

    /**
     * @description Change component states by selecting city
     * @param choice Object containing information about the selected city
     */
    function citySelected(choice: any) {
        const city = choice[0];

        if (city) {
            setSelected(choice);
            setCityName(city.cityName);
            setCoordinates([city.coordinates[1], city.coordinates[0]]);

            handlerAdressSelected({
                cityName: city.cityName,
                coordinates: [city.coordinates[1], city.coordinates[0]],
            });

        } else {
            setSelected([]);
            setCityName("");
        }
    }

    return (
        <MDBContainer className="p-3 my-5 d-flex flex-column w-75">


            <AsyncTypeahead
                filterBy={filterBy}
                isLoading={isLoading}
                highlightOnlyResult
                minLength={3}
                className="mb-4"
                id="basic-typeahead-single"
                labelKey="cityName"
                onSearch={handleSearch}
                options={options}
                placeholder="Adresse"
                selected={selected}
                onChange={citySelected}
            />

            <MDBInput hidden readonly id='form2' name="cityName" type="text" value={cityName}></MDBInput>

            <MDBBtn className="mb-4" type="submit" onClick={() => handlerAdressConfirm({cityName, coordinates})}>Choisir
                cette adresse</MDBBtn>
        </MDBContainer>
    )
}
