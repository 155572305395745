import * as React from "react";
import RdvDetail from "../Components/RdvDetail";
import {Appointment, Auth} from "../types/type";
import {useLoaderData} from "react-router-dom";
import {fetchAPI} from "../Components/API";
import {persistor, store} from "../store";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";

export default function PageRdv(): React.ReactElement {

    const appointment: Appointment = useLoaderData() as Appointment;

    const auth: Auth = fetchAPI.authObject;

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <div className="customCard">
                    <RdvDetail rdv={appointment} jwt={auth.jwt} idUser={Number(auth.identifiant)} isEmploye={auth.isEmploye}></RdvDetail>
                </div>
            </PersistGate>
        </Provider>
    )
}
