import * as React from "react";
import {useDispatch} from "react-redux";
import {Navigate} from "react-router-dom";

export default function Logout (): React.ReactElement {

    const dispatch = useDispatch();

    dispatch({type: 'LOGOUT'});

    return <Navigate to="/login"></Navigate>
}
