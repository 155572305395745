import * as React from 'react';
import {Button} from "react-bootstrap";
import {PopupIOS} from "./PopupIOS";


export default function ButtonInstallApp(): React.ReactElement {

    let deferredPrompt: any = null;

    React.useEffect(() => {

        function assignDeferredPrompt(e: any): void {
            deferredPrompt = e;
        }

        window.addEventListener('beforeinstallprompt', assignDeferredPrompt);


        return () => {
            window.removeEventListener('beforeinstallprompt', assignDeferredPrompt);
        }
    }, []);

    async function handlerInstallApplication(): Promise<void> {
        if (deferredPrompt !== null) {
            deferredPrompt.prompt();
            const {outcome} = await deferredPrompt.userChoice;
            if (outcome === 'accepted') {
                deferredPrompt = null;
            }
        }
    }

    return (
        <>
            <Button className="boutonInstallApp" variant="primary" onClick={handlerInstallApplication}>Installer
                application</Button>
            <PopupIOS></PopupIOS>
        </>

    )
}
