import {Button, Modal} from "react-bootstrap";
import * as React from "react";
import {ModalConfirmationProps} from "../types/type";


export default function ModalConfirmation(
    {titre, fonctionBtnAccept, fonctionBtnRefuse, show, body, form = false}: ModalConfirmationProps
): React.ReactElement {

    return (
        <Modal show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className="customModal">
                <Modal.Title id="contained-modal-title-vcenter">
                    {titre}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer style={form ? {justifyContent: "center"} : {justifyContent: "space-between"}}>
                {!form && <Button onClick={fonctionBtnAccept}>Confirmer</Button>}
                <Button className="btn-secondary" onClick={fonctionBtnRefuse}>Annuler</Button>
            </Modal.Footer>
        </Modal>
    );
}
