import * as React from "react";

//Elements graphiques
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import timeGridPlugin from '@fullcalendar/timegrid'
import frLocale from '@fullcalendar/core/locales/fr';
import 'bootstrap/dist/css/bootstrap.css';
import {useMediaQuery} from 'react-responsive';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {fetchAPI} from "./API";
import {useDispatch} from "react-redux";
import {MoonLoader} from "react-spinners";
import {Appointment, ErrorAPI, EventPlanning, isValidErrorAPI} from "../types/type";
import ErrorJWT from "../Errors/ErrorJWT";
import {formatDateToFetchApi, formatTimeToDisplay, getNbNotif} from "../Utils/functionManager";
import BadgeNotif from "./BadgeNotif";
import {PluginDef} from "@fullcalendar/core";


let firstDayWeek;

export default function PlanningOld(
    {jwt, idEmploye}: {
        jwt: string,
        idEmploye: number
    }
): React.ReactElement {

    const isMobile: boolean = useMediaQuery({query: `(max-width: 760px)`});

    const plugins: PluginDef[] = [timeGridPlugin, dayGridPlugin, interactionPlugin, bootstrap5Plugin]

    const headerTools: {
        right: string,
        left: string,
        center: string
    } = {
        right: "",
        left: "",
        center: ""
    };

    const footerTools: {
        right: string,
        left: string,
        center: string
    } = {
        right: "",
        left: "",
        center: ""
    };

    let initialView: string = "timeGridWeek";





    function launchEvent(data) {
        const clickRdv = new CustomEvent("onRdvClick", {detail: data});
        document.dispatchEvent(clickRdv);
    }

    function startLoading() {
        setLoading(true);
        document.body.style.opacity = "0.5";
    }

    function stopLoading() {
        setLoading(false);
        document.body.style.opacity = "1";
    }



    function customContent(args: any): React.ReactElement {
        const event = args.event;

        const startDay: Date = event.start;
        const endDay: Date = event.end;

        return (
            <div className="fc-event-main-frame">
                <div
                    className="fc-event-time">{`${formatTimeToDisplay(startDay.getHours())}:${formatTimeToDisplay(startDay.getMinutes())} - ${formatTimeToDisplay(endDay.getHours())}:${formatTimeToDisplay(endDay.getMinutes())}`}</div>
                <div className="fc-event-title-container">
                    <div className="fc-event-title fc-sticky">
                        {event.title}
                        <BadgeNotif height={20} width={20}
                                    nbNotif={getNbNotif(event.extendedProps.rdv.idRdv)}></BadgeNotif>
                    </div>

                </div>
            </div>
        )
    }


    function handlerEventClicked(infos) {
        launchEvent(infos.event);
    }

    if (!isMobile) {
        headerTools.right = 'timeGridDay,timeGridWeek';
        headerTools.left = 'today,prev,next';
        headerTools.center = 'title';
    } else {
        initialView = "timeGridDay"
        headerTools.center = 'title';
        footerTools.center = 'prev,today,next';
    }

    function getEvents(info, successCallback, failureCallback) {

        const startDay: Date = info.start;

        fetchAPI.getRdvForWeek(formatDateToFetchApi(startDay), fetchAPI.authObject.jwt)
            .then(appointments => {

                successCallback(
                    appointments.map((appointment) => {

                        if (!appointment.employe || appointment.employe.id.toString() != fetchAPI.authObject.identifiant) return {}

                        const dateStart = new Date(appointment.date);
                        const dateEnd = new Date(appointment.date);

                        dateStart.setHours(parseInt(appointment.horaires[0]));
                        dateEnd.setHours(Number(appointment.horaires[appointment.horaires.length - 1]) + 1);

                        return {
                            title: `${appointment.client.prenom} ${appointment.client.nom}`,
                            start: dateStart.toISOString(),
                            end: dateEnd.toISOString(),
                            rdv: appointment
                        }
                    })
                );
            }).catch((error) => failureCallback(error));
    }

    function setLoading(isLoading: boolean): void {
        const loader: HTMLElement = document.getElementById("loader");
        if (isLoading) {
            loader.style.display = "block";
            document.body.style.opacity = "0.5";
        } else {
            loader.style.display = "none";
            document.body.style.opacity = "1";
        }
    }

    return (
        <>
            <MoonLoader id="loader"
                        cssOverride={{display: "block", position: "absolute", left: "50%", top: "50%", opacity: "1"}}
                        color="#36d7b7" size={80}/>
            <FullCalendar
                plugins={plugins}
                initialView={initialView}
                themeSystem="bootstrap5"
                slotMinTime="07:00:00"
                slotMaxTime="18:00:00"
                height="auto"
                headerToolbar={headerTools}
                footerToolbar={footerTools}
                weekends={false}
                loading={setLoading}
                locale={frLocale}
                events={getEvents}
                eventContent={customContent}
                eventClick={handlerEventClicked}
            />
        </>

    );

}


