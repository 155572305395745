import {
    Adress,
    Appointment,
    ChatMessage,
    InterventionSite,
    MaxMonthInterval,
    Notification, Picture,
    Schedule,
    Service, Signature,
    Ticket,
    Token,
    User
} from "../types/type";
import {
    formalizeAdressFromApi,
    formalizeChatMessages,
    formalizeInterventionSites,
    formalizeNotifications,
    formalizeOneInterventionSite,
    formalizeOneNotification, formalizeOnePicture,
    formalizeOneRdv,
    formalizeOneSchedule,
    formalizeOneService, formalizeOneSignature,
    formalizeOneTicket,
    formalizeOneUser,
    formalizeRdvs,
    formalizeSchedules,
    formalizeServices,
    formalizeTickets,
    formalizeUsers
} from "../Utils/functionManager";
import ApiException from "../Exceptions/ApiException";

const entryPointAPI: string = "https://pwa.securitys.fr/pwa/public/api";


export const fetchAPI = {

    authObject: {
        jwt: undefined,
        refresh_jwt: undefined,
        isAdmin: false,
        isEmploye: false,
        isClientPro: false,
        identifiant: "",
        username: "",
        isConnected: false,
        notifications: [],
        idBoss: "",
        socketConnected: false,
        sid: ""
    },

    post: {
        async notification(idRdv: string, idUser: string, jwt: string): Promise<Notification> {

            const notification: Notification = await fetchAPI.get.notificationByIdUserAndIdRdv(idUser, idRdv, jwt);

            if (!notification) {
                const response: any = await fetch(`${entryPointAPI}/notifications`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwt
                    },
                    body: JSON.stringify({
                        idRdv: idRdv,
                        idUser: idUser,
                        nbNotif: 1
                    })
                });

                const responseJSON = await response.json();

                return formalizeOneNotification(responseJSON);
            } else {
                return await fetchAPI.patch.notification(notification.id, notification.nbNotif + 1, jwt);
            }
        },
        async ticket(name: string, description: string, idSiteIntervention: string, jwt: string): Promise<void> {
            await fetch(`${entryPointAPI}/tickets`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                },
                body: JSON.stringify({
                    name, description, idSiteIntervention
                })
            });
        },
        async picture(file: File, jwt: string, idSignature: string = undefined): Promise<Picture> {

            const formData = new FormData();

            formData.append("file", file);

            if (idSignature) formData.append("idSignature", idSignature);

            const response = await fetch(`${entryPointAPI}/pictures`, {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + jwt
                },
                body: formData
            });

            const responseJSON = await response.json();
            return formalizeOnePicture(responseJSON);
        }
    },


    get: {
        async notificationByIdUserAndIdRdv(idUser: string, idRdv: string, jwt: string): Promise<Notification | undefined> {
            const response: any = await fetch(`${entryPointAPI}/notifications?idUser=${idUser}&idRdv=${idRdv}`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });

            const responseJSON = await response.json();

            const object: any = responseJSON["hydra:member"][0];

            if (!object) return undefined;

            return formalizeOneNotification(object);
        },
        async ticket(idTicket: string, jwt: string): Promise<Ticket> {
            const response = await fetch(`${entryPointAPI}/tickets/${idTicket}`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });

            const responseJSON = await response.json();

            return formalizeOneTicket(responseJSON);
        },
        async signature(idSignature: string, jwt: string): Promise<Signature> {
            const response = await fetch(`${entryPointAPI}/signatures/${idSignature}`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });
            const responseJSON = await response.json();
            return formalizeOneSignature(responseJSON);
        }
    },

    getAll: {
        async notificationsByIdUser(idUser: string, jwt: string): Promise<Notification[]> {
            const response: any = await fetch(`${entryPointAPI}/utilisateurs/${idUser}/notifications`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });

            const responseJSON = await response.json();

            return formalizeNotifications(responseJSON["hydra:member"]);
        },

        async ticketsByIdUser(idUser: string, jwt: string): Promise<Ticket[]> {
            const response: any = await fetch(`${entryPointAPI}/utilisateurs/${idUser}/tickets`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });

            const responseJSON = await response.json();

            return formalizeTickets(responseJSON["hydra:member"]);
        },

        async tickets(jwt: string, getRejectedTickets: boolean = false, getCheckedTickets = false): Promise<Ticket[]> {
            const response: any = await fetch(`${entryPointAPI}/tickets?reject=${getRejectedTickets}&exists[appointment]=${getCheckedTickets}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });

            const responseJSON = await response.json();

            return formalizeTickets(responseJSON["hydra:member"]);
        },

        async hoursUnavailable(startDate: string, jwt: string): Promise<number[][]> {
            const response: any = await fetch(`https://pwa.securitys.fr/pwa/public/hours/${startDate}`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });
            return response.json();
        },
        async hoursUnavailableForTickets(startDate: string, jwt: string): Promise<number[][]> {
            const response: any = await fetch(`https://pwa.securitys.fr/pwa/public/hours/tickets/${startDate}`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });
            return response.json();
        }
    },

    patch: {
        async notification(idNotif: string, nbNotif: number, jwt: string): Promise<Notification> {
            try {
                const response = await fetch(`${entryPointAPI}/notifications/${idNotif}`, {
                    method: "PATCH",
                    headers: {
                        'Authorization': 'Bearer ' + jwt,
                        'Content-Type': 'application/merge-patch+json'
                    },
                    body: JSON.stringify({
                        nbNotif: nbNotif
                    })
                });

                const responseJSON = await response.json();

                return formalizeOneNotification(responseJSON);

            } catch (error: any) {
                throw new ApiException("Erreur lors de la mise à jour de la notification", error.status);
            }
        },
        async ticket(idTicket: string, idAppointment: string, view: boolean, reject: boolean, reasonForReject: string, jwt: string): Promise<Ticket> {
            const objectBody = {};

            if (idAppointment) objectBody["idAppointment"] = idAppointment;
            if (view !== undefined) objectBody["view"] = view;
            if (reject !== undefined) objectBody["reject"] = reject;
            if (reasonForReject) objectBody["reasonForReject"] = reasonForReject;

            try {
                const response = await fetch(`${entryPointAPI}/tickets/${idTicket}`, {
                    method: "PATCH",
                    headers: {
                        'Authorization': 'Bearer ' + jwt,
                        'Content-Type': 'application/merge-patch+json'
                    },
                    body: JSON.stringify(objectBody)
                });

                const responseJSON = await response.json();

                return formalizeOneTicket(responseJSON);

            } catch (error: any) {
                throw new ApiException("Erreur lors de la mise à jour du ticket", error.status);
            }
        },
        async signature(idSignature: string, idPicture: string, jwt: string): Promise<void> {
            try {
                await fetch(`${entryPointAPI}/signatures/${idSignature}`, {
                    method: "PATCH",
                    headers: {
                        'Authorization': 'Bearer ' + jwt,
                        'Content-Type': 'application/merge-patch+json'
                    },
                    body: JSON.stringify({
                        idPdf: idPicture
                    })
                });
            } catch (error: any) {
                throw new ApiException("Erreur lors de la mise à jour de la signature pour le pdf !", error.status);
            }
        }
    },

    delete: {
        async notification(idNotif: string, jwt: string): Promise<void> {
            await fetch(`${entryPointAPI}/notifications/${idNotif}`, {
                method: "DELETE",
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });
        },
        async ticket(idTicket: string, jwt: string): Promise<void> {
            await fetch(`${entryPointAPI}/tickets/${idTicket}`, {
                method: "DELETE",
                headers: {
                    'Authorization': 'Bearer ' + jwt,
                }
            })
        }
    },

    async refreshConnexion(refresh_token: string): Promise<Token> {
        let responseJson: any;

        try {
            const response = await fetch(`${entryPointAPI}/auth/refresh`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    refresh_token: refresh_token
                })
            });

            responseJson = await response.json();
        } catch (e) {
            throw new ApiException("Erreur lors de la connexion", e.status);
        }

        if (responseJson.code) throw new ApiException("Informations de connexion invalides !", responseJson.code);
        else return responseJson;
    },

    async connexion(login: string, motDePasse: string): Promise<Token> {
        let responseJson: any;

        try {
            const response = await fetch(`${entryPointAPI}/auth`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    login: login,
                    mdp: motDePasse
                }),
            });

            responseJson = await response.json();
        } catch (e) {
            throw new ApiException("Erreur lors de la connexion", e.status);
        }

        if (responseJson.code) throw new ApiException("Informations de connexion invalides !", responseJson.code);
        else return responseJson;
    },

    async creerUser(nom: string, prenom: string, login: string, mdp: string, jwt?: string, role: string[] = null, external: boolean = false): Promise<any> {
        let bodyObject: {
            prenom: string,
            nom: string,
            login: string,
            plainPassword: string,
            roles?: string[],
            external: boolean
        } = {
            prenom: prenom,
            nom: nom,
            login: login,
            plainPassword: mdp,
            roles: role !== null ? role : undefined,
            external: external
        }

        try {
            let response;
            if (jwt) {
                response = await fetch(`${entryPointAPI}/utilisateurs`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwt
                    },
                    body: JSON.stringify(bodyObject)
                });
            } else {
                response = await fetch(`${entryPointAPI}/utilisateurs`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(bodyObject)
                });
            }

            return await response.json();

        } catch (e) {
            console.log(e);
            return e;
        }
    },

    async deleteUser(idUser, jwt): Promise<any> {
        try {
            return await fetch(`${entryPointAPI}/utilisateurs/${encodeURI(idUser)}`, {
                method: "DELETE",
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });
        } catch (e) {
            console.log(e);
            throw new ApiException("Erreur lors de la supression d'utilisateur ", e.status);
        }
    },

    async getUser(idUser: number, jwt: string): Promise<User> {
        try {
            const response = await fetch(`${entryPointAPI}/utilisateurs/${idUser}`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });
            const responseJson = await response.json();
            return formalizeOneUser(responseJson);
        } catch (e) {
            console.log(e);
            return e;
        }
    },
    getRdvForWeek(day: string, jwt): Promise<Appointment[]> {
        return fetch(`${entryPointAPI}/rdvs?date=${encodeURI(day)}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => {
            return response.json()
                .then(responseJson => {
                    if (responseJson.code === 401) return responseJson;
                    else return formalizeRdvs(responseJson["hydra:member"]);
                });
        }).catch(e => {
            console.log(e);
            throw new ApiException("Erreur lors de la récupération des rendez-vous pour la semaine", e.code);
        });
    },
    takeRdv(date: string, time: string[], subject: string, idSite: string, idService: string, jwt: string, idClient: string = undefined) {

        const objectBody = {
            sujet: subject,
            date: date,
            horaires: time,
            idSiteIntervention: idSite
        };

        if (idService) objectBody["idService"] = idService;
        if (idClient) objectBody["idClient"] = idClient;


        return fetch(`${entryPointAPI}/rdvs`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
            body: JSON.stringify(objectBody)
        }).then(response => response.json()).then(responseJSON => {
            return formalizeOneRdv(responseJSON);
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    getRdvsUser(idUser, jwt): Promise<Appointment[]> {
        return fetch(`${entryPointAPI}/utilisateurs/${encodeURI(idUser)}/rdvs`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => response.json())
            .then(responseJSON => {
                if (responseJSON.code === 401) return responseJSON;
                else return formalizeRdvs(responseJSON["hydra:member"]);
            }).catch(e => {
                console.log(e);
                return e;
            });
    },
    deleteRdv(idRdv, jwt) {
        return fetch(`${entryPointAPI}/rdvs/${encodeURI(idRdv)}`, {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => {
            return response;
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    getAllMessagesByRdv(idRdv: number, jwt: string): Promise<ChatMessage[]> {
        return fetch(`${entryPointAPI}/rdvs/${idRdv}/messages`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => response.json()).then(responseJSON => {
            if (responseJSON.code === 401) return responseJSON;
            else return formalizeChatMessages(responseJSON["hydra:member"]);
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    createMessage(message, idRdv, date: string, horaire, jwt, file = null) {

        let formData = new FormData();

        formData.append("message", message);
        formData.append("idRdv", idRdv);
        formData.append("date", date);
        formData.append("horaire", horaire);

        if (file !== null) formData.append("file", file);


        return fetch(`${entryPointAPI}/messages`, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + jwt
            },
            body: formData
        }).then(response => response.json()).then(responseJSON => {
            return responseJSON;
        });
    },
    makeSignature(nom: string, idRdv: string, date: string, time, idSignatureTechnicien: number, idSignatureClient: number, latitude: number, longitude: number, description: string, nbHours: number, jwt: string) {
        return fetch(`${entryPointAPI}/signatures`, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + jwt,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                nomSignature: nom,
                idRdv,
                dateSignature: date,
                time,
                nbHours,
                description,
                latitudePost: latitude.toString(),
                longitudePost: longitude.toString(),
                idSignatureTechnicien: idSignatureTechnicien.toString(),
                idSignatureClient: idSignatureClient.toString(),
            })
        }).then(response => response.json()).then(responseJSON => {
            return formalizeOneSignature(responseJSON);
        });
    },
    updateRdv(idRdv, idEmploye, date, horaires, jwt, idSiteIntervention: string = null, idService: string = null, billNumber: string = null, customerPaid: boolean = null) {
        let objetBody: {
            idEmploye?: number,
            date?: Date,
            horaires?: string[],
            idSiteIntervention?: string,
            idService?: string,
            billNumber?: string,
            customerPaid?: boolean
        } = {
            idEmploye: idEmploye !== null ? idEmploye : undefined,
            date: date !== null ? date : undefined,
            horaires: horaires !== null ? horaires : undefined,
            idSiteIntervention: idSiteIntervention !== null ? idSiteIntervention : undefined,
            idService: idService !== null ? idService : undefined,
            billNumber: billNumber !== null ? billNumber : undefined,
            customerPaid: customerPaid !== null ? customerPaid : undefined
        };

        return fetch(`${entryPointAPI}/rdvs/${encodeURI(idRdv)}`, {
            method: "PATCH",
            headers: {
                'Authorization': 'Bearer ' + jwt,
                'Content-Type': 'application/merge-patch+json'
            },
            body: JSON.stringify(objetBody)
        }).then(response => response.json()).then(responseJSON => {
            return responseJSON;
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    updateUser(idUser, nom, prenom, login, mdp, idChef, jwt, roles) {
        let objetBody: {
            nom?: string,
            prenom?: string,
            login?: string,
            plainPassword?: string,
            idChef?: number,
            roles: string[]
        } = {
            nom: nom !== null ? nom : undefined,
            prenom: prenom !== null ? prenom : undefined,
            login: login !== null ? login : undefined,
            plainPassword: mdp !== null ? mdp : undefined,
            idChef: idChef !== null ? idChef : undefined,
            roles: roles !== null ? roles : undefined
        };

        /*
        if (nom !== null) objetBody.nom = nom;
        if (prenom !== null) objetBody.prenom = prenom;
        if (login !== null) objetBody.login = login;
        if (mdp !== null) objetBody.plainPassword = mdp;
        if (idChef !== null) objetBody.idChef = idChef;

         */

        return fetch(`${entryPointAPI}/utilisateurs/${encodeURI(idUser)}`, {
            method: "PATCH",
            headers: {
                'Authorization': 'Bearer ' + jwt,
                'Content-Type': 'application/merge-patch+json'
            },
            body: JSON.stringify(objetBody)
        }).then(response => response.json()).then(responseJSON => {
            return responseJSON
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    updateInterventionSite(idSite: number, nom: string, location: string, latitude: number, longitude: number, jwt: string) {
        return fetch(`${entryPointAPI}/site_interventions/${idSite}`, {
            method: "PATCH",
            headers: {
                'Authorization': 'Bearer ' + jwt,
                'Content-Type': 'application/merge-patch+json'
            },
            body: JSON.stringify({
                nomSiteIntervention: nom,
                locationSiteIntervention: location,
                latitude: latitude,
                longitude: longitude
            })
        }).then(response => response.json()).then(responseJSON => {
            return responseJSON
        }).catch(e => {
            console.log(e);
            return e;
        });

    },
    getAllRdvs(jwt): Promise<Appointment[]> {
        return fetch(`${entryPointAPI}/rdvs/`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => response.json()).then(responseJSON => {
            return formalizeRdvs(responseJSON["hydra:member"]);
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    async getAllEmployes(jwt, idAdmin): Promise<User[]> {
        const users: User[] = await this.getAllUser(jwt, idAdmin);

        return users.filter(objet => objet.id !== idAdmin && objet.roles.includes("ROLE_EMPLOYE"));
    },
    getAllClientsPro(jwt) {
        return fetch(`${entryPointAPI}/utilisateurs`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(respone => respone.json()).then(responseJSON => {
            return responseJSON["hydra:member"].filter(objet => objet.roles.includes("ROLE_CLIENTPRO"));
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    async getAllClient(jwt, idAdmin): Promise<User[]> {
        const users: User[] = await this.getAllUser(jwt, idAdmin);

        return users.filter(objet => objet.id !== idAdmin && !objet.roles.includes("ROLE_EMPLOYE"));
    },
    getAllUser(jwt, idAdmin) {
        return fetch(`${entryPointAPI}/utilisateurs`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(respone => respone.json()).then(responseJSON => {
            return formalizeUsers(responseJSON["hydra:member"]);
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    getRdv(idRdv, jwt): Promise<Appointment> {
        return fetch(`${entryPointAPI}/rdvs/${encodeURI(idRdv)}`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => response.json()).then(responseJSON => {
            return formalizeOneRdv(responseJSON);
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    getAdressByCoordinates(latitude, longitude) {
        return fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=fr`, {
            method: "GET"
        }).then(response => response.json())
            .then(responseJSON => {
                return responseJSON;
            }).catch(e => {
                console.log(e);
                return e;
            });
    },
    getAllSiteInterventionByUserPro(idUser, jwt): Promise<InterventionSite[]> {
        return fetch(`${entryPointAPI}/utilisateurs/${encodeURI(idUser)}/site_interventions`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => response.json()).then(responseJSON => {
            return formalizeInterventionSites(responseJSON["hydra:member"]);
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    creerSiteIntervention(nom, location, latitude, longitude, jwt) {
        return fetch(`${entryPointAPI}/site_interventions`, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + jwt,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                nomSiteIntervention: nom,
                locationSiteIntervention: location,
                latitude: parseFloat(latitude),
                longitude: parseFloat(longitude)
            })
        }).then(response => response.json()).then(responseJSON => {
            return responseJSON;
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    deleteSiteIntervention(idSite, jwt) {
        return fetch(`${entryPointAPI}/site_interventions/${encodeURI(idSite)}`, {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => {
            return response
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    getAllClientByChef(idChef, jwt) {
        return fetch(`${entryPointAPI}/utilisateurs/${encodeURI(idChef)}/clients`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => response.json()).then(responseJSON => {
            if (responseJSON.code) return responseJSON;
            else return formalizeUsers(responseJSON["hydra:member"])
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    createPaymentIntent(amount, jwt) {
        return fetch("https://pwa.securitys.fr/pwa/public/stripe/create-charge", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
            body: JSON.stringify({
                amount: amount
            })
        }).then(response => response.json()).then(responseJSON => {
            return responseJSON
        });
    },
    getInterventionSiteById(idSite, jwt): Promise<InterventionSite> {
        return fetch(`${entryPointAPI}/site_interventions/${idSite}`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => response.json()).then(responseJSON => {
            return formalizeOneInterventionSite(responseJSON);
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    async getAllServices(jwt): Promise<Service[]> {
        try {

            let servicesArray: Service[] = [];

            let response = await fetch(`${entryPointAPI}/services?status=false`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });
            let responseJSON: any = await response.json();
            if (responseJSON.code) return responseJSON;
            else servicesArray = formalizeServices(responseJSON["hydra:member"]);

            let response2 = await fetch(`${entryPointAPI}/services?status=true`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });

            let responseJSON2: any = await response2.json();
            if (responseJSON2.code) return responseJSON2;
            else servicesArray = [...servicesArray, ...formalizeServices(responseJSON2["hydra:member"])];

            return servicesArray;

        } catch (e) {
            console.error(e);
            return e;
        }
    },
    async getServicesForClients(jwt): Promise<Service[]> {
        try {

            let response = await fetch(`${entryPointAPI}/services?status=true`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });
            let responseJSON: any = await response.json();
            if (responseJSON.code) return responseJSON;
            else return formalizeServices(responseJSON["hydra:member"]);

        } catch (e) {
            console.error(e);
            return e;
        }
    },

    getAdress(adress: string): Promise<Adress[]> {
        return fetch(`https://api-adresse.data.gouv.fr/search/?q=${encodeURI(adress)}`).then(response => response.json()).then(responseJSON => {
            return formalizeAdressFromApi(responseJSON["features"]);
        }).catch(e => {
            console.error(e);
            return e;
        });
    },
    async deleteService(idService: number, jwt: string): Promise<any> {
        try {
            return await fetch(`${entryPointAPI}/services/${idService}`, {
                method: "DELETE",
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });
        } catch (e) {
            console.error(e);
            return e;
        }
    },
    async updateService(idService: number, name: string, description: string, duration: number, price: number, status: boolean, jwt: string): Promise<any> {
        try {
            const response = await fetch(`${entryPointAPI}/services/${idService}`, {
                method: "PATCH",
                headers: {
                    'Authorization': 'Bearer ' + jwt,
                    'Content-Type': 'application/merge-patch+json'
                },
                body: JSON.stringify({
                    name: name,
                    duration: duration,
                    price: price,
                    description: description,
                    status: status
                })
            });
            return await response.json();
        } catch (e) {
            console.error(e);
            return e;
        }
    },
    async createService(name: string, description: string, duration: number, price: number, status: boolean, jwt: string): Promise<any> {
        try {
            const response = await fetch(`${entryPointAPI}/services`, {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + jwt,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: name,
                    duration: duration,
                    price: price,
                    description: description,
                    status: status
                })
            });
            return await response.json();
        } catch (e) {
            console.error(e);
            return e;
        }
    },
    async getRdvsUpForService(idService: number, jwt: string): Promise<Array<object>> {
        try {
            const response = await fetch(`${entryPointAPI}/services/${idService}/rdvs?rdvUp=true`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + jwt,
                },
            });
            const responseJSON = await response.json();
            return responseJSON["hydra:member"];
        } catch (e) {
            console.error(e);
            return e;
        }
    },
    async isServiceSafeToEdit(idService: number, jwt: string): Promise<boolean> {
        try {
            const rdvs: object[] = await this.getRdvsUpForService(idService, jwt);
            return rdvs.length === 0;
        } catch (e) {
            console.error(e);
            return e;
        }
    },
    async getService(idService: number, jwt: string): Promise<Service> {
        try {
            const response = await fetch(`${entryPointAPI}/services/${idService}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + jwt,
                }
            });
            const responseJSON = await response.json();
            return formalizeOneService(responseJSON);
        } catch (e) {
            console.error(e);
            return e;
        }
    },
    async getSchedules(jwt: string, date: string): Promise<Schedule[]> {
        try {
            const response = await fetch(`${entryPointAPI}/schedules?date=${encodeURI(date)}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + jwt,
                }
            });

            const responseJSON = await response.json();
            return formalizeSchedules(responseJSON["hydra:member"]);
        } catch (e) {
            throw new ApiException("Erreur lors de la récupération des horaires de l'entreprise", e.status);
        }
    },
    async updateSchedule(idSchedule: number, timeTable: number[], jwt: string): Promise<void> {
        try {
            await fetch(`${entryPointAPI}/schedules/${idSchedule}`, {
                method: "PATCH",
                headers: {
                    'Authorization': 'Bearer ' + jwt,
                    'Content-Type': 'application/merge-patch+json'
                },
                body: JSON.stringify({
                    timeTable: timeTable
                })
            });
        } catch (e) {
            throw new ApiException("Erreur lors de la mise à jour des horaires", e.status);
        }
    },
    async createSchedule(date: string, timeTable: number[], jwt: string): Promise<void> {
        try {
            await fetch(`${entryPointAPI}/schedules`, {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + jwt,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    date: date,
                    timeTable: timeTable
                })
            });
        } catch (e) {
            throw new ApiException("Erreur lors de la création des horaires", e.status);
        }
    },
    async getScheduleById(id: number, jwt: string): Promise<Schedule> {
        try {
            const response = await fetch(`${entryPointAPI}/schedules/${id}`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });

            const responseJSON = await response.json();

            return formalizeOneSchedule(responseJSON);
        } catch (e) {
            throw new ApiException("Erreur lors de la récupération des horaires", e.status);
        }
    },
    async getMaxMonthInterval(jwt: string): Promise<MaxMonthInterval> {
        try {
            const response = await fetch(`${entryPointAPI}/max_month_intervals`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });

            const responseJSON = await response.json();

            return responseJSON["hydra:member"][0]

        } catch (e) {
            throw new ApiException("Erreur lors de la récupération de max month interval", e.status);
        }
    },
    async updateMaxMonthInterval(idMaxMonthInterval: number, nbMonths: number, jwt: string): Promise<void> {
        try {
            await fetch(`${entryPointAPI}/max_month_intervals/${idMaxMonthInterval}`, {
                method: "PATCH",
                headers: {
                    'Authorization': 'Bearer ' + jwt,
                    'Content-Type': 'application/merge-patch+json'
                },
                body: JSON.stringify({
                    nbMonths: nbMonths
                })
            });
        } catch (e) {
            throw new ApiException("Erreur lors de la mise à jour de max month interval", e.status);
        }
    },

}
