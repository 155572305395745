import {Form, useParams} from "react-router-dom";
import {Provider} from "react-redux";
import * as React from 'react';
import {persistor, store} from "../store";
import OnlineDetect from "../OnlineDetection/OnlineDetect";
import {PersistGate} from "redux-persist/integration/react";
import {MDBBtn, MDBCheckbox, MDBContainer, MDBInput} from "mdb-react-ui-kit";



export default function CreationUser(): React.ReactElement {

    const [isVisible, setIsVisible] = React.useState<boolean>(false);
    let params = useParams();


    return (
        <>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>

                    <div className="customCard">

                        <Form method="post" action="">

                            <MDBContainer className="p-3 my-5 d-flex flex-column w-75">

                                <MDBInput name="prenom"  wrapperClass='mb-4' label='Prénom' id='form1' type='text'/>

                                <MDBInput name="nom"  wrapperClass='mb-4' label='Nom' id='form2' type='text'/>

                                <MDBInput name="login"  wrapperClass='mb-4' label='Login' id='form3' type='text'/>


                                <div className="input-group mb-3">
                                    <input
                                        name="mdp"
                                        type={isVisible ? "text" : "password"}
                                        className="form-control rounded"
                                        placeholder="Mot de passe"
                                        aria-label="Mot de passe"
                                        aria-describedby="search-addon"
                                    />
                                    <span onClick={() => setIsVisible(!isVisible)} className="input-group-text border-0"
                                          id="search-addon"><i className="fas fa-eye"></i></span>
                                </div>

                                <div className="input-group mb-3">
                                    <input
                                        name="mdpConfirm"
                                        type={isVisible ? "text" : "password"}
                                        className="form-control rounded"
                                        placeholder="Mot de passe"
                                        aria-label="Mot de passe"
                                        aria-describedby="search-addon"
                                    />
                                    <span onClick={() => setIsVisible(!isVisible)} className="input-group-text border-0"
                                          id="search-addon"><i className="fas fa-eye"></i></span>
                                </div>

                                {params.typeUser !== "employe" &&
                                    <MDBCheckbox name='clientPro' id='flexCheckChecked' label='Client pro ?'/>
                                }

                                <input readOnly={true} hidden={true} value={(params.typeUser === "employe").toString()} name="wantEmploye"></input>


                                <MDBBtn className="mb-4" type="submit">{params.typeUser === "employe" ? "Enregistrer nouveau employe" : "Enregistrer nouveau client"}</MDBBtn>


                            </MDBContainer>
                        </Form>

                    </div>

                    <OnlineDetect></OnlineDetect>

                </PersistGate>
            </Provider>

        </>
    );
}
