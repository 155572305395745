import * as React from 'react';
import {useEffect} from 'react';
import {FormCheck} from "react-bootstrap";
import {CustomTableProps, IconButton} from "../../types/type";
import {Table, Tbody, Td, Th, Thead, Tr} from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import ButtonIcon from "./tableParts/ButtonIcon";

export default function CustomTable<T>(
    {headers, keys, keyForKeyAttribute, objects, actions, handlerClick, event}: CustomTableProps<T>
): React.ReactElement {

    useEffect(() => {

    }, [objects]);

    function getValueOfObjectByKey(keys: string[], object: T): any {
        let value: any = object;
        for (const key of keys) {
            value = value[key];
        }
        return value;
    }

    return (
        <Table style={{textAlign: 'center'}} key={headers.join()}>
            <Thead style={{backgroundColor: "black"}} className="table-dark">
                <Tr>
                    {headers.map((header) => (
                        <Th key={header}>{header}</Th>
                    ))}
                    <Th>Actions</Th>
                </Tr>
            </Thead>
            <Tbody style={{verticalAlign: "middle"}}>
                {objects.map((object: T, index: number) => {
                    const render: React.ReactElement[] = [];

                    let i = 0;

                    for (let key of keys) {

                        const keySplit: any[] = key.split("_");

                        const value: any = getValueOfObjectByKey(keySplit, object);

                        let renderRow: React.ReactElement = <Td
                            key={`${value}_${headers[i]}_${index}`}>{value}</Td>
                        if (typeof value === "boolean") {
                            renderRow =
                                <Td key={`${value}_${headers[i]}_${index}`}>
                                    <FormCheck className="formCheckCustomTable" type="switch" checked={value}
                                               readOnly={true}></FormCheck>
                                </Td>
                        }
                        i++;
                        render.push(renderRow);
                    }

                    return (
                        <Tr key={object[keyForKeyAttribute]}>
                            {render}
                            <Td>
                                {event ?
                                    object[event.key] ?
                                        <ButtonIcon
                                            handlerClick={() => handlerClick(event.action.nameEventClick, object)}
                                            key={event.action.icon.name} icon={event.action.icon}></ButtonIcon>
                                        :
                                        actions.map((action: IconButton) => (
                                            <ButtonIcon handlerClick={() => handlerClick(action.nameEventClick, object)}
                                                        key={action.icon.name} icon={action.icon}></ButtonIcon>
                                        ))
                                    :
                                    actions.map((action: IconButton) => (
                                        <ButtonIcon handlerClick={() => handlerClick(action.nameEventClick, object)}
                                                    key={action.icon.name} icon={action.icon}></ButtonIcon>
                                    ))
                                }

                            </Td>
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    )
}


/*
<td>
                            {buttons.map((button) => (
                                <Button className="buttonCustomTable" key={button.nameEventClick} variant={button.variant} onClick={() => handlerClick(button.nameEventClick, object)}>
                                    <MDBIcon far={button.icon.far} fas={button.icon.fas} icon={button.icon.name}/>
                                    {button.title}
                                </Button>
                            ))}
                        </td>
 */
