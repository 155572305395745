import {MDBBtn, MDBContainer, MDBInput} from "mdb-react-ui-kit";
import * as React from 'react';
import {fetchAPI} from '../API';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Notification, Token, UserConnection} from "../../types/type";
import {flash} from "react-universal-flash";
import servitysLogo from "../../img/servitys.png";
import Row from "react-bootstrap/Row";
import {jwtDecode} from "jwt-decode";

/**
 * @description Component representing the connection form
 * @param handlerChangeToRegistration
 * @constructor
 */
export default function LoginForm({handlerChangeToRegistration}: {
    handlerChangeToRegistration: () => void
}): React.ReactElement {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [userConnection, setUserConnection] = React.useState<UserConnection>({
        loginUser: "",
        pwdUser: ""
    });


    const [isVisible, setIsVisible] = React.useState<boolean>(false);

    /**
     * @description Connect the user
     */
    async function handlerClickLogin(): Promise<void> {
        try {
            await registerJWT();
        } catch (error) {
            flash(3000, "danger", `${error.message} | code : ${error.code}`);
        }
    }

    /**
     * @description Retrieve authentification token from the API and register it inside the application
     */
    async function registerJWT(): Promise<void> {
        const token: Token = await fetchAPI.connexion(userConnection.loginUser, userConnection.pwdUser);

        const decodedJwt = jwtDecode(token.token);

        // @ts-ignore
        const notifications: Notification[] = await fetchAPI.getAll.notificationsByIdUser(decodedJwt.identifiant, token.token);

        dispatch({type: 'SET_JWT', payload: {token: token, notifications: notifications}});
        navigate("/");
    }

    /**
     * @description Change display to register form
     * @param e
     */
    function changeToRegistration(e): void {
        e.preventDefault();
        handlerChangeToRegistration();
    }

    return (
        <MDBContainer className="p-3 my-5 d-flex flex-column loginContainer">

            <img
                className="logoServitysLoginPage"
                src={servitysLogo}
                alt="logo servitys"
            />

            <h2 className="titleConnectionForm">Se connecter</h2>

            <MDBInput value={userConnection.loginUser} wrapperClass='mb-4' label='Login' id='form1' type='text'
                      onChange={(e) => setUserConnection({...userConnection, "loginUser": e.target.value})}/>


            <div className="input-group mb-3">
                <input
                    type={isVisible ? "text" : "password"}
                    className="form-control rounded"
                    placeholder="Mot de passe"
                    aria-label="Mot de passe"
                    aria-describedby="search-addon"
                    value={userConnection.pwdUser}
                    onChange={(e) => setUserConnection({...userConnection, "pwdUser": e.target.value})}
                />
                <span onClick={() => setIsVisible(!isVisible)} className="input-group-text border-0"
                      id="search-addon"><i className="fas fa-eye"></i></span>
            </div>


            <Row className="w-100">
                <div className="d-flex justify-content-between mx-3 mb-4">
                    <a href="">Mot de passe oublié ?</a>
                </div>
            </Row>


            <MDBBtn className="mb-4" onClick={handlerClickLogin}>Se connecter</MDBBtn>

            <div className="d-flex mx-3 mb-4" style={{justifyContent: "center"}}>
                Pas encore client ? <a href="" style={{marginLeft: 5}} onClick={changeToRegistration}> Créez un
                compte</a>
            </div>


        </MDBContainer>
    )
}
