import * as React from "react";
import {useEffect} from "react";
import {Flasher} from "react-universal-flash";
import MessageFlash from "../Components/FlashMessage/MessageFlash";
import NavigationSideBar from "../Components/NavBar/NavigationSideBar";
import {Outlet} from "react-router-dom";
import {Auth, Notification, Token} from "../types/type";
import {socket} from "../socket";
import {fetchAPI} from "../Components/API";
import {useDispatch} from "react-redux";

export default function SecureRoute({auth}: {auth : Auth}): React.ReactElement {

    const dispatch = useDispatch();

    useEffect(() => {
        const timeout = setInterval(async () => {
            if (auth.refresh_jwt) {
                const token: Token = await fetchAPI.refreshConnexion(auth.refresh_jwt);

                const notifications: Notification[] = await fetchAPI.getAll.notificationsByIdUser(auth.identifiant, token.token);

                console.info("REFRESH TOKEN SUCCESSFUL !");

                dispatch({type: 'SET_JWT', payload: {token: token, notifications: notifications}});
            }
        }, (15 * 60) * 1000); //Toutes les 15 minutes

        socket.connect();

        return () => {
            clearInterval(timeout);
        }
    }, []);

    return (
        <>
            <Flasher position="top_center">
                <MessageFlash/>
            </Flasher>
            <NavigationSideBar notifications={auth.notifications} isEmploye={auth.isEmploye} isClientPro={auth.isClientPro}
                               isAdmin={auth.isAdmin} jwt={auth.jwt}
                               username={auth.username}/>
            <div className="conteneur">
                <Outlet/>
            </div>
        </>
    );

}
