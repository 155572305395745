import * as React from "react";
import {ReactElement, useEffect, useRef} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {MDBIcon, MDBListGroupItem, MDBRipple} from "mdb-react-ui-kit";
import {IconMDB} from "../../types/type";
import {Badge} from "react-bootstrap";
import BadgeNotif from "../BadgeNotif";

/**
 * @description A component displaying a link that highlights when clicked
 * @param route The name of the route to be taken when the link is clicked.
 * @param name The name that will be displayed for the link.
 * @param icon An icon appears next to the link name.
 * @param nbNotif
 * @param clickHandler
 * @return {Element} A React element displaying a link.
 * @constructor
 */
export default function Link({route, name, icon, nbNotif, clickHandler = undefined}: {
    route: string,
    name: string,
    icon: IconMDB,
    nbNotif?: number,
    clickHandler?: () => void
}): ReactElement {
    const navigate = useNavigate();

    const refLink = useRef<HTMLElement>();

    const location = useLocation();

    useEffect(() => {
        if (refLink.current) {
            if (location.pathname === route) refLink.current.classList.add("active");
            else refLink.current.classList.remove("active");
        }
    }, [location]);

    return (
        <MDBRipple onClick={clickHandler ? clickHandler : () => navigate(route)} rippleTag='span'>
            <MDBListGroupItem ref={refLink} tag='a' id={route} action
                              className='border-0 border-bottom rounded rounded user-select-none'>
                <MDBIcon style={{marginRight: "0.5vw"}} fas={icon.fas} far={icon.far} icon={icon.name}/>
                {name}
                {(nbNotif && nbNotif) > 0 &&
                    <BadgeNotif nbNotif={nbNotif}></BadgeNotif>
                }
            </MDBListGroupItem>
        </MDBRipple>
    );
}
