import * as React from 'react';
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {ErrorPageProps} from "../types/type";

export default function ErrorPage(
    {titleError, bodyError, codeError} : ErrorPageProps
): React.ReactElement {
    const navigate = useNavigate();
    return (
        <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="text-center">
                <h1 className="display-1 fw-bold">{codeError}</h1>
                <p className="fs-3"><span className="text-danger">Opps!</span>{titleError}</p>
                <p className="lead">
                    {bodyError}
                </p>
                <Button variant="primary" onClick={() => navigate("/")}>REVENIR EN LIEU SÛR</Button>
            </div>
        </div>
    );
}
