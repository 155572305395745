export const HOME: string = "Accueil";
export const TAKE_APPOINTMENT: string = "RDV";
export const MAKE_TICKET: string = "Ticket";
export const TICKET_MANAGEMENT: string = "Tickets";
export const USER_APPOINTMENTS: string = "Mes Rdvs";
export const EMPLOYEE_APPOINTMENTS: string = "Rendez-vous";
export const USERS_MANAGEMENT: string = "Gestion utilisateurs";
export const SERVICES_MANAGEMENT: string = "Gestion des services";
export const SCHEDULE_MANAGEMENT: string = "Gestion des horaires";
export const APPOINTMENTS_HISTORY: string = "Historique RDV";

export const LOGOUT: string = "Déconnexion"

export const APPOINTMENTS_NOT_ASSIGNED: string = "A affecter";
