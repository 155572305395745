import * as React from "react";
import {Ticket} from "../types/type";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import {getNumberOfPages} from "../Utils/functionManager";
import {Button, FloatingLabel, Form, FormCheck} from "react-bootstrap";
import PaginationTable from "./PaginationTable";
import Row from "react-bootstrap/Row";
import {MDBIcon, MDBProgress, MDBProgressBar} from "mdb-react-ui-kit";
import ModalConfirmation from "./ModalConfirmation";
import {useNavigate} from "react-router-dom";


const MAX_ELEMENT_PAGINATION: number = 12;

let reasonForReject: string;

export default function TableUserTicket({tickets}: {tickets:  Ticket[]}): React.ReactElement {

    const [render, setRender] = React.useState<React.ReactElement[]>([]);

    const [nbPages, setNbPages] = React.useState<number>();
    const [currentPage, setCurrentPage] = React.useState<number>(1);


    const navigate = useNavigate();
    const [showModal, setShowModal] = React.useState<boolean>(false);


    React.useEffect(() => {
        setNbPages(getNumberOfPages(tickets, MAX_ELEMENT_PAGINATION));
        refreshRender();
    }, [tickets]);


    function handlerClickRdvProgressBar(idRdv: number): void {
        navigate(`/myRdv/${idRdv}`);
    }

    function handlerClickRejectProgressBar(reasonReject: string): void {
        reasonForReject = reasonReject;
        setShowModal(true);
    }

    function refreshRender(): void {
        const tabTickets: React.ReactElement[] = [];
        for (let ticket of tickets) {

            let width: number = 33;
            let bgColor: string = 'danger';
            let text: string = 'ENVOYER';
            let cursorStyle: string = 'default';
            let animated: boolean = true;
            let handlerClick: () => void = () => {}


            if (ticket.view) {
                width = 66;
                bgColor = "warning";
                text = 'EN COURS DE VALIDATION';
            }

            if (ticket.appointment) {
                width = 100;
                bgColor = "success";
                text = 'VOIR RENDEZ-VOUS';
                cursorStyle = 'pointer';
                animated = false;
                handlerClick = () => handlerClickRdvProgressBar(ticket.appointment.idRdv);
            }

            if (ticket.reject) {
                width = 100;
                bgColor = "danger";
                text = 'REFUSER - VOIR LES RAISONS DU REFUS';
                animated = false;
                cursorStyle = 'pointer';
                handlerClick = () => handlerClickRejectProgressBar(ticket.reasonForReject);
            }

            tabTickets.push(
                <Tr key={ticket.id}>
                    <Td>{ticket.name}</Td>
                    <Td>
                        <MDBProgress style={{cursor: cursorStyle}} onClick={handlerClick} height='20'>
                            <MDBProgressBar striped={animated} animated={animated} bgColor={bgColor} width={width} valuemin={0} valuemax={100}>
                                {text}
                            </MDBProgressBar>
                        </MDBProgress>
                    </Td>
                </Tr>
            )
        }

        setRender(tabTickets);
    }


    return (
        <>
            <Table style={{textAlign: 'center', tableLayout: 'fixed'}}>
                <Thead style={{backgroundColor: "black"}} className="table-dark">
                    <Tr>
                        <Th>Titre</Th>
                        <Th>Status</Th>
                    </Tr>
                </Thead>
                <Tbody style={{verticalAlign: "middle"}}>
                    {render.slice((currentPage - 1) * MAX_ELEMENT_PAGINATION, currentPage * MAX_ELEMENT_PAGINATION)}
                </Tbody>
            </Table>

            <Row style={{marginTop: '5%'}}>
                <PaginationTable style={{justifyContent: "center"}} nbPages={nbPages} currentPage={currentPage}
                                 setCurrentPage={setCurrentPage}/>
            </Row>

            <ModalConfirmation form titre="Refus du ticket" fonctionBtnRefuse={() => setShowModal(false)} show={showModal} body={
                <p>
                    {reasonForReject}
                </p>
            }></ModalConfirmation>

        </>
    );
}
