import {Button, Card} from "react-bootstrap";
import * as React from "react";
import {CardRdvProps} from "../types/propsType";

export default function CardRdv({rdv, isEmploye}: CardRdvProps): React.ReactElement {

    const [buttons, setButtons] = React.useState<React.ReactElement[]>([]);

    function launchEvent(): void {
        let event = new CustomEvent("onInterventionValidated");
        document.dispatchEvent(event);
    }


    React.useEffect(() => {

        console.log(rdv.signature)

        if (new Date().toLocaleDateString() === rdv.date.toLocaleDateString()) {
            if (rdv.signature) {
                setButtons([
                    <p style={{color: "green"}} key={1}>Signature déjà enregistrée</p>,
                    <>
                        {rdv.signature.pdf &&
                            <a href={rdv.signature.pdf.imagePath}>Récapitulatif de l'intervention</a>
                        }
                    </>
                ])
            } else if (isEmploye) {
                setButtons([<Button key={1} onClick={launchEvent} variant="primary">Valider intervention</Button>]);
            }
        } else {
            setButtons([]);
        }

    }, [])

    return (
        <>
            <Card>
                <Card.Header>{rdv.service ? rdv.service.name : rdv.ticket.name}</Card.Header>
                <Card.Body>
                    <Card.Title>Rendez-vous
                        du {rdv.date.toLocaleDateString("fr", {weekday: "long"})} {rdv.date.toLocaleDateString()} à {rdv.horaires[0]}h</Card.Title>
                    <Card.Text>
                        <span style={{display: "block"}}>Pour le client : {rdv.client.prenom} {rdv.client.nom}</span>
                        <span style={{display: "block"}}>Sujet : {rdv.sujet}</span>
                        <span>Lieu de l'intervention : {rdv.siteIntervention.locationSiteIntervention}</span>
                    </Card.Text>
                    {buttons}
                </Card.Body>
            </Card>

        </>
    )
}
