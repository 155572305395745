import {fetchAPI} from "../Components/API";
import {formatDateToFetchApi, getMondayOfTheCurrentWeek, sortRdvs} from "../Utils/functionManager";
import {
    Appointment,
    Auth,
    InterventionSite,
    LoaderDataGestionInterventionSite,
    LoaderDataUpdateAppointment,
    MaxMonthInterval,
    Schedule,
    Service, Ticket,
    User
} from "../types/type";
import {
    DataAccount,
    DataAllUser,
    DataSchedulesAndMaxMonthInterval,
    DataServices,
    DataUserRdvs,
    DataUserRdvsAndServices, DataUserTickets,
    DataUserUpdate
} from "../types/loaderType";

export async function loaderUserRdvs(): Promise<DataUserRdvsAndServices> {
    const authObject: Auth = fetchAPI.authObject;

    const rdvs: Appointment[] = await fetchAPI.getRdvsUser(authObject.identifiant, authObject.jwt);

    const services: Service[] = await fetchAPI.getServicesForClients(authObject.jwt);

    sortRdvs(rdvs);

    return {
        rdvs: rdvs,
        services: services
    };
}

export async function loaderAnotherUserRdvs({params}): Promise<DataUserRdvs> {
    const authObject: Auth = fetchAPI.authObject;
    const response: Appointment[] = await fetchAPI.getRdvsUser(params.idClient, authObject.jwt);

    return {
        rdvs: response,
        idClient: params.idClient
    };
}

export async function loaderServices(): Promise<DataServices> {
    const authObject: Auth = fetchAPI.authObject;

    let interventionsSite: InterventionSite[];

    if (authObject.idBoss !== "") {
        interventionsSite = await fetchAPI.getAllSiteInterventionByUserPro(authObject.idBoss, authObject.jwt);
    } else {
        interventionsSite = await fetchAPI.getAllSiteInterventionByUserPro(authObject.identifiant, authObject.jwt);
    }

    const services: Service[] = await fetchAPI.getServicesForClients(authObject.jwt);

    const monday: Date = getMondayOfTheCurrentWeek();

    const schedules: Schedule[] = await fetchAPI.getSchedules(authObject.jwt, formatDateToFetchApi(monday));

    const maxMonthInterval: MaxMonthInterval = await fetchAPI.getMaxMonthInterval(authObject.jwt);

    return {
        services: services,
        sites: interventionsSite,
        schedules: schedules,
        maxMonthInterval: maxMonthInterval
    }
}


export async function loaderInterventionSiteById({params}): Promise<InterventionSite> {
    const authObject = fetchAPI.authObject;
    return await fetchAPI.getInterventionSiteById(params.idSite, authObject.jwt);
}

export async function loaderInterventionSiteUpdate({params}): Promise<LoaderDataGestionInterventionSite> {
    const interventionSite: InterventionSite = await loaderInterventionSiteById({params: params});

    return {
        interventionSite: interventionSite,
        idSite: params.idSite,
        action: "update"
    }
}

export async function loaderInterventionSiteCreate(): Promise<LoaderDataGestionInterventionSite> {
    return {
        action: "create"
    }
}

export async function loaderUserByIdUpdate({params}): Promise<DataUserUpdate> {
    const authObject: Auth = fetchAPI.authObject;
    const usersPro = await fetchAPI.getAllClientsPro(authObject.jwt);
    const user: User = await fetchAPI.getUser(params.idUser, authObject.jwt);

    return {
        user: user,
        usersPro: usersPro,
        idUser: params.idUser,
        typeUser: params.typeUser
    }
}

export async function loaderAllInterventionSiteByIdClientPro(): Promise<InterventionSite[]> {
    const authObject: Auth = fetchAPI.authObject;
    return await fetchAPI.getAllSiteInterventionByUserPro(authObject.identifiant, authObject.jwt);
}

export async function loaderAllRdvs(): Promise<Appointment[]> {
    const authObject: Auth = fetchAPI.authObject;

    const rdvs: Appointment[] = await fetchAPI.getAllRdvs(authObject.jwt);

    sortRdvs(rdvs);

    return rdvs;
}

export async function loaderAllRdvsAffectation(): Promise<Appointment[]> {
    let rdvs: Appointment[] = await loaderAllRdvs();
    return rdvs.filter(objet => !objet.employe);
}

export async function loaderRdvById({params}): Promise<Appointment> {
    const authObject: Auth = fetchAPI.authObject;

    return await fetchAPI.getRdv(params.idRdv, authObject.jwt);
}

export async function loaderUpdateAppointment({params}): Promise<LoaderDataUpdateAppointment> {
    const authObject: Auth = fetchAPI.authObject;
    const rdvModif: Appointment = await loaderRdvById({params: params});
    const idUserForInterventionSite = rdvModif.chef ? rdvModif.chef.id : rdvModif.client.id;
    const interventionSites: InterventionSite[] = await fetchAPI.getAllSiteInterventionByUserPro(idUserForInterventionSite, authObject.jwt);
    const services: Service[] = await fetchAPI.getServicesForClients(authObject.jwt);

    const maxMonthInterval: MaxMonthInterval = await fetchAPI.getMaxMonthInterval(authObject.jwt);

    return {
        rdvModif: rdvModif,
        interventionSites: interventionSites,
        services: services,
        maxMonthInterval: maxMonthInterval,
    }
}

export async function loaderAllServices(): Promise<Service[]> {
    const authObject: Auth = fetchAPI.authObject;

    return await fetchAPI.getAllServices(authObject.jwt);
}

export async function loaderSchedules(): Promise<DataSchedulesAndMaxMonthInterval> {
    const authObject: Auth = fetchAPI.authObject;

    const today: Date = new Date();

    const schedules: Schedule[] = await fetchAPI.getSchedules(authObject.jwt, formatDateToFetchApi(today));
    const maxMonthInterval: MaxMonthInterval = await fetchAPI.getMaxMonthInterval(authObject.jwt);

    return {
        schedules,
        maxMonthInterval
    };
}

export async function loaderAccount(): Promise<DataAccount> {
    const authObject: Auth = fetchAPI.authObject;

    const user: User = await fetchAPI.getUser(Number(authObject.identifiant), authObject.jwt);

    const interventionSites: InterventionSite[] = await fetchAPI.getAllSiteInterventionByUserPro(authObject.identifiant, authObject.jwt);

    return {user, interventionSites}
}

export async function loaderAllUsers(): Promise<DataAllUser> {
    const authObject: Auth = fetchAPI.authObject;

    const clients: User[] = await fetchAPI.getAllClient(authObject.jwt, authObject.identifiant);

    const allEmployes: User[] = await fetchAPI.getAllEmployes(authObject.jwt, authObject.identifiant);

    const employes: User[] = allEmployes.filter(employe => !employe.external);

    const externalEmployes: User[] = allEmployes.filter(employe => employe.external);

    return {
        clients,
        employes,
        externalEmployes
    }

}

export async function loaderOwnedTickets(): Promise<DataUserTickets> {
    const authObject: Auth = fetchAPI.authObject;

    const tickets: Ticket[] = await fetchAPI.getAll.ticketsByIdUser(authObject.identifiant, authObject.jwt)

    const sites: InterventionSite[] = await fetchAPI.getAllSiteInterventionByUserPro(authObject.identifiant, authObject.jwt);

    return {tickets, sites};
}

export async function loaderAllTickets(): Promise<Ticket[]> {
    const authObject: Auth = fetchAPI.authObject;
    return await fetchAPI.getAll.tickets(authObject.jwt);
}

export async function loaderTicketById({params}): Promise<Ticket> {
    const authObject: Auth = fetchAPI.authObject;
    const idTicket: string = params.idTicket;

    return await fetchAPI.get.ticket(idTicket, authObject.jwt);
}
