import * as React from 'react';
import {useEffect} from 'react';
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "../store";
import OnlineDetect from "../OnlineDetection/OnlineDetect";
import {Auth, IconButton, InputForm, Service} from "../types/type";
import {useLoaderData, useLocation, useNavigate} from "react-router-dom";
import CustomTable from "../Components/Table/CustomTable";
import ModalConfirmation from '../Components/ModalConfirmation'
import {fetchAPI} from "../Components/API";
import CustomForm from "../Components/Forms/CustomForm";
import {getEmptyService} from "../Utils/functionManager";
import {Button, Card, CardHeader} from "react-bootstrap";
import {MDBIcon} from "mdb-react-ui-kit";
import {flash} from "react-universal-flash";
import {iconClone, iconEdit, iconTrash} from "../types/Icons";
import {PatternLogin, PatternName} from "../types/regexPattern";


export default function GestionService(): React.ReactElement {

    const services: Service[] = useLoaderData() as Service[];

    const navigate = useNavigate();
    const location = useLocation();



    const auth: Auth = fetchAPI.authObject;

    const [showModalUpdate, setShowModalUpdate] = React.useState<boolean>(false);
    const [showModalDelete, setShowModalDelete] = React.useState<boolean>(false);
    const [showModalCreate, setShowModalCreate] = React.useState<boolean>(false);
    const [showModalClone, setShowModalClone] = React.useState<boolean>(false);

    const [targetService, setTargetService] = React.useState<Service>(getEmptyService());


    const [targetServiceCreate, setTargetServiceCreate] = React.useState<Service>(getEmptyService());

    const headers: string[] = ["Identifiant", "Nom", "Durée", "Prix", "Description", "Status"];
    const keys: string[] = ["idService", "name", "durationString", "price", "description", "status"];

    useEffect(() => {
        refuseButtonModal();
    }, [services]);

    /*
    const buttons: ButtonTable[] = [
        {
            title: "Modifier",
            nameEventClick: "update",
            icon: {
                fas: true,
                far: false,
                name: "edit"
            },
            variant: "warning"
        },
        {
            title: "Supprimer",
            nameEventClick: "delete",
            icon: {
                fas: true,
                far: false,
                name: "trash-alt"
            },
            variant: "danger"
        }
    ]

     */

    const actions: IconButton[] = [
        {
            icon: iconEdit,
            nameEventClick: "update"
        },
        {
            icon: iconTrash,
            nameEventClick: "delete"
        },
        {
            icon: iconClone,
            nameEventClick: "copy"
        }
    ]

    const inputsFormUpdate: InputForm[] = [
        {
            name: "name",
            label: "Nom Service",
            type: "text",
            pattern: PatternName,
            value: targetService.name,
            setValue: (name: object) => setTargetService({...targetService, ...name})
        },
        {
            name: "duration",
            label: "Durée du Service (en heure)",
            type: "number",
            value: targetService.duration,
            setValue: (duration: object) => setTargetService({...targetService, ...duration})
        },
        {
            name: "price",
            label: "Prix du service (en €)",
            type: "number",
            value: targetService.price,
            setValue: (price: object) => setTargetService({...targetService, ...price})
        },
        {
            name: "status",
            label: "Status",
            type: "checkbox",
            value: targetService.status,
            required: false,
            setValue: (status: object) => setTargetService({...targetService, ...status})
        },
        {
            name: "description",
            label: "Description",
            type: "textarea",
            value: targetService.description,
            setValue: (description: object) => setTargetService({...targetService, ...description})
        },
        {
            name: "idService",
            label: "id",
            type: "hidden",
            value: targetService.idService,
            setValue: (o: object) => {}
        },
        {
            name: "action",
            label: "Action",
            type: "hidden",
            value: "update",
            setValue: (o: object) => {}
        }
    ];

    const inputsFormCreate: InputForm[] = [
        {
            name: "name",
            label: "Nom Service",
            type: "text",
            pattern: PatternName,
            value: targetServiceCreate.name,
            setValue: (name: object) => setTargetServiceCreate({...targetServiceCreate, ...name})
        },
        {
            name: "duration",
            label: "Durée du Service (en heure)",
            type: "number",
            value: targetServiceCreate.duration,
            setValue: (duration: object) => setTargetServiceCreate({...targetServiceCreate, ...duration})
        },
        {
            name: "price",
            label: "Prix du service (en €)",
            type: "number",
            value: targetServiceCreate.price,
            setValue: (price: object) => setTargetServiceCreate({...targetServiceCreate, ...price})
        },
        {
            name: "status",
            label: "Status",
            type: "checkbox",
            value: targetServiceCreate.status,
            required: false,
            setValue: (status: object) => setTargetServiceCreate({...targetServiceCreate, ...status})
        },
        {
            name: "description",
            label: "Description",
            type: "textarea",
            value: targetServiceCreate.description,
            setValue: (description: object) => setTargetServiceCreate({...targetServiceCreate, ...description})
        },
        {
            name: "idService",
            label: "id",
            type: "hidden",
            value: targetServiceCreate.idService,
            setValue: (o: object) => {}
        },
        {
            name: "action",
            label: "Action",
            type: "hidden",
            value: "create",
            setValue: (o: object) => {}
        }
    ];



    function handlerClickTable(eventName: string, object: Service): void {
        if (eventName === "update") setShowModalUpdate(true);
        else if (eventName === "copy") setShowModalClone(true);
        else setShowModalDelete(true);
        setTargetService(object);
        console.log(eventName);
        console.log(object);
    }

    async function copyService() {
        const response = await fetchAPI.createService(targetService.name, targetService.description, targetService.duration, targetService.price, false, auth.jwt);
        console.log(response);
        if (response.id) {
            flash(5000, "success", "Copie du service réussit !");
            navigate(location.pathname);
        } else {
            flash(5000, "danger", "Erreur lors de la copie du service : " + response.statusText);
        }

    }

    function refuseButtonModal(): void {
        if (showModalUpdate) setShowModalUpdate(false);
        if (showModalDelete) setShowModalDelete(false);
        if (showModalCreate) setShowModalCreate(false);
        if (showModalClone) setShowModalClone(false);
    }

    async function acceptButtonModal() {
        const status: boolean = await targetService.status;
        const isSafe: boolean = await fetchAPI.isServiceSafeToEdit(targetService.idService, auth.jwt);

        if (status) {
            flash(5000, "warning", "Un service ne peut pas être modifié tant qu'il est actif sur le site.");
        }
        else if (!isSafe) {
            flash(5000, "danger", "Attention, vous ne pouvez pas supprimer un service qui possède des rendez-vous en cours.");
        } else {
            const response = await fetchAPI.deleteService(targetService.idService, auth.jwt);
            console.log(response);
            if (response.status === 204) {
                flash(5000, "success", "Le service à bel et bien été supprimé.");
                navigate(location.pathname);
            } else {
                flash(5000, "danger", "Une erreur est survenue : " + response.statusText);
            }

        }
    }

    return (
        <>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>

                    <div className="customCard">
                        <Card>
                            <CardHeader style={{display: "flex", justifyContent: "space-between", fontSize: "large", fontWeight: "600"}}>
                                <p style={{margin: "0", alignSelf: "center"}}>Gestion des services</p>
                                <Button onClick={() => setShowModalCreate(true)}>
                                    <MDBIcon style={{marginRight: "0.3vw"}} fas icon="plus"></MDBIcon>
                                    Nouveau Service
                                </Button>
                            </CardHeader>
                        </Card>
                        <CustomTable<Service> headers={headers} keys={keys} keyForKeyAttribute="idService" objects={services}
                                              handlerClick={handlerClickTable} actions={actions}></CustomTable>
                    </div>


                    <ModalConfirmation
                        body={
                            <p>
                                Voulez vous vraiment supprimer le service : {targetService ? targetService.name : ""}
                            </p>
                        }
                        titre="Confirmation de la supression du service" fonctionBtnRefuse={refuseButtonModal}
                        show={showModalDelete} fonctionBtnAccept={acceptButtonModal}>
                    </ModalConfirmation>

                    <ModalConfirmation
                        body={
                            <p>
                                Voulez vous vraiment cloner le service : {targetService ? targetService.name : ""}
                            </p>
                        }
                        titre="Confirmation de la copie du service" fonctionBtnRefuse={refuseButtonModal}
                        show={showModalClone} fonctionBtnAccept={copyService}>
                    </ModalConfirmation>

                    <ModalConfirmation
                        form={true}
                        body={
                            <CustomForm titleButtonSend="Modifier le service" inputs={inputsFormUpdate}></CustomForm>
                        }
                        titre="Modification du service" fonctionBtnRefuse={refuseButtonModal}
                        show={showModalUpdate}>
                    </ModalConfirmation>

                    <ModalConfirmation
                        form={true}
                        titre="Création d'un service"
                        fonctionBtnRefuse={refuseButtonModal}
                        show={showModalCreate}
                        body={
                            <CustomForm titleButtonSend="Créer un service" inputs={inputsFormCreate}></CustomForm>
                        }>

                    </ModalConfirmation>


                </PersistGate>

                <OnlineDetect></OnlineDetect>
            </Provider>
        </>
    )
}
