import PwaInstallPopupIOS from 'react-pwa-install-ios';
import safariPNG from '../img/safari.png';
import * as React from 'react';
export const PopupIOS = (): React.ReactElement => {
    React.useEffect(() => {
        window.localStorage.removeItem("pwa_popup_display");
    }, []);
    return (
        <PwaInstallPopupIOS
            delay={1}
            lang="fr"
            appIcon={safariPNG}>
        </PwaInstallPopupIOS>
    );
};
