import {persistor, store} from "../store";
import OnlineDetect from "../OnlineDetection/OnlineDetect";
import {Provider} from "react-redux";
import * as React from 'react';
import {PersistGate} from "redux-persist/integration/react";
import TableUserForClientPro from "../Components/TableUserForClientPro";
import {fetchAPI} from "../Components/API";
import {useNavigate} from "react-router-dom";
import {Auth} from "../types/type";


export default function GestionClient(): React.ReactElement {

    const navigate = useNavigate();

    const authObject: Auth = fetchAPI.authObject;


    React.useEffect(() => {

        function onChoixClient(event) {
            let client = event.detail;
            navigate(`/gestionClient/${client.id}`, {replace: true});
        }

        document.addEventListener("onChoixClient", onChoixClient);

        return () => {
            document.removeEventListener("onChoixClient", onChoixClient);
        }

    }, []);


    return (
        <>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>


                    <>
                        <div className="customCard">

                            <div style={{display: "flex", justifyContent: "center"}}>

                                <h2>Clients</h2>

                            </div>

                            <TableUserForClientPro jwt={authObject.jwt}
                                                   idClientPro={authObject.identifiant}></TableUserForClientPro>

                        </div>
                    </>


                    <OnlineDetect></OnlineDetect>

                </PersistGate>
            </Provider>
        </>
    );
}
