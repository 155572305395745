import * as React from "react";
import {Auth, MaxMonthInterval, Schedule} from "../types/type";
import {useLoaderData, useNavigate} from "react-router-dom";
import {Provider} from "react-redux";
import {persistor, store} from "../store";
import {PersistGate} from "redux-persist/integration/react";
import OnlineDetect from "../OnlineDetection/OnlineDetect";
import {Button, Row, Table} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import {formatDateToFetchApi, getDayNameFromDate, sortTimeTable} from "../Utils/functionManager";
import {fetchAPI} from "../Components/API";
import {flash} from "react-universal-flash";
import {DataSchedulesAndMaxMonthInterval} from "../types/loaderType";


const START_DAY: number = 8;
const DEFAULT_TIMETABLE: number[] = [8, 9, 10, 11, 14, 15, 16, 17];
const WEEK: number[] = [0, 1, 2, 3, 4, 5, 6];

export default function ScheduleGestion(): React.ReactElement {

    const todayConst: Date = new Date();

    const data: DataSchedulesAndMaxMonthInterval = useLoaderData() as DataSchedulesAndMaxMonthInterval;

    let schedulesTemp: Schedule[] = data.schedules;

    const maxMonthIntervalTemp: MaxMonthInterval = data.maxMonthInterval;

    const [today, setToday] = React.useState<Date>(new Date());

    const auth: Auth = fetchAPI.authObject;

    const navigate = useNavigate();

    const [schedules, setSchedules] = React.useState<Schedule[]>(getSchedules());

    const [maxMonthInterval, setMaxMonthInterval] = React.useState<number>(maxMonthIntervalTemp.nbMonths);


    React.useEffect(() => {
        getSchedulesApi().then()
    }, [today])

    async function getSchedulesApi(): Promise<void> {
        schedulesTemp = await fetchAPI.getSchedules(auth.jwt, formatDateToFetchApi(today));
        setSchedules(getSchedules());
    }

    function getSchedules(): Schedule[] {
        const daysEdited: number[] = schedulesTemp.map((schedule: Schedule) => schedule.date.getDay());

        function getScheduleByDay(day: number): Schedule {
            return schedulesTemp.filter((schedule: Schedule) => schedule.date.getDay() === day)[0];
        }

        const newSchedules: Schedule[] = [];


        for (let i = 1; i < 8; i++) {

            const tempDay: Date = new Date(today.getTime());
            tempDay.setDate(tempDay.getDate() + (i - 1));

            const day: number = tempDay.getDay();

            if (daysEdited.includes(day)) newSchedules.push(getScheduleByDay(day));
            else {
                let timeTable: number[] = [];

                if (day !== 6 && day !== 0) timeTable = [...DEFAULT_TIMETABLE];

                newSchedules.push({
                    id: -1,
                    date: new Date(tempDay.getTime()),
                    timeTable: timeTable,
                    end: -1,
                    start: -1
                });
            }
        }

        newSchedules.sort((a, b) => a.date.getTime() - b.date.getTime());

        return newSchedules;
    }


    function changeStateTime(dayNumberInTheWeek: number, hour: number, adding: boolean): void {

        const schedulesNotConcerned: Schedule[] = schedules.filter((schedule) => schedule.date.getDay() !== dayNumberInTheWeek);

        const schedulesConcerned: Schedule[] = schedules.filter((schedule) => schedule.date.getDay() === dayNumberInTheWeek);

        const scheduleConcerned: Schedule = schedulesConcerned[0];


        if (adding) scheduleConcerned.timeTable.push(hour);
        else {
            const indexOfHour: number = scheduleConcerned.timeTable.indexOf(hour);
            scheduleConcerned.timeTable.splice(indexOfHour, 1);
        }

        sortTimeTable(scheduleConcerned.timeTable);

        const newSchedules: Schedule[] = [
            ...schedulesNotConcerned,
            scheduleConcerned
        ];

        newSchedules.sort((a, b) => a.date.getTime() - b.date.getTime());

        setSchedules(newSchedules);
    }

    async function saveNewSchedules(): Promise<void> {
        try {
            await callApiForSaveNewSchedules();
            flash(3000, "success", "Les horaires ont bel et bien été modifiés");
            navigate("/");
        } catch (error) {
            flash(3000, "danger", error.message + ' ' + error.code);
        }
    }

    async function callApiForSaveNewSchedules(): Promise<void> {
        for (const schedule of schedules) {
            const stringTimeTableSchedule: string = JSON.stringify(schedule.timeTable);
            const stringTimeTableDefault: string = JSON.stringify(DEFAULT_TIMETABLE);
            if (schedule.id === -1 && stringTimeTableDefault !== stringTimeTableSchedule) {
                if (schedule.date.getDay() === 6 || schedule.date.getDay() === 0) {
                    if (stringTimeTableSchedule !== JSON.stringify([])) await fetchAPI.createSchedule(formatDateToFetchApi(schedule.date), schedule.timeTable, auth.jwt);
                } else await fetchAPI.createSchedule(formatDateToFetchApi(schedule.date), schedule.timeTable, auth.jwt);
            } else if (schedule.id !== -1) {
                await fetchAPI.updateSchedule(schedule.id, schedule.timeTable, auth.jwt);
            }
        }
    }

    /**
     * @description Function to advance the schedule by one week
     */
    function nextWeek(): void {
        let tomorow: Date = new Date(today.getTime());
        tomorow.setDate(today.getDate() + 7);
        setToday(tomorow);
    }

    /**
     * @description Function to move the schedule back one week
     */
    function previousWeek(): void {
        let yesterday: Date = new Date(today.getTime());
        yesterday.setDate(today.getDate() - 7);

        if (yesterday.getFullYear() >= todayConst.getFullYear()) {
            if (yesterday.getMonth() === todayConst.getMonth()) {
                if (yesterday.getDate() >= todayConst.getDate()) setToday(yesterday);
            } else if (yesterday.getMonth() > todayConst.getMonth()) setToday(yesterday);
        }
    }

    async function saveNewMaxMonthInterval(): Promise<void> {
        try {
            await fetchAPI.updateMaxMonthInterval(maxMonthIntervalTemp.id, maxMonthInterval, auth.jwt);
            flash(3000, "success", "Le délai à bien été modifié !");
        } catch (error) {
            flash(3000, "danger", error.message + ' ' + error.code);
        }
    }

    function incrementMaxMonthInterval(): void {
        setMaxMonthInterval(maxMonthInterval+1)
    }

    function decrementMaxMonthInterval(): void {
        if (maxMonthInterval > 1) setMaxMonthInterval(maxMonthInterval-1)
    }


    return (
        <>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>

                    <div className="customCard">
                        <Row>
                            <h3 className="tabTimeTitle">Management du délais maximum pour la prise de rendez-vous</h3>
                            <Col className="colManagementMaxMonthInterval">
                                <Row className="rowManagementMaxMonthInterval">

                                    <p className="groupHoursAppointment colManagementMaxMonthInterval">
                                        Délais : <span style={{fontWeight: "bold", marginLeft: "5px", marginRight: "5px"}}>{maxMonthInterval}</span> mois
                                    </p>

                                    <Button style={{width: "auto"}} onClick={decrementMaxMonthInterval}>-</Button>
                                    <Button style={{width: "auto"}} onClick={incrementMaxMonthInterval}>+</Button>

                                    <Button style={{marginTop: "5%"}} onClick={saveNewMaxMonthInterval} variant="primary">Enregistrer</Button>
                                </Row>

                            </Col>
                        </Row>
                    </div>

                    <div className="customCard">
                        <Row>
                            <h3 className="tabTimeTitle">Management des horaires</h3>
                            <Col sm={6}>
                                <Row>

                                    <Col>
                                        <div>
                                            <Button variant="success" className="boutonTimeManagement"></Button>
                                            Ouvert
                                        </div>
                                        <div>
                                            <Button variant="danger" className="boutonTimeManagement"></Button>
                                            Fermé
                                        </div>
                                    </Col>
                                </Row>

                            </Col>
                            <Col className="colTimeManagement" sm={6}>
                                <Button onClick={saveNewSchedules} variant="primary">Enregistrer</Button>
                            </Col>
                        </Row>
                    </div>

                    <div className="customCard">
                        <div className="row">
                            <div className="col">
                                <Button onClick={previousWeek} style={{float: "left"}}
                                        className="primary">{"<"}</Button>
                            </div>
                            <div className="col">
                                <Button onClick={nextWeek} style={{float: "right"}} className="primary">{">"}</Button>
                            </div>
                        </div>
                        <Table responsive>
                            <thead>
                            <tr>
                                {WEEK.map((dayInTheWeek) => {

                                    const tempDate: Date = new Date(today.getTime());

                                    tempDate.setDate(tempDate.getDate() + dayInTheWeek);

                                    const nameOfTheDay: string = getDayNameFromDate(tempDate).toUpperCase();

                                    return (
                                        <th key={nameOfTheDay}>
                                            <div>
                                                {nameOfTheDay}
                                            </div>
                                            <span className="dateSpan">
                                                {tempDate.toLocaleDateString()}
                                            </span>
                                        </th>
                                    );

                                })}
                            </tr>
                            </thead>
                            <tbody>

                            {schedules.length === 7 &&
                                Array.from({length: 10}).map((_, index) => (
                                    <tr key={index}>
                                        {WEEK.map((dayInTheWeek) => {

                                            const schedule: Schedule = schedules[dayInTheWeek];

                                            const included: boolean = schedule.timeTable.includes(START_DAY + index);

                                            return (
                                                <td key={getDayNameFromDate(schedule.date) + index}>
                                                    <Button
                                                        onClick={() => changeStateTime(schedule.date.getDay(), START_DAY + index, !included)}
                                                        className="boutonTime"
                                                        variant={included ? "success" : "danger"}>{START_DAY + index}
                                                    </Button>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                ))
                            }


                            </tbody>
                        </Table>
                    </div>


                    <OnlineDetect></OnlineDetect>
                </PersistGate>
            </Provider>

        </>
    );
}
