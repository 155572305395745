import {Adress, Auth, InterventionSite} from "../../types/type";
import * as React from 'react';
import {ReactElement} from 'react';
import ListGroup from "react-bootstrap/ListGroup";
import {deleteAllFlashes, flash} from "react-universal-flash";
import {MapContainer, Marker, Popup, TileLayer, useMap} from 'react-leaflet'
import {Button} from "react-bootstrap";
import AdressSearch from "../AdressSearch";
import {fetchAPI} from "../API";
import {MDBBtn} from "mdb-react-ui-kit";


const POSITION_CLASSES = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
}

function ChangeView({center, zoom}): null {
    const map = useMap();
    map.setView(center, zoom);
    return null;
}

/**
 * @description Displays the button for centring the map on the user's position
 * @param position
 * @constructor
 */
function ButtonControl({position}) {
    const map = useMap();

    // Memoize the button so he don't rerender with position change of the map
    const button = React.useMemo(
        () => (
            <Button onClick={() => {
                navigator.geolocation.getCurrentPosition((position) => {
                    map.setView([position.coords.latitude, position.coords.longitude], 13)
                }, (e: any) => console.log(e), {enableHighAccuracy: true})

            }}>Ma position</Button>
        ),
        [],
    )

    const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright

    return (
        <div className={positionClass}>
            <div className="leaflet-control leaflet-bar">{button}</div>
        </div>
    );
}


export default function InterventionSiteChoice(
    {sites, isMobile, inlineChoice = false}: {
        sites: InterventionSite[],
        isMobile: boolean,
        inlineChoice?: boolean
    }
): React.ReactElement {

    const [coordinates, setCoordinates] = React.useState<[number, number]>([0, 0]);
    const [marker, setMarker] = React.useState<React.ReactElement>();
    const [wantNewAdress, setWantNewAdress] = React.useState<boolean>(false);


    const authObject: Auth = fetchAPI.authObject;


    function launchEvent(adress: InterventionSite | undefined): void {
        const event: CustomEvent<InterventionSite | undefined> = new CustomEvent("onSiteChoice", {detail: adress});
        document.dispatchEvent(event);
    }

    React.useEffect((): void => {
        if (sites.length === 0) {
            deleteAllFlashes();
            flash(5000, "warning", "Aucun site d'intervention trouvé.");
        }
    }, []);

    /**
     * @description Changes the display to highlight the intervention site selected by the user
     * @param idSite
     * @param coordinates
     */
    function choiceSite(idSite: number, coordinates: [number, number]) {
        setCoordinates(coordinates);

        const itemsList: NodeListOf<Element> = document.querySelectorAll(".siteIntervention");

        for (const item of itemsList) {
            item.classList.remove("active");
        }

        const itemActive: Element = document.querySelector(`[data-id="${idSite.toString()}"]`);

        itemActive.classList.add("active");
    }

    /**
     * @description Saves the address searched for and assigns a marker to the position of the address
     * @param adress
     */
    function handlerAdressSearch(adress: Adress): void {
        setCoordinates([adress.coordinates[0], adress.coordinates[1]]);
        setMarker(
            <Marker position={[adress.coordinates[0], adress.coordinates[1]]}>
                <Popup>
                    {adress.cityName}
                </Popup>
            </Marker>
        );
    }

    /**
     * @description Launches the event confirming the user's choice of address
     * @param adress
     */
    async function handlerAdressConfirm(adress: Adress): Promise<void> {
        const interventionSite: InterventionSite = await fetchAPI.creerSiteIntervention(adress.cityName, adress.cityName, adress.coordinates[0], adress.coordinates[1], authObject.jwt);
        launchEvent(interventionSite);
    }

    function getRenderComputer(): ReactElement {
        return (
            <>
                {sites.map((site) => (
                    <ListGroup.Item style={{cursor: "pointer"}} data-id={site.id.toString()} key={site.id} onClick={(e) => {
                        choiceSite(site.id, [site.latitude, site.longitude]);
                        if (inlineChoice) launchEvent(site);
                    }} className="listeChoixRdv siteIntervention">
                        <div className="enfant">
                            <p className="titleService">{site.nomSiteIntervention}</p>
                            <p className="descService">{site.locationSiteIntervention}</p>
                        </div>

                        {!inlineChoice &&
                            <div className="badgeGrid">
                                <div className="btn btn-dark" style={{marginTop: "50%"}}
                                     onClick={() => launchEvent(site)}>Choisir
                                </div>
                            </div>
                        }

                    </ListGroup.Item>
                ))}
            </>
        )
    }

    function getRenderMobile(): ReactElement {
        return (
            <>
                {sites.map((site) => (
                    <ListGroup.Item style={{cursor: "pointer"}} data-id={site.id.toString()} key={site.id} onClick={(e) => {
                        choiceSite(site.id, [site.latitude, site.longitude]);
                        if (inlineChoice) launchEvent(site);
                    }} className="listeChoixRdv siteIntervention">
                        <div className="enfant">
                            <p className="titleServiceMobile">{site.locationSiteIntervention}</p>
                        </div>

                        {!inlineChoice &&
                            <div className="badgeGrid" style={{alignSelf: 'center'}}>
                                <div className="btn btn-dark" onClick={() => launchEvent(site)}>Choisir</div>
                            </div>
                        }
                    </ListGroup.Item>
                ))}
            </>
        )
    }


    return (
        <div className="siteChoiceContainer">
            <section className="interventionSiteSection">
                {(sites.length === 0 && authObject.idBoss === "") || (wantNewAdress && authObject.idBoss === "") ?
                    <>
                        <h2 style={{textAlign: "center"}}>Saisir le lieu pour le rendez-vous</h2>
                        <AdressSearch handlerAdressConfirm={handlerAdressConfirm}
                                      handlerAdressSelected={handlerAdressSearch}></AdressSearch>
                    </>
                    :
                    <>
                        <h2 style={{textAlign: "center"}}>{isMobile ? "Choix adresse" : "Choix du lieu d'intervention"}</h2>
                        <ListGroup>
                            {isMobile ? getRenderMobile() : getRenderComputer()}

                            {authObject.idBoss === "" ?
                                !inlineChoice &&
                                <MDBBtn className="buttonAddNewAdress"
                                        onClick={() => setWantNewAdress(true)}>{isMobile ? "Ajouter" : "Ajouter nouvelle adresse"}</MDBBtn>
                                :
                                <></>
                            }
                        </ListGroup>
                    </>
                }
            </section>


            <aside className="asideMapContainer">
                <MapContainer center={[46.71109, 1.7191036]} zoom={15} scrollWheelZoom={true}>
                    <ChangeView center={coordinates} zoom={15}></ChangeView>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <ButtonControl position="topright"></ButtonControl>

                    {sites.map((site) => (
                        <Marker eventHandlers={{
                            click: (e: any) => {
                                choiceSite(site.id, [site.latitude, site.longitude]);
                            }
                        }} key={site.id} position={[site.latitude, site.longitude]}>
                            <Popup>
                                {site.nomSiteIntervention}
                            </Popup>
                        </Marker>
                    ))}
                    {marker}
                </MapContainer>

            </aside>

        </div>
    )
}


