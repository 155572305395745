import * as React from "react";
import {Pagination} from "react-bootstrap";

export default function PaginationTable({nbPages, currentPage, setCurrentPage, style = {}} : {nbPages: number, currentPage: number, setCurrentPage: any, style?: {}}): React.ReactElement {


    return (
        <Pagination style={style}>
            <Pagination.First onClick={() => setCurrentPage(1)}/>
            <Pagination.Prev onClick={() => {
                if (currentPage !== 1) setCurrentPage(currentPage-1);
            }}/>
            {[...Array(nbPages).keys()].map((pgNumber) => {

                pgNumber++;

                if (pgNumber <= currentPage+1 && pgNumber >= currentPage-1) {
                    return (
                        <Pagination.Item active={currentPage === pgNumber} key={pgNumber} onClick={() => setCurrentPage(pgNumber)}>{pgNumber}</Pagination.Item>
                    )
                } else if (pgNumber === nbPages || pgNumber === 1) {
                    return (
                        <Pagination.Item active={currentPage === pgNumber} key={pgNumber} onClick={() => setCurrentPage(pgNumber)}>{pgNumber}</Pagination.Item>
                    )
                } else if (pgNumber === 2 || pgNumber === nbPages-1) {
                    return (
                        <Pagination.Ellipsis></Pagination.Ellipsis>
                    )
                }
            })}

            <Pagination.Next onClick={() => {
                if (currentPage !== nbPages) setCurrentPage(currentPage+1);
            }}/>
            <Pagination.Last onClick={() => setCurrentPage(nbPages)}/>
        </Pagination>
    )
}
