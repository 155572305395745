import * as React from "react";
import {Carousel} from "react-bootstrap";
import {Service} from "../../types/type";

export default function CarouselService({services, serviceChoice}: {services: Service[], serviceChoice: (service: Service) => void}): React.ReactElement {

    return (
        <Carousel>
            {services.map((service: Service) => (
                <Carousel.Item key={service.idService}>
                    <svg onClick={() => serviceChoice(service)} className="imgCarousel" preserveAspectRatio="xMidYMid slice" role="img" width="100%" height="400">
                        <rect width="100%" height="100%" fill="green"/>
                        <text fontWeight="bold" fill="white" x="50%" y="50%" dy=".3em">Choisir</text>
                    </svg>
                    <Carousel.Caption>
                        <h3>{service.name}</h3>
                        <p>{service.description}</p>
                    </Carousel.Caption>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}
