import {Button, FloatingLabel, Form, FormCheck} from "react-bootstrap";
import {Table, Tbody, Td, Th, Thead, Tr} from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import * as React from "react";
import {Appointment, InputForm} from "../types/type";
import PaginationTable from "./PaginationTable";
import {getNumberOfPages} from '../Utils/functionManager';
import Row from "react-bootstrap/Row";
import ModalConfirmation from "./ModalConfirmation";
import {fetchAPI} from "./API";
import {flash} from "react-universal-flash";
import {redirect} from "react-router-dom";
import CustomForm from "./Forms/CustomForm";


const MAX_ELEMENT_PAGINATION: number = 12;




export default function HistoriqueRdv({rdvs}: { rdvs: Appointment[] }): React.ReactElement {

    const [render, setRender] = React.useState<React.ReactElement[]>([]);

    const [modalShowBillNumber, setModalShowBillNumber] = React.useState<boolean>(false);

    const [modalShowCustomerPaid, setModalShowCustomerPaid] = React.useState<boolean>(false);

    const [billNumber, setBillNumber] = React.useState<string>("");

    const [idAppointmentForUpdate, setIdAppointmentForUpdate] = React.useState<number>();

    const [customerPaid, setCustomerPaid] = React.useState<boolean>();


    const [nbPages, setNbPages] = React.useState<number>();
    const [currentPage, setCurrentPage] = React.useState<number>(1);


    const inputsFormUpdateBillNumber: InputForm[] = [
        {
            name: "billNumber",
            label: "Numéro de facture",
            type: "text",
            value: billNumber,
            setValue: (billNumber: any) => setBillNumber(billNumber.billNumber)
        },
        {
            name: "idAppointment",
            label: "IdAppointment",
            type: "hidden",
            value: idAppointmentForUpdate,
            setValue: (o: object) => {}
        }
    ]

    const inputsFormUpdateCustomerPaid: InputForm[] = [
        {
            name: "customerPaid",
            label: "Customer paid",
            type: "hidden",
            value: customerPaid,
            setValue: (o: object) => {}
        },
        {
            name: "idAppointment",
            label: "IdAppointment",
            type: "hidden",
            value: idAppointmentForUpdate,
            setValue: (o: object) => {}
        }
    ]


    React.useEffect(() => {
        closeAllModals();
        setNbPages(getNumberOfPages(rdvs, MAX_ELEMENT_PAGINATION));
        refreshRender();
    }, [rdvs]);

    function closeAllModals() {
        if (modalShowBillNumber) setModalShowBillNumber(false);
        if (modalShowCustomerPaid) setModalShowCustomerPaid(false);
    }


    function launchEvent(rdv: Appointment) {
        let event = new CustomEvent("onDetailRdv", {detail: rdv});
        document.dispatchEvent(event);
    }

    function getAllRdvsFiltered(): [Appointment[], Appointment[]] {

        const rdvsPasser: Appointment[] = rdvs.filter(objet => {
            objet.date.setHours(objet.horaireFin);
            if (objet.date < new Date()) return objet;
        });

        const rdvsEnCours: Appointment[] = rdvs.filter(objet => {
            objet.date.setHours(objet.horaireFin);
            if (objet.date >= new Date()) return objet;
        });

        return [rdvsPasser, rdvsEnCours];
    }

    function askUpdateBillNumber(idAppointment: number): void {
        setModalShowBillNumber(true);
        setIdAppointmentForUpdate(idAppointment);
    }

    function refreshRender() {
        const tabRdv: React.ReactElement[] = [];

        const [rdvsPasser, rdvsEnCours] = getAllRdvsFiltered();

        for (let rdv of rdvsEnCours) {
            let horaires: string = `${rdv.horaires[0]}h à ${Number(rdv.horaires[0]) + 1}h`;

            if (rdv.horaires.length > 1) horaires = `${rdv.horaires[0]}h à ${Number(rdv.horaires[rdv.horaires.length - 1]) + 1}h`;


            tabRdv.push(
                <Tr key={rdv.idRdv}>
                    <Td style={{backgroundColor: "yellow"}}>{rdv.service ? rdv.service.name : rdv.ticket.name}</Td>
                    <Td style={{backgroundColor: "yellow"}}>{`${rdv.service ? rdv.service.price : "Inconnu"} €`}</Td>
                    <Td style={{backgroundColor: "yellow"}}>{rdv.date.toLocaleDateString()}</Td>
                    <Td style={{backgroundColor: "yellow"}}>{horaires}</Td>
                    <Td style={{backgroundColor: "yellow"}}>{rdv.client.nom}</Td>
                    <Td style={{backgroundColor: "yellow"}}>{rdv.client.prenom}</Td>
                    <Td style={{backgroundColor: "yellow"}}>
                        {rdv.billNumber ? rdv.billNumber :
                            <Button onClick={() => askUpdateBillNumber(rdv.idRdv)}
                                    variant="success">Ajouter</Button>
                        }
                    </Td>
                    <Td style={{backgroundColor: "yellow"}}>
                        <FormCheck className="formCheckCustomTable" type="switch" checked={rdv.customerPaid}
                                   readOnly={false} onClick={(event) => handlerCustomerPaid(rdv.idRdv,event.target)}></FormCheck>
                    </Td>
                    <Td style={{backgroundColor: "yellow"}}><Button onClick={() => launchEvent(rdv)} variant="primary">Voir
                        détail</Button></Td>
                </Tr>
            );
        }

        for (let rdv of rdvsPasser) {
            let horaires = `${rdv.horaires[0]}h à ${Number(rdv.horaires[0]) + 1}h`;

            if (rdv.horaires.length !== 1) horaires = `${rdv.horaires[0]}h à ${Number(rdv.horaires[rdv.horaires.length - 1]) + 1}h`;

            let backGroundColor = "#ff9b9b";

            if (rdv.signature) backGroundColor = "#99CC99";

            tabRdv.push(
                <Tr key={rdv.idRdv}>
                    <Td style={{backgroundColor: backGroundColor}}>{rdv.service ? rdv.service.name : rdv.ticket.name}</Td>
                    <Td style={{backgroundColor: backGroundColor}}>{`${rdv.service ? rdv.service.price : "Inconnu"} €`}</Td>
                    <Td style={{backgroundColor: backGroundColor}}>{rdv.date.toLocaleDateString()}</Td>
                    <Td style={{backgroundColor: backGroundColor}}>{horaires}</Td>
                    <Td style={{backgroundColor: backGroundColor}}>{rdv.client.nom}</Td>
                    <Td style={{backgroundColor: backGroundColor}}>{rdv.client.prenom}</Td>
                    <Td style={{backgroundColor: backGroundColor}}>
                        {rdv.billNumber ? rdv.billNumber :
                            <Button onClick={() => askUpdateBillNumber(rdv.idRdv)}
                                    variant="success">Ajouter</Button>
                        }
                    </Td>
                    <Td style={{backgroundColor: backGroundColor}}>
                        <FormCheck className="formCheckCustomTable" type="switch" checked={rdv.customerPaid}
                                   readOnly={false} onClick={(e) => handlerCustomerPaid(rdv.idRdv,e.target)}></FormCheck>
                    </Td>
                    <Td style={{backgroundColor: backGroundColor}}><Button onClick={() => launchEvent(rdv)}
                                                                           variant="primary">Voir détail</Button></Td>
                </Tr>
            );
        }

        setRender(tabRdv);

    }

    function handlerCustomerPaid(idAppointment: number, target: any): void {
        setCustomerPaid(target.checked);
        setIdAppointmentForUpdate(idAppointment);
        setModalShowCustomerPaid(true);
    }


    return (
        <>
            <Table style={{textAlign: 'center'}}>
                <Thead style={{backgroundColor: "black"}} className="table-dark">
                    <Tr>
                        <Th>Prestation</Th>
                        <Th>Prix</Th>
                        <Th>Date</Th>
                        <Th>Horaire</Th>
                        <Th>Nom</Th>
                        <Th>Prénom</Th>
                        <Th>Numéro facture</Th>
                        <Th>Paiement</Th>
                        <Th>Action</Th>
                    </Tr>
                </Thead>
                <Tbody style={{verticalAlign: "middle"}}>
                    {render.slice((currentPage - 1) * MAX_ELEMENT_PAGINATION, currentPage * MAX_ELEMENT_PAGINATION)}
                </Tbody>
            </Table>

            <Row style={{marginTop: '5%'}}>
                <PaginationTable style={{justifyContent: "center"}} nbPages={nbPages} currentPage={currentPage}
                                 setCurrentPage={setCurrentPage}/>
            </Row>


            <ModalConfirmation
                form={true}
                body={
                    <CustomForm titleButtonSend="Enregistrer facture" inputs={inputsFormUpdateBillNumber}></CustomForm>
                }
                titre="Enregistrement du numéro de facture" fonctionBtnRefuse={() => setModalShowBillNumber(false)}
                show={modalShowBillNumber}>
            </ModalConfirmation>

            <ModalConfirmation
                form={true}
                body={
                    <CustomForm titleButtonSend={customerPaid ? "Confirmation paiement reçu" : "Confirmation paiement annulé"} inputs={inputsFormUpdateCustomerPaid}></CustomForm>
                }
                titre="Changement du statut de paiement" fonctionBtnRefuse={() => setModalShowCustomerPaid(false)}
                show={modalShowCustomerPaid}>
            </ModalConfirmation>

        </>
    )
}
