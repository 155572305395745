import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import {createStore, applyMiddleware, combineReducers} from 'redux';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import authReducer from './reducers/authReducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'], // Réduisez le state à persister (utilisez un nom de reducer approprié)
};

const rootReducer = combineReducers({
    auth: authReducer,
    // Ajoutez d'autres reducers ici au besoin
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(/* middleware si nécessaire */));
const persistor = persistStore(store);


export { store, persistor };
