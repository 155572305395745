export const PatternName: string = "[A-ZÀ-ÖØ-ÝĀ-Ž]{1}.+";

export const PatternLogin: string = "(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9]{3,30}";

export const PatternPassword: string = "(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,30}";

export const PatternImage: string = "\.(jpg|jpeg|png|webp|avif|gif|svg)$"

export const PatternClassic: string = ".{20,150}";


const PatternMinMaxGeneral: RegExp = new RegExp("\\.{\\d+,\\d+}");

export function PatternFrameNumberOfCharacters(min: number, max: number): string {
    return `.{${min},${max}}`;
}

export function PatternMessage(pattern: string): string {

    let message: string;

    switch (pattern) {
        case PatternLogin:
            message = "Au moins 1 majuscule, 1 minuscule, de 3 à 30 caractères."
            break;
        case PatternPassword:
            message = "Au moins 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial, de 8 à 30 caractères."
            break;
        case PatternName:
            message = "Commence avec 1 majuscule."
            break;
        case PatternClassic:
            message = "De 20 à 150 caractères";
            break;
        default:
            message = "Conditions non remplis."
            break;
    }


    if (PatternMinMaxGeneral.test(pattern)) {
        const {min, max} = slicePatternMinMax(pattern);
        message = `De ${min} à ${max} caractères`;
    }

    return message;
}

function slicePatternMinMax(pattern: string): {min: string, max: string} {
    const patternSliced: string = pattern.slice(2, pattern.length-2);
    const partsOfPattern: string[] = patternSliced.split(",");

    return {min: partsOfPattern[0], max: partsOfPattern[1]};
}
