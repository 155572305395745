import * as React from "react";
import {fetchAPI} from "./API";
import {Button, Card, FloatingLabel, Form} from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import Planning from "./RDV/Planning";
import ChoixEmployeAffectation from "./ChoixEmployeAffectation";
import {flash} from "react-universal-flash";
import {
    Appointment,
    Auth,
    IconButton,
    InterventionSite,
    LoaderDataUpdateAppointment, MaxMonthInterval,
    Schedule,
    Service
} from "../types/type";
import {redirect, useLoaderData, useNavigate} from "react-router-dom";
import {persistor, store} from "../store";
import OnlineDetect from "../OnlineDetection/OnlineDetect";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import * as L from "leaflet";
import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet'
import Col from "react-bootstrap/Col";

import blueMarker from '../img/leafletMarkers/blue.png';
import greenMarker from '../img/leafletMarkers/green.png';
import InterventionSiteChoice from "./RDV/InterventionSiteChoice";
import CustomTable from "./Table/CustomTable";
import {useMediaQuery} from "react-responsive";
import CustomForm from "./Forms/CustomForm";
import ModalConfirmation from "./ModalConfirmation";


export default function DetailRdvModif(): React.ReactElement {

    const [render, setRender] = React.useState<React.ReactElement>();

    const [showModalBillNumber, setShowModalBillNumber] = React.useState<boolean>(false);

    const [billNumber, setBillNumber] = React.useState<string>();

    const data: LoaderDataUpdateAppointment = useLoaderData() as LoaderDataUpdateAppointment;

    let rdvModif: Appointment = data.rdvModif;

    const interventionSites: InterventionSite[] = data.interventionSites;

    const isMobile: boolean = useMediaQuery({query: `(max-width: 700px)`});

    const services: Service[] = data.services;

    const maxMonthInterval: MaxMonthInterval = data.maxMonthInterval;

    const authObject: Auth = fetchAPI.authObject;

    const navigate = useNavigate();

    const blueIcon = new L.Icon({
            iconUrl: blueMarker,
            iconRetinaUrl: blueMarker,
            popupAnchor: [-0, -0],
            iconSize: [32, 45],
        }),
        greenIcon = new L.Icon({
            iconUrl: greenMarker,
            iconRetinaUrl: greenMarker,
            popupAnchor: [-0, -0],
            iconSize: [32, 45],
        });


    const headersTableService: string[] = ["id", "Nom service", "Description", "Durée", "Prix"];
    const keysTableService: string[] = ["idService", "name", "description", "durationString", "price"];

    const actions: IconButton[] = [
        {
            icon: {
                name: "hand-pointer",
                far: true,
                fas: false
            },
            nameEventClick: "choiceService"
        }
    ]


    React.useEffect(() => {

        async function onEmployeConfirm(event: any): Promise<void> {
            let idEmploye = event.detail.idEmploye;
            let idRdv = event.detail.rdv.idRdv;

            await fetchAPI.updateRdv(idRdv, idEmploye.toString(), null, null, authObject.jwt).then(response => {
                if (response.status !== 401 && response.status !== 404 && response.status !== 400) {
                    flash(5000, "success", "L'employe à bel et bien été affecté au rendez-vous.");
                }
            });

            await fetchRdv();
            rendering();
        }

        async function onConfirmSiteIntervention(event: any): Promise<void> {
            let idSite = event.detail;

            await fetchAPI.updateRdv(rdvModif.idRdv, null, null, null, authObject.jwt, idSite.toString()).then(response => {
                if (response.status !== 401 && response.status !== 404 && response.status !== 400) {
                    flash(5000, "success", "Le site d'intervention à bien été modifié.");
                }
            });

            await fetchRdv();
            rendering();
        }

        async function refreshDataRdv(): Promise<void> {
            await fetchRdv();
            rendering();
        }


        document.addEventListener("onChoixAffecterEmploye", onEmployeConfirm);
        document.addEventListener("onSiteChoice", onConfirmSiteIntervention);
        document.addEventListener("onRdvConfirm", refreshDataRdv);

        rendering();

        return () => {
            document.removeEventListener("onChoixAffecterEmploye", onEmployeConfirm);
            document.removeEventListener("onSiteChoice", onConfirmSiteIntervention);
            document.removeEventListener("onRdvConfirm", refreshDataRdv);
        }
    }, []);

    async function fetchRdv(): Promise<void> {
        rdvModif = await fetchAPI.getRdv(rdvModif.idRdv, authObject.jwt);
    }


    function changementHoraires(): void {
        setRender(
            <Planning isMobile={isMobile} maxMonthInterval={maxMonthInterval.nbMonths} idSite={rdvModif.siteIntervention.id} idService={rdvModif.service.idService}
                      jwt={authObject.jwt}
                      durationRdv={rdvModif.horaires.length} durationStringRdv={rdvModif.service.durationString} modifRdv={true} rdvModif={rdvModif}></Planning>
        );
    }

    function changementEmploye(): void {
        setRender(
            <ChoixEmployeAffectation rdv={rdvModif} jwt={authObject.jwt}
                                     idAdmin={Number(authObject.identifiant)}></ChoixEmployeAffectation>
        );
    }

    function changementLieu(): void {
        setRender(
            <InterventionSiteChoice isMobile={isMobile} sites={interventionSites}></InterventionSiteChoice>
        );
    }

    function changementBillNumber(): void {
        setShowModalBillNumber(true);
    }

    function changementService(): void {
        setRender(
            <CustomTable<Service> headers={headersTableService} keys={keysTableService} keyForKeyAttribute="idService"
                                  objects={services} actions={actions} handlerClick={choiceService}></CustomTable>
        );
    }

    async function choiceService(eventName: string, object: Service): Promise<void> {
        await fetchAPI.updateRdv(rdvModif.idRdv, null, null, null, authObject.jwt, null, object.idService.toString()).then(response => {
            if (response.status !== 401 && response.status !== 404 && response.status !== 400) {
                flash(5000, "success", "Le service à bien été modifié.");
            }
        });

        await fetchRdv();
        rendering();
    }

    function rendering() {
        let employe: string,
            horaires: string,
            textButtonEmploye: string;

        rdvModif.date.setHours(Number(rdvModif.horaireDepart));

        const today = new Date();

        const readOnlyRdv = rdvModif.date < today;

        rdvModif.date.setHours(rdvModif.horaireFin);

        const rdvFinish = rdvModif.date < today;

        if (rdvModif.horaires.length === 1) horaires = `Rendez-vous prévu à ${rdvModif.horaires[0]}h`;
        else horaires = `Rendez-vous prévu de ${rdvModif.horaires[0]}h à ${Number(rdvModif.horaires[rdvModif.horaires.length - 1]) + 1}h`


        textButtonEmploye = "Affecter un employe";

        if (rdvModif.employe) {
            employe = `Assigné à l'employé : ${rdvModif.employe.prenom} ${rdvModif.employe.nom}`;
            textButtonEmploye = "Modifier l'employe affecté";
        } else employe = "Aucun employé assigné pour l'instant";


        const client = `Client : ${rdvModif.client.prenom} ${rdvModif.client.nom}`;

        const siteIntervention = `Lieu d'intervention : ${rdvModif.siteIntervention.locationSiteIntervention}`;

        const service: string = `Service demandé : ${rdvModif.service.name}`;


        setRender(
            <Card>
                <Card.Body>
                    <Card.Title className="detailModifRdvText">{rdvModif.service.name}</Card.Title>
                    <Card.Text className="detailModifRdvText">
                        {rdvModif.sujet}
                    </Card.Text>
                </Card.Body>
                <ListGroup className="list-group-flush">
                    <ListGroup.Item className="detailModifRdvText">{client}</ListGroup.Item>
                    <ListGroup.Item className="detailModifRdvText">
                        {employe}
                        {!readOnlyRdv &&
                            <Button className="mx-3" key="btnEmploye" variant={rdvModif.employe ? "warning" : "success"}
                                    onClick={changementEmploye}>{textButtonEmploye}</Button>}
                    </ListGroup.Item>
                    <ListGroup.Item className="detailModifRdvText">
                        {`${horaires} pour le ${rdvModif.date.toLocaleDateString()}`}
                        {!readOnlyRdv &&
                            <Button className="mx-3" key="btnHoraires" variant="warning" onClick={changementHoraires}>Modifier
                                horaires</Button>}
                    </ListGroup.Item>
                    <ListGroup.Item className="detailModifRdvText">
                        {siteIntervention}
                        {!readOnlyRdv && <Button className="mx-3" key="btnLieuIntervention" variant="warning"
                                                 onClick={changementLieu}>Modifier lieu intervention</Button>}
                    </ListGroup.Item>

                    <ListGroup.Item className="detailModifRdvText">
                        {rdvModif.billNumber ? rdvModif.billNumber : "Aucun numéro de facture"}
                        {!readOnlyRdv && <Button className="mx-3" key="btnBillNumber" variant={rdvModif.billNumber ? "warning" : "success"}
                                                 onClick={changementBillNumber}>{rdvModif.billNumber ? "Modifier" : "Ajouter"}</Button>}
                    </ListGroup.Item>
                    <ListGroup.Item className="detailModifRdvText">

                        <div>
                            {service}
                            {!readOnlyRdv && <Button className="mx-3" key="btnService" variant="warning"
                                                     onClick={changementService}>Modifier service</Button>}
                        </div>

                        <div>
                            Description : {rdvModif.service.description}
                        </div>

                        <div>
                            Prix : {rdvModif.service.price}€
                        </div>

                        <div>
                            Durée : {rdvModif.service.durationString}
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item style={{
                        textAlign: "center",
                        backgroundColor: readOnlyRdv ? rdvFinish ? rdvModif.signature ? "lightgreen" : "red" : "yellow" : "lightblue"
                    }} className="detailModifRdvText">
                        {readOnlyRdv ?
                            rdvFinish ?
                                rdvModif.signature ?
                                    <div>Rendez-vous validé !</div>
                                    :
                                    <div>Rendez-vous echoué !</div>
                                :
                                <div>Rendez-vous en cours</div>
                            :
                            <div>Rendez-vous à venir</div>
                        }
                    </ListGroup.Item>
                </ListGroup>
                <Card.Body style={{display: "flex"}}>
                    {rdvModif.signature &&
                        <>
                            <Col>
                                <Card>
                                    <Card.Img variant="top" src={rdvModif.signature.signatureTechnicien.imagePath}/>
                                    <Card.Body>
                                        <Card.Title>{rdvModif.signature.nameSignature}</Card.Title>
                                        <Card.Text>
                                            <p style={{marginBottom: "0"}}>
                                                Signature effectuée
                                                le {rdvModif.signature.dateSignature.toLocaleDateString()}
                                            </p>
                                            <p>
                                                Horaire : {rdvModif.signature.hours}h{rdvModif.signature.minute}
                                            </p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>


                            <Col>
                                <MapContainer style={{width: "100%", height: "92%"}}
                                              center={[rdvModif.siteIntervention.latitude, rdvModif.siteIntervention.longitude]}
                                              zoom={15} scrollWheelZoom={true}>
                                    <TileLayer
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />

                                    <Marker icon={blueIcon}
                                            position={[rdvModif.siteIntervention.latitude, rdvModif.siteIntervention.longitude]}>
                                        <Popup>
                                            Site d'intervention
                                        </Popup>
                                    </Marker>

                                    <Marker icon={greenIcon}
                                            position={[rdvModif.signature.latitude, rdvModif.signature.longitude]}>
                                        <Popup>
                                            Lieu de la signature
                                        </Popup>
                                    </Marker>
                                </MapContainer>
                            </Col>
                        </>
                    }
                </Card.Body>
            </Card>
        );

    }

    async function handlerChangeBillNumber(): Promise<void> {
        await fetchAPI.updateRdv(rdvModif.idRdv, null, null, null, authObject.jwt, null, null, billNumber, null);
        navigate("/historique");
    }


    return (

        <>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>


                    <>
                        <div className="customCard">

                            {render}

                        </div>
                    </>

                    <ModalConfirmation
                        body={
                            <FloatingLabel
                                controlId="floatingTextarea"
                                label="Numéro facture"
                                className="mb-3"
                            >
                                <Form.Control onChange={(e) => setBillNumber(e.target.value)} value={billNumber}
                                              as="textarea"
                                              placeholder="Enregistrer le numéro de la facture" style={{resize: "none"}}/>
                            </FloatingLabel>
                        }
                        titre="Enregistrement du numéro de facture" fonctionBtnRefuse={() => setShowModalBillNumber(false)}
                        show={showModalBillNumber}
                        fonctionBtnAccept={handlerChangeBillNumber}>
                    </ModalConfirmation>


                    <OnlineDetect></OnlineDetect>

                </PersistGate>
            </Provider>
        </>


    );
}
