import * as React from 'react';
import {MDBIcon} from "mdb-react-ui-kit";
import {IconMDB} from "../../types/type";
import {typeFlashToIcon} from "../../types/Icons";


export default function FlashIcon ({type}: {type: string}): React.ReactElement {

    const icon: IconMDB = typeFlashToIcon.get(type);

    return (
        <>
            {icon && <MDBIcon style={{marginRight: 10, fontSize: "xx-large"}} far={icon.far} fas={icon.fas} icon={icon.name}></MDBIcon>}
        </>
    );
}
