import * as React from 'react'
import {flash} from "react-universal-flash";
import {Button, Dropdown, DropdownButton, Modal} from "react-bootstrap";
import {socket} from "../../socket";
import Participant from "./Participant";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {VisioConferenceProps} from "../../types/propsType";
import {DataVisio} from "../../types/type";

let localStream: MediaStream,
    roomCallId;

const constraints = {
    'video': true,
    'audio': true
}

const peers = {};

const streamsTemp: Map<string, MediaStream> = new Map();


export default function VisioConferenceMultipeer(
    {isEmploye, idRdv}: VisioConferenceProps
): React.ReactElement {

    const [showModal, setShowModal] = React.useState<boolean>(true);

    const navigate: NavigateFunction = useNavigate();

    const [itemsCamera, setItemsCamera] = React.useState<React.ReactElement[]>([]);
    const [itemsMicrophone, setItemsMicrophone] = React.useState<React.ReactElement[]>([]);

    const [cameraChoisie, setCameraChoisie] = React.useState<MediaDeviceInfo>(null);
    const [microphoneChoisie, setMicrophoneChoisie] = React.useState<MediaDeviceInfo>(null);

    const [callJoined, setCallJoined] = React.useState<boolean>(false);

    const [isVisio, setIsVisio] = React.useState<boolean>(false);

    const [remoteVideos, setRemoteVideos] = React.useState<any[]>([]);


    const refVideoElement = React.useRef<HTMLVideoElement>();
    const refButtonStartVisio = React.useRef<HTMLButtonElement>();
    const refConteneurRemoteVideo = React.useRef();


    React.useEffect(() => {

        //document.querySelector(".conteneur").classList.add("bg-black");

        navigator.mediaDevices.getUserMedia(constraints).then(registerMediaStream).catch(errorGetUserMedia);

        async function registerMediaStream(stream: MediaStream): Promise<void> {
            streamsTemp.set(stream.id, stream);

            await updateCameraListForUserChoice('videoinput');
            await updateMicrophoneList('audioinput');
        }

        function errorGetUserMedia(error: any): void {
            flash(5000, "danger", "Erreur lors de l'accès aux medias (voir console).");
            console.error('Error media devices.', error);
        }


        async function updateDevices(event: any): Promise<void> {
            await updateCameraListForUserChoice('video');
            await updateMicrophoneList('audio');
        }


        navigator.mediaDevices.addEventListener('devicechange', updateDevices);


        async function handleDataVisio(event: DataVisio): Promise<void> {
            const sid: string = event.sid;

            switch (event.data.type) {
                case 'offer':
                    peers[sid] = createPeerConnection(sid);
                    await peers[sid].setRemoteDescription(event.data);
                    await sendAnswer(sid);
                    break;
                case 'answer':
                    await peers[sid].setRemoteDescription(event.data);
                    break;
                case 'candidate':
                    if (!event.data.candidate) {
                        await peers[sid].addIceCandidate(null);
                    } else {
                        await peers[sid].addIceCandidate(event.data.candidate);
                    }
                    break;
            }
            console.log(peers);
        }


        function handleCallAlreadyStarted(isStarted: boolean): void {
            setIsVisio(isStarted);
        }

        function handleStartCall(): void {
            setIsVisio(true);
        }

        async function handleNewClientJoin(sid: string): Promise<void> {
            peers[sid] = createPeerConnection(sid);
            await sendOffer(sid);
        }

        function handleCallJoined(): void {
            refVideoElement.current.srcObject = localStream;
        }

        socket.on('visio', handleDataVisio);
        socket.on("call_already_started", handleCallAlreadyStarted);
        socket.on("start_call", handleStartCall);
        socket.on("new_client", handleNewClientJoin);
        socket.on("call_joined", handleCallJoined);

        return () => {
            navigator.mediaDevices.removeEventListener("devicechange", updateDevices);

            socket.off("visio", handleDataVisio);
            socket.off("call_already_started", handleCallAlreadyStarted);
            socket.off("start_call", handleStartCall);
            socket.off("new_client", handleNewClientJoin);
            socket.off("call_joined", handleCallJoined);
        }
    }, []);

    function stopStream(stream: MediaStream): void {
        stream.getTracks().forEach((track) => track.stop());
    }


    /**
     * Updates the select element with the provided set of cameras
     * @param type
     */
    async function updateCameraListForUserChoice(type: string): Promise<void> {
        const tabElement: React.ReactElement[] = await updateDeviceListForUserChoice(type, setCameraChoisie);

        setItemsCamera(tabElement);
    }

    async function updateMicrophoneList(type: string): Promise<void> {
        const tabElement: React.ReactElement[] = await updateDeviceListForUserChoice(type, setMicrophoneChoisie);

        setItemsMicrophone(tabElement);
    }

    async function updateDeviceListForUserChoice(type: string, handleClick: React.Dispatch<any>) {
        const devices: MediaDeviceInfo[] = await getConnectedDevices(type);
        const tabElement: React.ReactElement[] = [];

        for (const device of devices) {
            tabElement.push(
                <Dropdown.Item onClick={() => handleClick(device)}
                               key={device.deviceId}>{device.label}</Dropdown.Item>
            );
        }

        return tabElement;
    }

    /**
     * @description Asynchronous function retrieves equipment connected to the device, such as a camera or microphone.
     * @param type String representing the type of equipment to be retrieved as "audioinput".
     * @return {Promise<MediaDeviceInfo[]>} Promise containing all connected devices corresponding to the type specified when the function is called.
     */
    async function getConnectedDevices(type: string): Promise<MediaDeviceInfo[]> {
        const devices: MediaDeviceInfo[] = await navigator.mediaDevices.enumerateDevices();
        return devices.filter(device => device.kind === type);
    }

    /**
     * @description Asynchronous function for opening selected media using drop-down lists
     * @return {Promise<MediaStream>} A promise containing the MediaStream objects corresponding to the constraints
     */
    async function openDevices(): Promise<MediaStream> {

        const constraints: MediaStreamConstraints = {
            'audio': {
                'echoCancellation': true,
                'deviceId': microphoneChoisie.deviceId
            },
            'video': {
                'deviceId': cameraChoisie.deviceId
            }
        }
        return await navigator.mediaDevices.getUserMedia(constraints);
    }

    /**
     * @description Function that detects whether videoconferencing equipment has been selected and, if so, opens the equipment's video stream and sets up the local video object.
     */
    function handleChoixDevicesVisio(): void {

        if (cameraChoisie && microphoneChoisie && cameraChoisie.deviceId && microphoneChoisie.deviceId) {
            setShowModal(false);

            socket.emit("join_room_call", idRdv);

            roomCallId = `Call#${idRdv}`;

            openDevices().then(stream => {
                localStream = stream;

                for (let [key, value] of streamsTemp.entries()) {
                    if (key !== stream.id) {
                        stopStream(value);
                    }
                }

                if (isEmploye && !isVisio) refButtonStartVisio.current.style.display = "block";
            });
        } else {
            flash(5000, "warning", "Choisissez d'abord les équipements pour la visio.");
        }
    }


    function createPeerConnection(sid: string): RTCPeerConnection {


        const peerConnection: RTCPeerConnection = new RTCPeerConnection({
            iceServers: [
                {
                    urls: "stun:stun.relay.metered.ca:80",
                },
                {
                    urls: "turn:global.relay.metered.ca:80",
                    username: "091aea5d5fdfd7da6eb44af5",
                    credential: "dIklg20MsLRpzDJ8",
                },
                {
                    urls: "turn:global.relay.metered.ca:80?transport=tcp",
                    username: "091aea5d5fdfd7da6eb44af5",
                    credential: "dIklg20MsLRpzDJ8",
                },
                {
                    urls: "turn:global.relay.metered.ca:443",
                    username: "091aea5d5fdfd7da6eb44af5",
                    credential: "dIklg20MsLRpzDJ8",
                },
                {
                    urls: "turn:global.relay.metered.ca:443?transport=tcp",
                    username: "091aea5d5fdfd7da6eb44af5",
                    credential: "dIklg20MsLRpzDJ8",
                },
            ],
        });

        peerConnection.onicecandidate = function handlerIceCandidate(event: RTCPeerConnectionIceEvent) {
            console.log("Candidate !");

            const data: DataVisio = {
                sid: sid,
                data: {
                    type: 'candidate',
                    candidate: undefined
                }
            }

            if (event.candidate) {
                data.data.candidate = {
                    candidate: event.candidate.candidate,
                    sdpMid: event.candidate.sdpMid,
                    sdpMLineIndex: event.candidate.sdpMLineIndex
                }
            }

            socket.emit("visio", data);
        }

        peerConnection.addEventListener('track', (event: RTCTrackEvent) => {
            const remoteStream: MediaStream = event.streams[0];

            if (event.track.kind === "video") setRemoteVideos(oldArray => [...oldArray, <Participant videoStream={remoteStream}></Participant>]);
        });

        peerConnection.onicecandidateerror = error => console.error(error);

        peerConnection.addEventListener("connectionstatechange", (event) => console.info(event));

        localStream.getTracks().forEach(track => peerConnection.addTrack(track, localStream));

        console.log("Peer connection created !");

        return peerConnection;
    }

    async function setAndSendLocalDescription(sid: string, sessionDescription: RTCSessionDescriptionInit): Promise<void> {
        await peers[sid].setLocalDescription(sessionDescription);
        socket.emit("visio", {sid: sid, data: {type: sessionDescription.type, sdp: sessionDescription.sdp}});
    }

    async function sendOffer(sid: string): Promise<void> {
        console.log("Sending offer for " + sid);
        const offer = await peers[sid].createOffer();
        await setAndSendLocalDescription(sid, offer);
    }

    async function sendAnswer(sid: string): Promise<void> {
        console.log('Sending answer for ' + sid);
        const answer = await peers[sid].createAnswer();
        await setAndSendLocalDescription(sid, answer);
    }


    function joinCall(typeEvent: string): void {
        socket.emit(typeEvent);
        setCallJoined(true);
    }

    function leaveCall(): void {
        socket.emit("quit_call");
        stopStream(localStream);
        navigate("/");
    }

    return (
        <>
            {callJoined &&
                <div className="container" ref={refConteneurRemoteVideo}>
                    <div className="row">
                        <div className="col" style={{display: "flex", justifyContent:"center", paddingBottom: "3vh"}}>
                            <video style={{width: "30vw", height: "30vh", border: "5px solid green"}}
                                   ref={refVideoElement}
                                   autoPlay={true} playsInline={true} controls={true}></video>
                        </div>
                        {remoteVideos.map((remoteVideo) => (
                            <div className="col" style={{display: "flex", justifyContent:"center", paddingBottom: "3vh"}}>
                                {remoteVideo}
                            </div>
                        ))}
                    </div>
                </div>
            }

            <div style={{display: "flex", justifyContent: "center"}}>
                {!callJoined ?
                    <>
                        {isVisio ?
                            <Button onClick={() => joinCall("join_call")}>Rejoindre la visio en cours</Button>
                            :
                            isEmploye &&
                            <Button ref={refButtonStartVisio} style={{display: "none"}} onClick={() => joinCall("start_call")}>Commencer
                                la visio</Button>
                        }
                    </>
                    :
                    <></>
                }

            </div>

            {callJoined &&
                <div style={{display: "flex", position: "fixed", bottom: "3vh", width: "90%", justifyContent: "center"}}>
                    <Button onClick={leaveCall} className="btn btn-danger">Quitter</Button>
                </div>
            }



            <Modal show={showModal}
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Autorisation pour la visio
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Camera : {cameraChoisie ? cameraChoisie.label : "Aucune"}
                    <DropdownButton id="dropdown-basic-button" title="Choix camera">
                        {itemsCamera}
                    </DropdownButton>
                    Microphone : {microphoneChoisie ? microphoneChoisie.label : "Aucun"}
                    <DropdownButton id="dropdown-basic-button" title="Choix microphone">
                        {itemsMicrophone}
                    </DropdownButton>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleChoixDevicesVisio} variant="primary">Confirmer</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
