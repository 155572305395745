import * as React from "react";
import CustomForm from "./Forms/CustomForm";
import {InputForm, InterventionSite, Ticket} from "../types/type";
import {PatternFrameNumberOfCharacters} from "../types/regexPattern";
import {getEmptyTicket} from "../Utils/functionManager";
import InterventionSiteChoice from "./RDV/InterventionSiteChoice";

export default function TicketFormCreate({sites, isMobile}: {sites: InterventionSite[], isMobile: boolean}): React.ReactElement {

    const [targetTicket, setTargetTicket] = React.useState<Ticket>(getEmptyTicket());

    const [idAdress, setIdAdress] = React.useState<string>("");

    React.useEffect(() => {

        function onSiteChoice(event: any): void {
            if (event.detail) {
                const adress: InterventionSite = event.detail as InterventionSite;
                setIdAdress(adress.id.toString());
            }
        }

        document.addEventListener("onSiteChoice",onSiteChoice);


        return () => {
            document.removeEventListener("onSiteChoice", onSiteChoice);
        }
    }, [])

    const inputs: InputForm[] = [
        {
            name: "name",
            label: "Titre",
            type: "text",
            value: targetTicket.name,
            pattern: PatternFrameNumberOfCharacters(5, 100),
            setValue: (name: object) => setTargetTicket({...targetTicket, ...name}),
        },
        {
            name: "description",
            label: "Description",
            type: "textarea",
            value: targetTicket.description,
            setValue: (description: object) => setTargetTicket({...targetTicket, ...description})
        },
        {
            name: "idAdress",
            label: "idAdress",
            type: "hidden",
            value: idAdress,
            setValue: (object: any) => {}
        }
    ]

    return (
        <>
            <div>
                <CustomForm customElement={
                    (
                        <InterventionSiteChoice inlineChoice={true} sites={sites} isMobile={isMobile}></InterventionSiteChoice>
                    )
                } inputs={inputs} classButtonSend="buttonSendCustomForm" titleButtonSend="Envoyer"></CustomForm>
            </div>
        </>
    );
}
