import {Provider} from "react-redux";
import {persistor, store} from "../store";
import OnlineDetect from "../OnlineDetection/OnlineDetect";
import * as React from "react";
import {PersistGate} from "redux-persist/integration/react";
import PlanningOld from "../Components/PlanningOld";
import {fetchAPI} from "../Components/API";
import RdvDetail from "../Components/RdvDetail";
import {Auth} from "../types/type";

export default function RDVListeEmploye(): React.ReactElement {

    const [rdv, setRdv] = React.useState<any>(null);

    const authObject: Auth = fetchAPI.authObject;

    React.useEffect(() => {
        function onRdvClick(event) {
            setRdv(event.detail.extendedProps.rdv);
        }

        document.addEventListener("onRdvClick", onRdvClick);

        return () => {
            document.removeEventListener("onRdvClick", onRdvClick);
        }
    }, []);


    return (
        <>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>

                    <div className="customCard">


                        {rdv == null ?
                            <PlanningOld jwt={authObject.jwt} idEmploye={Number(authObject.identifiant)}></PlanningOld>
                            :
                            <RdvDetail jwt={authObject.jwt} rdv={rdv} idUser={Number(authObject.identifiant)}
                                       isEmploye={true}></RdvDetail>
                        }

                    </div>


                    <OnlineDetect></OnlineDetect>


                </PersistGate>
            </Provider>

        </>
    )
}
