import * as React from "react";
import {Schedule, Service, Time} from "../../types/type";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import {Badge, Button} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import {
    formatTimeToDisplay,
    getDayNameFromDate,
    getMondayOfTheCurrentWeek,
    getSegmentedTimeFromHour
} from "../../Utils/functionManager";
import CarouselService from "./CarouselService";
import {useMediaQuery} from "react-responsive";


const WEEK: number[] = [0, 1, 2, 3, 4, 5, 6];

export default function ServiceChoice({services, handlerSelectedService, schedules, isMobile}: {
    services: Service[],
    handlerSelectedService: (service: Service) => void,
    schedules: Schedule[],
    isMobile: boolean
}): React.ReactElement {

    const mondayOfTheWeek: Date = getMondayOfTheCurrentWeek();
    const todayInTheWeek: number = new Date().getDay();


    function getRenderTime(schedule: Schedule): string {
        if (schedule.start === -1) {
            return 'Fermé'
        } else {
            const startTime: Time = getSegmentedTimeFromHour(schedule.start),
                endTime: Time = getSegmentedTimeFromHour(schedule.end);

            return `${formatTimeToDisplay(startTime.hour)}:${formatTimeToDisplay(startTime.minute)} - ${formatTimeToDisplay(endTime.hour)}:${formatTimeToDisplay(endTime.minute)}`
        }
    }

    return (
        <Row className="mb-6" style={{justifyContent: "center"}}>
            <Col sm={7}>
                <h2 style={{textAlign: "center"}}>{isMobile ? "Choix de la prestation" : "Choix de la prestation désirée"}</h2>
                <ListGroup>
                    <CarouselService serviceChoice={handlerSelectedService} services={services}></CarouselService>
                </ListGroup>
            </Col>
            <Col sm={4} className="colHoursAppointment">
                <h2 style={{textAlign: "center"}}>Horaires d'ouverture</h2>
                <ListGroup className="groupHoursAppointment">

                    {WEEK.map((indexOfDay) => {

                        let schedule: Schedule = schedules[indexOfDay];

                        const day: Date = new Date(mondayOfTheWeek.getTime());

                        day.setDate(day.getDate() + indexOfDay);

                        if (!schedule) {
                            if (day.getDay() === 6 || day.getDay() === 0) {
                                schedule = {
                                    id: -1,
                                    date: day,
                                    start: -1,
                                    end: -1,
                                    timeTable: []
                                }
                            } else {
                                schedule = {
                                    id: -1,
                                    date: day,
                                    start: 8,
                                    end: 17,
                                    timeTable: []
                                }
                            }
                        }

                        return (
                            <ListGroup.Item className="rowOpenHoursAppointment"
                                            style={todayInTheWeek === schedule.date.getDay() ? {fontWeight: "bold"} : {}}
                                            key={schedule.date.toLocaleDateString()}>
                                <span>
                                    {getDayNameFromDate(schedule.date)}
                                </span>
                                <span>
                                    {getRenderTime(schedule)}
                                </span>
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>
            </Col>
        </Row>
    )
}

/**
 * {services.map((service) => (
 *                         <ListGroup.Item key={service.idService} className="listeChoixRdv">
 *                             <div>
 *                                 <p className="titleService">{service.name}</p>
 *                                 <p className="descService">{service.description}</p>
 *                             </div>
 *                             <div className="badgeGrid">
 *                                 <Badge className="customBadge" bg="secondary">{service.durationString}</Badge>
 *                                 <Badge className="customBadge" bg="primary">{`${service.price}€`}</Badge>
 *                                 <Button variant="dark" onClick={() => {
 *                                     handlerSelectedService(service);
 *                                 }}>Choisir</Button>
 *                             </div>
 *                         </ListGroup.Item>
 *                     ))}
 */
