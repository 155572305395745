import * as React from "react";
import {Auth, InputForm, Ticket} from "../types/type";
import {Button, Card, CardBody, CardHeader, FloatingLabel, Form, Modal} from "react-bootstrap";
import {MDBIcon} from "mdb-react-ui-kit";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ModalConfirmation from "./ModalConfirmation";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import {getDayNameFromDate, getMonthNameFromDate} from "../Utils/functionManager";
import ButtonIcon from "./Table/tableParts/ButtonIcon";
import {iconCalendarPlus} from "../types/Icons";
import PlanningDisplayOnly from "./RDV/PlanningDisplayOnly";
import {fetchAPI} from "./API";
import {useMediaQuery} from "react-responsive";
import {useNavigate} from "react-router-dom";
import {flash} from "react-universal-flash";
import CustomForm from "./Forms/CustomForm";

export default function TicketView({ticket, acceptTicket, isMobile, jwt}: { ticket: Ticket, acceptTicket: () => void, isMobile: boolean, jwt: string }): React.ReactElement {

    const [show, setShow] = React.useState<boolean>(false);

    const [showSupressModal, setShowSupressModal] = React.useState<boolean>(false);

    const navigate = useNavigate();

    const [showPlanning, setShowPlanning] = React.useState<boolean>(false);

    const [reasonForReject, setReasonForReject] = React.useState<string>("");

    const textArea = React.useRef<HTMLTextAreaElement>(null);


    function handlerClickLocation(event): void {
        event.preventDefault();
        setShow(true)
    }

    async function handlerRefuseTicket(): Promise<void> {
        if (reasonForReject.length == 0) {
            flash(3000, "warning", "Veuillez renseigner une raison pour refuser le ticket.");
        } else {
            await refuseTicket();
        }
    }

    function updateInput(newText: string): void {
        setReasonForReject(newText);
        if (textArea.current) {
            textArea.current.style.height = "inherit";
            textArea.current.style.height = `${textArea.current.scrollHeight}px`;
        }
    }

    async function refuseTicket(): Promise<void> {
        try {
            await fetchAPI.patch.ticket(ticket.id.toString(), undefined, undefined, true, reasonForReject, jwt);
            flash(3000, "success", "Le ticket à bien été refusé.")
            navigate("/ticketManagement");
        } catch (error) {
            flash(3000, "danger", error.message + ' ' + error.code);
        }
    }

    return (
        <>
            <Card>
                <CardHeader style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "large",
                    fontWeight: "600"
                }}>
                    <p style={{
                        margin: "0",
                        alignSelf: "center"
                    }}>Demande <span className="boldSpan">#{ticket.id}</span></p>
                    <div>
                        <Button style={{marginRight: "0.5vw"}} variant="danger" onClick={() => setShowSupressModal(true)}>
                            Refuser
                        </Button>
                        <Button onClick={acceptTicket}>
                            <MDBIcon style={{marginRight: "0.3vw"}} fas icon="check-circle"></MDBIcon>
                            Accepter
                        </Button>
                    </div>
                </CardHeader>
            </Card>
            <Card>
                <CardHeader style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "large",
                    fontWeight: "600"
                }}>
                    <p style={{
                        margin: "0",
                        alignSelf: "center",
                        fontWeight: "bold"
                    }}>Informations</p>
                    <div>

                    </div>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <p>
                                Demande de <span
                                className="boldSpan">{ticket.client.firstName} {ticket.client.lastName}</span>
                            </p>
                            <p>
                                Lieu de l'intervention : <a href="#" onClick={handlerClickLocation}><span className="boldSpan">{ticket.adress.locationSiteIntervention}</span> <MDBIcon fas icon="search"></MDBIcon></a>
                            </p>
                            <p>
                                Demande faite le <span className="boldSpan">{getDayNameFromDate(ticket.date, true)} {ticket.date.getDate()} {getMonthNameFromDate(ticket.date, true)} {ticket.date.getFullYear()}</span>
                            </p>
                            <p>
                                Description : {ticket.description}
                            </p>
                            <Button onClick={() => setShowPlanning(true)}>Voir disponibilités <MDBIcon icon="calendar" fas></MDBIcon></Button>
                        </Col>
                    </Row>


                </CardBody>
            </Card>


            <Modal show={show}
                   size="xl"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <Modal.Header className="customModal">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Location
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{height: "50vh"}}>
                        <MapContainer center={[ticket.adress.latitude, ticket.adress.longitude]} zoom={15}
                                      scrollWheelZoom={true}>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={[ticket.adress.latitude, ticket.adress.longitude]}>
                                <Popup>
                                    {ticket.adress.nomSiteIntervention}
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{justifyContent: "center"}}>
                    <Button onClick={() => setShow(false)}>Fermer</Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showPlanning}
                   size="xl"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <Modal.Header className="customModal">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Planning
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PlanningDisplayOnly jwt={jwt} isMobile={isMobile}></PlanningDisplayOnly>
                </Modal.Body>
                <Modal.Footer style={{justifyContent: "center"}}>
                    <Button onClick={() => setShowPlanning(false)}>Fermer</Button>
                </Modal.Footer>
            </Modal>

            <ModalConfirmation titre="Refus du ticket" fonctionBtnRefuse={() => setShowSupressModal(false)} show={showSupressModal} body={
                <FloatingLabel
                    controlId="floatingTextarea"
                    label="Raison du refus"
                    className="mb-3"
                >
                    <Form.Control ref={textArea} onChange={(e) => updateInput(e.target.value)} value={reasonForReject}
                                  as="textarea"
                                  placeholder="Enregistrer la raison du refus"/>
                </FloatingLabel>
                } fonctionBtnAccept={handlerRefuseTicket}></ModalConfirmation>
        </>
    )
}
