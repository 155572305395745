import * as React from "react";
import {ReactElement, useState} from "react";

//Elements graphiques
import {MDBIcon, MDBListGroup, MDBNavbarNav,} from "mdb-react-ui-kit";
import {MDBCollapse, MDBContainer, MDBNavbar, MDBNavbarBrand, MDBNavbarToggler, MDBNavItem} from "mdbreact";

import servitysLogo from '../../img/servitys_old.png';
import Link from './Link'
import {
    iconCalendarPlus,
    iconClock,
    iconEditUser, iconHand,
    iconHandShake,
    iconHistory,
    iconHome,
    iconList,
    iconMapMarker,
    iconSignOut, iconTicket,
    iconUser
} from "../../types/Icons";
import {
    APPOINTMENTS_HISTORY, APPOINTMENTS_NOT_ASSIGNED,
    EMPLOYEE_APPOINTMENTS,
    HOME,
    LOGOUT, MAKE_TICKET,
    SCHEDULE_MANAGEMENT,
    SERVICES_MANAGEMENT,
    TAKE_APPOINTMENT, TICKET_MANAGEMENT,
    USER_APPOINTMENTS,
    USERS_MANAGEMENT
} from "./LinksName";
import {useLocation, useNavigate} from "react-router-dom";
import ModalConfirmation from "../ModalConfirmation";
import {Button} from "react-bootstrap";
import {fetchAPI} from "../API";
import {Auth} from "../../types/type";
import {socket} from "../../socket";
import {useDispatch} from "react-redux";

/**
 * @description Component displaying a side navigation bar
 * @param jwt The jwt retrieved when the user logs in
 * @param isAdmin Boolean indicating whether the current user is an administrator
 * @param isEmploye Boolean indicating whether the current user is an employee
 * @param username The pseudonym of the logged-in user
 * @param isClientPro Boolean indicating whether the current user is a corporate customer
 * @param notifications
 * @return {Element} A React element that displays a navigation bar
 * @constructor
 */
export default function NavigationSideBar({jwt, isAdmin, isEmploye, username, isClientPro, notifications}): ReactElement {

    const [showShow, setShowShow] = useState<boolean>(false);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [showModalLogout, setShowModalLogout] = React.useState<boolean>(false);

    const location = useLocation();

    const auth: Auth = fetchAPI.authObject;

    const toggleShow = () => setShowShow(!showShow);

    const nbNotifs: number = notifications.reduce((acc, notification) => acc + notification.nbNotif, 0);

    function logout() {
        navigate("/logout");
    }

    React.useEffect(() => {

        function onNotif(notif: any): void {
            dispatch({type: "ADD_NOTIFICATION", payload: notif});
        }

        socket.on("notification", onNotif);

        setShowShow(false);

        return () => {
            socket.off("notification", onNotif);
        }
    }, [location.pathname]);

    /**
     * @description Retrieves the configured links to be displayed to the administrator
     */
    function getLinksForAdmin(): React.ReactElement {
        return (
            <>
                <Link route="/gestionUser" name={USERS_MANAGEMENT} icon={iconEditUser}></Link>
                <Link route="/gestionService" name={SERVICES_MANAGEMENT} icon={iconHandShake}></Link>
                <Link route="/gestionHoraires" name={SCHEDULE_MANAGEMENT} icon={iconClock}></Link>
                <Link route="/rdvNonAffect" nbNotif={nbNotifs} name={APPOINTMENTS_NOT_ASSIGNED} icon={iconHand}></Link>
                <Link route="/ticketManagement" name={TICKET_MANAGEMENT} icon={iconTicket}></Link>
                <Link route="/historique" name={APPOINTMENTS_HISTORY} icon={iconHistory}></Link>
            </>
        );
    }

    /**
     * @description Retrieves the configured links to be displayed to pro customers
     */
    function getLinksForProCustomer(): React.ReactElement {
        return (
            <>
                <Link route="/siteIntervention" name="Gestion site" icon={iconMapMarker}></Link>
                <Link route="/gestionClient" name="Gestion clients" icon={iconEditUser}></Link>
            </>
        );
    }

    /**
     * @description Retrieves the configured links to be displayed to customers
     */
    function getLinksForCustomer(): React.ReactElement {
        return (
            <>
                <Link route="/rdv" name={TAKE_APPOINTMENT} icon={iconCalendarPlus}></Link>
                <Link route="/ticket" name={MAKE_TICKET} icon={iconTicket}></Link>
                <Link route="/myRdv" nbNotif={nbNotifs} name={USER_APPOINTMENTS} icon={iconUser}></Link>
            </>
        );
    }

    /**
     * @description Retrieves the configured links to be displayed to employees
     */
    function getLinksForEmployee(): React.ReactElement {
        return (
            <>
                <Link route="/rdvListe" nbNotif={nbNotifs} name={EMPLOYEE_APPOINTMENTS} icon={iconList}></Link>
            </>
        )
    }

    return (
        <>
            <MDBCollapse tag="nav" className={`d-lg-block bg-white sidebar ${showShow ? 'show' : ''}`}>
                <div className="position-sticky">
                    <MDBListGroup flush="true" className="mx-3 mt-4">
                        <Link route="/" name={HOME} icon={iconHome}></Link>
                        {jwt ?
                            isEmploye ?
                                getLinksForEmployee()
                                :
                                isAdmin ?
                                    getLinksForAdmin()
                                    :
                                    isClientPro ?
                                        getLinksForProCustomer()
                                        :
                                        getLinksForCustomer()
                            :
                            <>
                            </>
                        }
                        <Link route="/logout" name={LOGOUT} icon={iconSignOut}
                              clickHandler={() => setShowModalLogout(true)}></Link>
                    </MDBListGroup>
                </div>
            </MDBCollapse>

            <MDBNavbar expand='lg' light bgcolor='light' className="navBarSideBar">
                <MDBContainer fluid>
                    <MDBNavbarNav className="d-flex flex-row align-items-center w-auto">
                        <MDBNavbarToggler
                            type='button'
                            aria-label='Toggle navigation'
                            onClick={toggleShow}
                        >
                            <MDBIcon style={{color: 'white'}} icon='bars' fas/>
                        </MDBNavbarToggler>
                        <MDBNavbarBrand>
                            <img
                                className="ms-2 servitysImg"
                                src={servitysLogo}
                                height='70'
                                alt=''
                                loading='eager'
                            />
                        </MDBNavbarBrand>
                    </MDBNavbarNav>
                    <MDBNavItem className="d-flex flex-row align-items-center w-auto username">
                        <Button onClick={() => navigate("/account")} variant="light">{username}</Button>
                    </MDBNavItem>
                </MDBContainer>
            </MDBNavbar>


            <ModalConfirmation titre="Voulez-vous vraiment vous déconnecter ?" fonctionBtnAccept={logout}
                               fonctionBtnRefuse={() => setShowModalLogout(false)} show={showModalLogout}
                               body={<></>}></ModalConfirmation>
        </>

    );
}
