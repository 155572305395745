import * as React from "react";
import {Token, User, UserRegistration} from "../../types/type";
import {flash} from "react-universal-flash";
import {MDBBtn, MDBContainer, MDBInput} from "mdb-react-ui-kit";
import {Form, useNavigate} from "react-router-dom";
import {PatternLogin, PatternName, PatternPassword} from "../../types/regexPattern";
import {fetchAPI} from "../API";
import {useDispatch} from "react-redux";
import servitysLogo from "../../img/servitys.png";

export default function RegisterForm({handlerChangeToLogin}: { handlerChangeToLogin: () => void }): React.ReactElement {

    const [userRegistration, setUserRegistration] = React.useState<UserRegistration>({
        firstName: "",
        lastName: "",
        loginUser: "",
        pwdUser: "",
        pwdUserConfirm: ""
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isVisible, setIsVisible] = React.useState<boolean>(false);

    /**
     * @description Check if the informations passed into the form are correct
     * @param e
     */
    async function checkValidity(e): Promise<void> {
        e.preventDefault();
        if (!e.target.checkValidity() || !checkPwd()) {
            e.target.reportValidity();
        } else {
            await handlerClickRegister();
        }
    }

    /**
     * @description Check if the password hit by the user is correct
     */
    function checkPwd(): boolean {
        const samePwd: boolean = userRegistration.pwdUser === userRegistration.pwdUserConfirm;
        if (!samePwd) flash(3000, "warning", "Les mots de passes doivent être identiques.");
        return samePwd;
    }

    /**
     * @description Register the user in the API and connect him
     */
    async function handlerClickRegister(): Promise<void> {
        try {
            await registerJWT();
            await connection();
        } catch (error) {
            flash(3000, "danger", `${error.message} | code : ${error.code}`);
        }
    }

    /**
     * @description Create a user with the API
     */
    async function registerJWT(): Promise<void> {
        await fetchAPI.creerUser(userRegistration.lastName, userRegistration.firstName, userRegistration.loginUser, userRegistration.pwdUser);
        /*
        const tokenJSON: string = await fetchAPI.connexion(userConnection.loginUser, userConnection.pwdUser);
        dispatch({type: 'SET_JWT', payload: tokenJSON});
        navigate("/");
         */
    }

    /**
     * @description Connects the user with the information defined in the form
     */
    async function connection(): Promise<void> {
        const token: Token = await fetchAPI.connexion(userRegistration.loginUser, userRegistration.pwdUser);
        dispatch({type: 'SET_JWT', payload: token});
        navigate("/");
    }

    /**
     * Switch the current form to the login
     * @param e
     */
    function changeToLogin(e) {
        e.preventDefault();
        handlerChangeToLogin();
    }

    return (
        <Form style={{display: "contents"}} method="post" action="" onSubmit={checkValidity}>
            <MDBContainer className="p-3 my-5 d-flex flex-column loginContainer">

                <img
                    className="logoServitysLoginPage"
                    src={servitysLogo}
                    alt="logo servitys"
                />

                <h2 className="titleConnectionForm">Créer un compte</h2>

                <MDBInput required={true} pattern={PatternName} value={userRegistration.firstName} wrapperClass='mb-4'
                          label='Prénom'
                          id='form1' type='text'
                          onChange={(e) => setUserRegistration({...userRegistration, "firstName": e.target.value})}/>

                <MDBInput required={true} pattern={PatternName} value={userRegistration.lastName} wrapperClass='mb-4'
                          label='Nom'
                          id='form2' type='text'
                          onChange={(e) => setUserRegistration({...userRegistration, "lastName": e.target.value})}/>

                <MDBInput required={true} pattern={PatternLogin} value={userRegistration.loginUser} wrapperClass='mb-4'
                          label='Login'
                          id='form3' type='text'
                          onChange={(e) => setUserRegistration({...userRegistration, "loginUser": e.target.value})}/>

                <div className="input-group mb-3">
                    <input
                        required={true}
                        pattern={PatternPassword}
                        type={isVisible ? "text" : "password"}
                        className="form-control rounded"
                        placeholder="Mot de passe"
                        aria-label="Mot de passe"
                        aria-describedby="search-addon"
                        value={userRegistration.pwdUser}
                        onChange={(e) => setUserRegistration({...userRegistration, "pwdUser": e.target.value})}
                    />
                    <span onClick={() => setIsVisible(!isVisible)} className="input-group-text border-0"
                          id="search-addon"><i className="fas fa-eye"></i></span>
                </div>

                <div className="input-group mb-3">
                    <input
                        required={true}
                        pattern={PatternPassword}
                        type={isVisible ? "text" : "password"}
                        className="form-control rounded"
                        placeholder="Confirmation du mot de passe"
                        aria-label="Confirmation du mot de passe"
                        aria-describedby="search-addon"
                        value={userRegistration.pwdUserConfirm}
                        onChange={(e) => setUserRegistration({...userRegistration, "pwdUserConfirm": e.target.value})}
                    />
                    <span onClick={() => setIsVisible(!isVisible)} className="input-group-text border-0"
                          id="search-addon"><i className="fas fa-eye"></i></span>
                </div>


                <MDBBtn className="mb-4" type="submit">Enregistrer</MDBBtn>

                <div className="d-flex mx-3 mb-4" style={{justifyContent: "center"}}>
                    Déjà client ? <a href="" style={{marginLeft: 5}} onClick={changeToLogin}> Se connecter</a>
                </div>

            </MDBContainer>
        </Form>
    )
}

