import * as React from "react";
import {Carousel, Image} from "react-bootstrap";
import plaine from '../img/Carousel/plaine.jpg'

export default function TestGrayScale (): React.ReactElement {


    return (
        <div className="customCard">
            <Carousel>
                <Carousel.Item>
                    <Image className="imgCarousel" src={plaine}></Image>
                    <Carousel.Caption>
                        <h3>First slide label</h3>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <Image className="imgCarousel" src={plaine}></Image>
                    <Carousel.Caption>
                        <h3>Second slide label</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <Image className="imgCarousel" src={plaine}></Image>
                    <Carousel.Caption>
                        <h3>Third slide label</h3>
                        <p>
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                        </p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

