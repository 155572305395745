import * as React from 'react'
import 'reactjs-popup/dist/index.css';
import {Modal} from "react-bootstrap";

export default function OnlineDetect(): React.ReactElement {

    const [online, setOnline] = React.useState<boolean>(navigator.onLine);
    const [open, setOpen] = React.useState<boolean>(false);

    //Détection avec ajout et remove des eventListener pour savoir si on utilise l'application en "online" ou en "offline"
    React.useEffect(() => {
        function online(): void {
            setOnline(true);
            setOpen(true);
        }

        function offline(): void {
            setOnline(false);
            setOpen(true);
        }

        window.addEventListener("online", online);
        window.addEventListener("offline", offline);


        return (): void => {
            window.removeEventListener("online", online);
            window.removeEventListener("offline", offline);
        }

    }, []);

    //Detection du changement du state "online" et donc de l'affichage de la popup


    React.useEffect((): void => {
        if (open) setTimeout(() => setOpen(false), 2000);
    }, [open]);


    return (
        <>
            <Modal show={open}>
                <Modal.Header>
                    <Modal.Title>Changement de connexion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {online ? <p className="fs-5">Vous utilisez l'application en ligne</p> :
                        <p className="fs-5">Vous utilisez l'application hors ligne</p>}
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    )


}
