// reducers/authReducer.js
import {jwtDecode} from "jwt-decode";
import {Notification, Token} from "../types/type";
import {fetchAPI} from "../Components/API";
import {socket} from "../socket";

const initialState = {
    jwt: undefined,
    refresh_jwt: undefined,
    isAdmin: false,
    isEmploye: false,
    isClientPro: false,
    identifiant: "",
    username: "",
    isConnected: false,
    notifications: [],
    idBoss: "",
    socketConnected: false,
    sid: ""
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_JWT':
            const token: Token = action.payload.token;
            const notifications: Notification[] = action.payload.notifications;
            let decodedJwt = jwtDecode(token.token);

            return {
                ...state,
                jwt: token.token,
                refresh_jwt: token.refresh_token,
                isAdmin: decodedJwt.roles.includes("ROLE_ADMIN"),
                isEmploye: decodedJwt.roles.includes("ROLE_EMPLOYE"),
                isClientPro: decodedJwt.roles.includes("ROLE_CLIENTPRO"),
                username: decodedJwt.username,
                identifiant: decodedJwt.identifiant,
                isConnected: true,
                notifications: notifications,
                idBoss: decodedJwt.idBoss
            };
        case 'LOGOUT':
            socket.disconnect()
            console.log("SOCKET DISCONNECTED");
            return initialState;
        case 'ADD_NOTIFICATION':
            const {idReceiver, idAppointment} = action.payload;
            const idNotif: string = `${idAppointment}-${idReceiver}`;

            console.info("ADD NOTIFICATION !");

            if (state.notifications.filter(notif => notif.id === idNotif).length === 1) {
                const indexOfNotif: number = state.notifications.findIndex(notif => notif.id === idNotif);
                state.notifications[indexOfNotif].nbNotif++;
                return {
                    ...state
                };
            }

            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        id: idNotif,
                        idRdv: idAppointment,
                        idUser: idReceiver,
                        nbNotif: 1
                    }
                ]
            }
        case 'ERASE_NOTIFICATIONS':
            const idRdv: number = action.payload;

            return {
                ...state,
                notifications: state.notifications.filter(notification => notification.idRdv !== idRdv)
            }
        case 'ERASE_ALL_NOTIFICATIONS':
            return {
                ...state,
                notifications: []
            }
        default:
            return state;
    }
};

export default authReducer;
