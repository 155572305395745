import {flash} from "react-universal-flash";

export default class ErrorJWT extends Error {

    private readonly code: number;

    constructor(msg: string, code: number) {
        super(msg);
        this.code = code;
        Object.setPrototypeOf(this, ErrorJWT.prototype);
    }

    public flashError() {
        switch (this.code) {
            case 401:
                flash(4000, "warning", "La connexion est expirée");
                break;
        }
    }
}
