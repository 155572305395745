import {MDBCard, MDBCardBody, MDBCardImage, MDBCol, MDBContainer, MDBRow} from "mdb-react-ui-kit";
import qrCode from "../img/QRCODE_DEV.png";
import servitysLogo from '../img/servitys.png';
import ButtonInstallApp from "../Components/ButtonInstallApp";
import LoginForm from "../Components/Connection/LoginForm";
import * as React from "react";
import {useMediaQuery} from "react-responsive";
import RegisterForm from "../Components/Connection/RegisterForm";

export default function Login(): React.ReactElement {

    const isMobile: boolean = useMediaQuery({query: `(max-width: 760px)`});
    const isStandalone: boolean = window.matchMedia('(display-mode: standalone)').matches;

    const displayForm: boolean = isStandalone || !isMobile;

    const [wantLogin, setWantLogin] = React.useState<boolean>(true);

    return (
        <>
            <div className="customCard">
                <MDBRow>
                    <MDBCol className="ms-2 qr-code-card">
                        <MDBRow className="justify-content-center">
                            <img
                                className="logoServitysConnectionPage"
                                src={servitysLogo}
                                alt="logo servitys"
                            />
                        </MDBRow>

                        <MDBRow className="justify-content-center">
                            <MDBCard className="align-items-center">
                                <MDBCardImage src={qrCode} alt="qrCode" className="qrCode"></MDBCardImage>
                                <MDBCardBody>
                                    <p>Scanner le qr code pour être ramené sur le site</p>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBRow>
                    </MDBCol>
                    <MDBCol className="ms-2 align-items-center conteneurBlock">
                        {!isStandalone &&
                            <MDBContainer className="p-3 my-5 flex-column conteneurInstallBouton">
                                {isMobile && <img
                                    className="logoServitysInstall"
                                    src={servitysLogo}
                                    alt="logo servitys"
                                />}
                                <ButtonInstallApp></ButtonInstallApp>
                            </MDBContainer>
                        }

                        {displayForm ?
                            wantLogin ?
                                <LoginForm handlerChangeToRegistration={() => setWantLogin(false)}></LoginForm>
                                :
                                <RegisterForm handlerChangeToLogin={() => setWantLogin(true)}></RegisterForm>
                            :
                            <>
                            </>
                        }

                    </MDBCol>

                </MDBRow>
            </div>
        </>
    );
}
