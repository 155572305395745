import Tab from 'react-bootstrap/Tab';
import * as React from "react";
import {AppointmentChoice, InterventionSite, Schedule, Service} from "../../types/type";
import InterventionSiteChoice from "./InterventionSiteChoice";
import ServiceChoice from "./ServiceChoice";

/**
 * @description A component giving users a choice of Service and location for their appointments
 * @param jwt The jwt retrieved when the user logs in
 * @param services
 * @param sites
 * @param schedules
 * @param isMobile
 * @return {JSX.Element}
 * @constructor
 */
export default function ChoixRDV(
    {services, sites, schedules, isMobile, onSiteChoice, onServiceChoice, serviceChoice, interventionSiteChoice}:
        {
            services: Service[],
            sites: InterventionSite[],
            schedules: Schedule[],
            isMobile: boolean,
            serviceChoice?: Service,
            interventionSiteChoice?: InterventionSite,
            onSiteChoice: (site: InterventionSite) => void,
            onServiceChoice: (service: Service) => void
        }
): React.ReactElement {


    /**
     * @description Refreshes the component's visual display by retrieving the intervention sites it can choose from
     */

    React.useEffect(() => {

        function siteChoice(event: any) {
            if (event.detail) {
                const adress: InterventionSite = event.detail as InterventionSite;
                onSiteChoice(adress);
            }
        }

        document.addEventListener("onSiteChoice", siteChoice);

        return () => {
            document.removeEventListener("onSiteChoice", siteChoice);
        }
    });

    function selectService(service: Service): void {
        onServiceChoice(service);
    }


    /**
     * @description Launches an event indicating the user's choice of intervention site
     * @param data object containing information about the selected intervention site
     */
    function launchEvent(data: AppointmentChoice) {
        const choixRdvEffectuer: CustomEvent<AppointmentChoice> = new CustomEvent("onChoixEffectuer", {detail: data});
        document.dispatchEvent(choixRdvEffectuer);
    }


    return (
        <Tab.Container id="list-group-tabs-example">
            {serviceChoice ?
                <InterventionSiteChoice isMobile={isMobile} sites={sites}></InterventionSiteChoice>
                :
                <ServiceChoice isMobile={isMobile} schedules={schedules} services={services}
                               handlerSelectedService={selectService}></ServiceChoice>
            }
        </Tab.Container>
    )
}
