import {fetchAPI} from "../../../Components/API";
import {redirect} from "react-router-dom";
import {flash} from "react-universal-flash";
import {Auth, DataActionService, Service} from "../../../types/type";
import {compareServices} from "../../../Utils/functionManager";

export default async function actionsService({request}): Promise<Response> {
    const formData = await request.formData();
    const authObject: Auth = fetchAPI.authObject;

    const data: DataActionService = {
        service: {
            idService: Number(formData.get("idService")),
            name: formData.get("name"),
            duration: Number(formData.get("duration")),
            description: formData.get("description"),
            status: formData.get("status") === "true",
            price: Number(formData.get("price")),
            durationString: ""
        },
        authObject: authObject,
    }

    let success: boolean = false;

    switch (formData.get("action")) {
        case "update":
            const initialService: Service = await fetchAPI.getService(data.service.idService, data.authObject.jwt);
            success = await verifUpdateService(data, initialService);
            break;
        case "create":
            success = await verifCreateService(data);
            break;

    }


    return success ? redirect("/gestionService") : null;
}


async function verifCreateService(data: DataActionService): Promise<boolean> {
    let success = false;

    if (data.service.name.length === 0) flash(5000, "warning", "Le nom doit être renseigné.");
    else if (data.service.description.length === 0) flash(5000, "warning", "La description doit être renseignée.");
    else if (data.service.duration === 0) flash(5000, "warning", "La durée du service ne peux pas être à 0.");
    else if (data.service.price === 0) flash(5000, "warning", "Le prix du service ne peux pas être à 0.");
    else {
        const response = await fetchAPI.createService(data.service.name, data.service.description, data.service.duration, data.service.price, data.service.status, data.authObject.jwt);
        console.log(response);
        if (response.code) {
            flash(5000, "danger", "Une erreur est survenue (cf. voir la console pour plus d'information)");
        } else {
            flash(5000, "success", "Le service à bien été créer.");
            success = true;
        }
    }

    return success;

}

async function verifUpdateService(data: DataActionService, initialService: Service): Promise<boolean> {

    let success = false;

    const isSafeToEdit = await fetchAPI.isServiceSafeToEdit(data.service.idService, data.authObject.jwt);


    if (data.service.name.length === 0) flash(5000, "warning", "Le nom doit être renseigné.");
    else if (data.service.description.length === 0) flash(5000, "warning", "La description doit être renseignée.");
    else if (data.service.duration === 0) flash(5000, "warning", "La durée du service ne peux pas être à 0.");
    else if (data.service.price === 0) flash(5000, "warning", "Le prix du service ne peux pas être à 0.");
    else if (initialService.status && data.service.status) flash(5000, "warning", "Un service ne peut pas être modifié tant qu'il est actif sur le site.");
    else if (!compareServices(initialService, data.service)) {
        if (!isSafeToEdit) {
            flash(5000, "danger", "Attention, vous ne pouvez pas modifier un service qui possède des rendez-vous en cours.");
            return false;
        } else {
            await updateService();
        }
    }
    else {
        await updateService()
    }

    async function updateService(): Promise<void> {
        const response = await fetchAPI.updateService(data.service.idService, data.service.name, data.service.description, data.service.duration, data.service.price, data.service.status, data.authObject.jwt);
        console.log(response);
        if (response.code) {
            flash(5000, "danger", "Une erreur est survenue (cf. voir la console pour plus d'information)");
        } else {
            flash(5000, "success", "Le service à bien été modifié.");
            success = true;
        }
    }

    return success;
}
