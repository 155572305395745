import * as React from "react";
import {Auth, IconButton, InputForm, InterventionSite, User} from "../types/type";
import {useLoaderData, useNavigate} from "react-router-dom";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "../store";
import Row from "react-bootstrap/Row";
import CustomForm from "../Components/Forms/CustomForm";
import {PatternLogin, PatternName, PatternPassword} from "../types/regexPattern";
import Col from "react-bootstrap/Col";
import {fetchAPI} from "../Components/API";
import CustomTable from "../Components/Table/CustomTable";
import {DataAccount} from "../types/loaderType";
import {iconEdit, iconTrash} from "../types/Icons";
import ModalConfirmation from "../Components/ModalConfirmation";
import {MDBInput} from "mdb-react-ui-kit";
import {Button} from "react-bootstrap";
import ApiException from "../Exceptions/ApiException";
import {flash} from "react-universal-flash";
import {ex} from "@fullcalendar/core/internal-common";

export default function Account(): React.ReactElement {

    const data: DataAccount = useLoaderData() as DataAccount;

    const user: User = data.user;

    const interventionSites: InterventionSite[] = data.interventionSites;

    const auth: Auth = fetchAPI.authObject;

    const isClient: boolean = !auth.isClientPro && !auth.isAdmin && !auth.isEmploye && auth.idBoss === "";

    const navigate = useNavigate();

    const [userInformation, setUserInformation] = React.useState<User>(user);

    const [showModal, setShowModal] = React.useState<boolean>(false);

    const [isVisible, setIsVisible] = React.useState<boolean>(false);

    const [changePwd, setChangePwd] = React.useState<boolean>(false);

    const [targetSite, setTargetSite] = React.useState<InterventionSite>();

    const [pwd, setPwd] = React.useState<string>("");

    const [newPwd, setNewPwd] = React.useState<string>("");
    const [newPwdConfirm, setNewPwdConfirm] = React.useState<string>("");

    const inputs: InputForm[] = [
        {
            name: "lastName",
            label: "Nom",
            type: "text",
            pattern: PatternName,
            value: userInformation.lastName,
            setValue: (lastName: object) => setUserInformation({...userInformation, ...lastName})
        },
        {
            name: "firstName",
            label: "Prénom",
            type: "text",
            pattern: PatternName,
            value: userInformation.firstName,
            setValue: (firstName: object) => setUserInformation({...userInformation, ...firstName})
        },
        {
            name: "login",
            label: "Login",
            type: "text",
            pattern: PatternLogin,
            value: userInformation.login,
            setValue: (login: object) => setUserInformation({...userInformation, ...login})
        },
        {
            name: "action",
            label: "Action",
            type: "hidden",
            value: "update",
            setValue: (o: object) => {
            }
        },
        {
            name: "id",
            label: "IdUser",
            type: "hidden",
            value: auth.identifiant,
            setValue: (o: object) => {}
        },
        {
            name: "pwd",
            label: "Mot de passe",
            type: "hidden",
            required: false,
            value: "",
            setValue: (pwd: object) => {}
        },
        {
            name: "pwdConfirm",
            label: "Confirmation du mot de passe",
            type: "hidden",
            value: "",
            setValue: (pwdConfirm: object) => {}
        },
        {
            name: "objectType",
            label: "ObjectType",
            type: "hidden",
            value: auth.isEmploye ? "employe" : "client",
            setValue: (o: object) => {}
        }
    ]

    const headers: string[] = ["Adresse"]

    const keys: string[] = ["locationSiteIntervention"]

    const actions: IconButton[] = [
        {
            icon: iconEdit,
            nameEventClick: "udpate"
        },
        {
            icon: iconTrash,
            nameEventClick: "delete"
        }
    ]


    function handlerAction(eventName: string, interventionSite: InterventionSite) {
        if (eventName === "delete") {
            setTargetSite(interventionSite);
            setShowModal(true);
        } else navigate(`/updateInterventionSite/${interventionSite.id}`)
    }

    function handlingSuppressSite(): void {
        //TODO LORS DE LA SUPRESSION METTRE L'ADRESSE DANS UNE TABLE OLD ADRESS ET MODIFIER LES RENDEZ-VOUS AFFECTES EN PHP + CHERCHER SI L'ADRESSE EXISTE DEJA COMME CA ON PEUT AMOINDRIR LE NB DE DONNEES EN BASE
    }

    async function requestToChangePwd(): Promise<void> {
        try {
            await checkOldPwd();
            setChangePwd(true);
        } catch (error: any) {
            const exception: ApiException = error as ApiException;
            flash(3000, "warning", exception.getInfo());
        }
    }

    async function checkOldPwd(): Promise<void> {
        await fetchAPI.connexion(auth.username, pwd);
    }


    async function updatePwd(): Promise<void> {
        try {
            await updateUser();
            flash(3000, "success", "Le mot de passe à bien été modifié.");
            cancelChangePwd();
        } catch (error: any) {
            console.log(error);
            flash(3000, "danger", "Une erreur est survenue");
        }
    }

    async function updateUser(): Promise<void> {
        await fetchAPI.updateUser(auth.identifiant, null, null, null, newPwd, null, auth.jwt, null);
    }


    function checkValidPwds(): boolean {
        return pwdsNotEmpty() && pwdsAreTheSame() && pwdsRespectRegex();
    }

    function pwdsNotEmpty(): boolean {
        return newPwd.length !== 0 && newPwdConfirm.length !== 0;
    }

    function pwdsAreTheSame(): boolean {
        return newPwd === newPwdConfirm;
    }

    function pwdsRespectRegex(): boolean {
        const regexPwd: RegExp = new RegExp(PatternPassword);

        return regexPwd.test(newPwd);
    }

    function cancelChangePwd(): void {
        setChangePwd(false);
        setPwd('');
        setNewPwd('');
        setNewPwdConfirm('');
    }

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <div className="customCard">
                    <Row style={{display: "flex", justifyContent: "center"}}>
                        <Col md={6}>
                            <h2 className="user-select-none" style={{textAlign: "center"}}>Mes coordonnées</h2>
                            <CustomForm inputs={inputs} titleButtonSend="Enregistrer"></CustomForm>
                        </Col>
                    </Row>
                </div>

                {isClient &&
                    <div className="customCard">
                        <Row style={{display: "flex", justifyContent: "center"}}>
                            <Col md={6}>
                                <h2 className="user-select-none" style={{textAlign: "center"}}>Mes adresses</h2>
                                <CustomTable<InterventionSite> headers={headers} keys={keys} keyForKeyAttribute="id"
                                                               objects={interventionSites} actions={actions}
                                                               handlerClick={handlerAction}></CustomTable>
                            </Col>
                        </Row>
                    </div>
                }

                <div className="customCard">
                    <Row style={{display: "flex", justifyContent: "center"}}>
                        <Col md={6}>
                            <h2 className="user-select-none" style={{textAlign: "center"}}>Mot de passe</h2>
                        </Col>
                    </Row>
                    <Row>
                        {changePwd ?
                            <Col>
                                <p>
                                    Veuillez saisir le nouveau mot de passe que vous souhaitez
                                </p>
                                <div className="input-group mb-3" style={{width: "fit-content"}}>
                                    <input
                                        type={isVisible ? "text" : "password"}
                                        className="form-control rounded"
                                        placeholder="Nouveau mot de passe"
                                        aria-label="Mot de passe"
                                        aria-describedby="search-addon"
                                        value={newPwd}
                                        onChange={(e) => setNewPwd(e.target.value)}
                                    />
                                    <span onClick={() => setIsVisible(!isVisible)} className="input-group-text border-0"
                                          id="search-addon"><i className="fas fa-eye"></i></span>
                                </div>

                                <div className="input-group mb-3" style={{width: "fit-content"}}>
                                    <input
                                        type={isVisible ? "text" : "password"}
                                        className="form-control rounded"
                                        placeholder="Confirmation nouveau mot de passe"
                                        aria-label="Mot de passe"
                                        aria-describedby="search-addon"
                                        value={newPwdConfirm}
                                        onChange={(e) => setNewPwdConfirm(e.target.value)}
                                    />
                                    <span onClick={() => setIsVisible(!isVisible)} className="input-group-text border-0"
                                          id="search-addon"><i className="fas fa-eye"></i></span>
                                </div>
                                <Button style={{marginRight: '20px'}} onClick={updatePwd} disabled={!checkValidPwds()} variant="dark">
                                    Modifier
                                </Button>
                                <Button onClick={cancelChangePwd} variant="danger">
                                    Annuler
                                </Button>
                            </Col>
                            :
                            <Col>
                                <p>
                                    Pour modifier votre mot de passe, veuillez saisir votre mot de passe actuel pour
                                    confirmer votre identité.
                                </p>
                                <div className="input-group mb-3" style={{width: "fit-content"}}>
                                    <input
                                        type={isVisible ? "text" : "password"}
                                        className="form-control rounded"
                                        placeholder="Mot de passe"
                                        aria-label="Mot de passe"
                                        aria-describedby="search-addon"
                                        value={pwd}
                                        onChange={(e) => setPwd(e.target.value)}
                                    />
                                    <span onClick={() => setIsVisible(!isVisible)} className="input-group-text border-0"
                                          id="search-addon"><i className="fas fa-eye"></i></span>
                                </div>
                                <Button onClick={requestToChangePwd} disabled={pwd.length === 0} variant="dark">
                                    Modifier
                                </Button>
                            </Col>
                        }

                    </Row>
                </div>


                <ModalConfirmation fonctionBtnAccept={handlingSuppressSite}
                                   fonctionBtnRefuse={() => setShowModal(false)} show={showModal}
                                   titre="Confirmation supression adresse"
                                   body={
                                       <>
                                           <p>
                                               Voulez vous réelement suprimer cette adresse ?
                                           </p>
                                           <p style={{fontWeight: "bold"}}>
                                               {targetSite && targetSite.locationSiteIntervention}
                                           </p>
                                       </>

                                   }>
                </ModalConfirmation>
            </PersistGate>
        </Provider>
    );
}

