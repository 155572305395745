import * as React from "react";
import {InterventionSite, Service} from "../../types/type";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Card} from "react-bootstrap";

/**
 * @description Component that allows you to go backwards through the various stages of the appointment booking process
 * @param isMobile
 * @param service
 * @param interventionSite
 * @param onCancelService
 * @param onCancelInterventionSite
 * @constructor
 */
export default function BreadcrumbRdv(
    {
        service,
        interventionSite,
        onCancelService,
        onCancelInterventionSite,
    }: {
        service?: Service,
        interventionSite?: InterventionSite,
        onCancelService: () => void,
        onCancelInterventionSite: () => void
    }
): React.ReactElement {

    return (
        <div className="customCard">
            <Row className="rowBreadcrumb">
                <Col md={4}>
                    <Card className="cardBreadcrumb">
                        <Card.Body>
                            Prise de rendez-vous
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    {service &&
                        <Card className="cardBreadcrumb">
                            <Card.Body>
                                <Row>
                                    <Col md={8}>
                                        <Card.Title>{service.name}</Card.Title>
                                        <Card.Text>
                                            {service.description}
                                        </Card.Text>
                                    </Col>
                                    {!interventionSite &&
                                        <Col md={4} className="colBreadCrumbBtn">
                                            <div className="btn btn-dark" onClick={onCancelService}>Annuler</div>
                                        </Col>
                                    }

                                </Row>
                            </Card.Body>
                        </Card>
                    }
                </Col>
                <Col md={4}>
                    {interventionSite &&
                        <Card className="cardBreadcrumb">
                            <Card.Body>
                                <Row>
                                    <Col md={8}>
                                        <Card.Title>{interventionSite.locationSiteIntervention}</Card.Title>
                                    </Col>
                                    <Col md={4} className="colBreadCrumbBtn">
                                        <div className="btn btn-dark" onClick={onCancelInterventionSite}>Annuler</div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    }
                </Col>
            </Row>
        </div>
    )
}
