export default class ApiException extends Error {
    private readonly code: number;
    constructor(message: string, code: number) {
        super(message);
        this.name = "API ERROR";
        this.code = code;
    }

    getInfo(): string | null {
        return `Erreur ${this.code} | ${this.message}`;
    }
}

