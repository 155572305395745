import {Button} from "react-bootstrap";
import * as React from "react";
import {ButtonEmployeProps} from "../types/propsType";

export default function ButtonEmploye ({dataOnClick, eventName, buttonName, variant}: ButtonEmployeProps): React.ReactElement {
    function launchEvent(): void {
        let event = new CustomEvent(eventName, {detail: dataOnClick});
        document.dispatchEvent(event);
    }

    return (
        <Button className="mx-3" variant={variant} onClick={launchEvent}>{buttonName}</Button>
    );
}
