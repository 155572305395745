import * as React from 'react';
import {Provider} from "react-redux";
import {persistor, store} from "../store";
import OnlineDetect from "../OnlineDetection/OnlineDetect";
import {PersistGate} from "redux-persist/integration/react";
import Planning from "../Components/RDV/Planning";
import ChoixRDV from "../Components/RDV/ChoixRDV";
import {fetchAPI} from "../Components/API";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {useLoaderData} from "react-router-dom";
import {Auth, InterventionSite, MaxMonthInterval, Schedule, Service} from "../types/type";
import {DataServices} from "../types/loaderType";
import {useMediaQuery} from "react-responsive";
import BreadcrumbRdv from "../Components/RDV/BreadcrumbRdv";


const stripePromise = await loadStripe('pk_test_51OWy1DGraefg4D4JxKFOdVm0uXGx0yP6jgynndZJ1eexWYt42W9ExVLpP75iJkiyMyYuehAk8UYJKvg7oY9n3fCN00eu7F5rd0');

export default function RDV(): React.ReactElement {

    const [service, setService] = React.useState<Service>();
    const [interventionSite, setInterventionSite] = React.useState<InterventionSite>();


    const [clientSecret, setClientSecret] = React.useState<string>();

    const isMobile: boolean = useMediaQuery({query: `(max-width: 700px)`});

    const authObject: Auth = fetchAPI.authObject;

    const dataLoader: DataServices = useLoaderData() as DataServices;

    const services: Service[] = dataLoader.services;
    const sites: InterventionSite[] = dataLoader.sites;
    const schedules: Schedule[] = dataLoader.schedules;
    const maxMonthInterval: MaxMonthInterval = dataLoader.maxMonthInterval;

    const option = React.useMemo(() => ({
        clientSecret
    }), [clientSecret]);


    React.useEffect(() => {

        function onRdvConfirm() {
            setService(undefined);
            setInterventionSite(undefined);
        }

        document.addEventListener("onRdvConfirm", onRdvConfirm);

        return () => {
            document.removeEventListener("onRdvConfirm", onRdvConfirm);
        }
    }, []);

    async function onSiteChoice(interventionSite: InterventionSite): Promise<void> {
        const response = await fetchAPI.createPaymentIntent(5000, fetchAPI.authObject.jwt);

        setClientSecret(response.client_secret);
        setInterventionSite(interventionSite);
    }

    function onServiceChoice(service: Service): void {
        setService(service);
    }

    function onCancelService(): void {
        setService(undefined);
    }

    function onCancelInterventionSite(): void {
        setInterventionSite(undefined);
    }

    return (
        <>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BreadcrumbRdv onCancelInterventionSite={onCancelInterventionSite} onCancelService={onCancelService}
                                   interventionSite={interventionSite} service={service}>
                    </BreadcrumbRdv>
                    <div className="customCard">
                        {interventionSite ?
                            clientSecret &&
                            <Elements stripe={stripePromise} options={option}>
                                <Planning isMobile={isMobile} maxMonthInterval={maxMonthInterval.nbMonths}
                                          jwt={authObject.jwt}
                                          durationRdv={service.duration} idSite={interventionSite.id}
                                          idService={service.idService}></Planning>
                            </Elements>
                            :
                            <ChoixRDV interventionSiteChoice={interventionSite} serviceChoice={service}
                                      onServiceChoice={onServiceChoice} onSiteChoice={onSiteChoice} isMobile={isMobile}
                                      schedules={schedules} sites={sites} services={services}></ChoixRDV>
                        }
                    </div>

                    <OnlineDetect></OnlineDetect>
                </PersistGate>
            </Provider>

        </>
    );
}
