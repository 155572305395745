import {redirect} from "react-router-dom";
import {flash} from "react-universal-flash";
import {fetchAPI} from "../../../Components/API";

export default async function actionsInterventionSite ({request}) {

    const formData = await request.formData();
    const authObject = fetchAPI.authObject;


    const data = {
        nom: formData.get("nom"),
        idSite: Number(formData.get("idSite")),
        cityName: formData.get("cityName"),
        coordinates: formData.get("coordinates"),
        action: formData.get("action"),
        authObject: authObject
    }

    console.log(data);

    let success = await verifInterventionSite(data);


    return success ? redirect("/siteIntervention") : null;
}


async function verifInterventionSite(data) {

    let success = false;

    if (data.nom.length === 0) {
        flash(5000, "warning", "Le nom doit être renseigné.");
    } else if (data.cityName.length === 0) {
        flash(5000, "warning", "La location doit être renseignée.");
    } else if (data.coordinates.length === 0 || data.coordinates === [0,0]) {
        flash(5000, "warning", "La location doit être renseignée.");
    } else {

        const coordinates = data.coordinates.split(",");


        let response;

        switch (data.action) {
            case "create":
                response = await fetchAPI.creerSiteIntervention(data.nom, data.cityName,Number(coordinates[0]), Number(coordinates[1]), data.authObject.jwt);
                break;
            case "update":
                response = await fetchAPI.updateInterventionSite(data.idSite, data.nom, data.cityName, Number(coordinates[0]), Number(coordinates[1]), data.authObject.jwt);
                break;
        }


        if (response.status === 422) {
            flash(5000, "danger", response.detail);
        } else {
            flash(5000, "success", "Le site d'intervention à été enregistré avec succès !");
            success = true;
        }
    }

    return success;
}
