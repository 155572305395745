import {
    Adress,
    Appointment, Auth,
    ChatMessage,
    InterventionSite, Notification, Picture,
    Schedule,
    Service,
    Signature, Ticket, Time,
    User,
    UserGestion
} from "../types/type";
import {start} from "node:repl";
import {fetchAPI} from "../Components/API";

/**
 * Changes the information contained in objects representing appointments
 * @param rdvs Array containing appointment information retrieved by the API request
 * @return array
 */
function formalizeRdvs(rdvs: any[]): Appointment[] {
    return rdvs.map(objet => formalizeOneRdv(objet));
}

function formalizeServices(services: any[]): Service[] {
    return services.map(objet => formalizeOneService(objet));
}

function formalizeUsers(users: any[]): User[] {
    return users.map(objet => formalizeOneUser(objet));
}

function formalizeChatMessages(chatMessages: any[]): ChatMessage[] {
    return chatMessages.map(object => formalizeOneChatMessage(object));
}

function formalizeInterventionSites(interventionSites: any[]): InterventionSite[] {
    return interventionSites.map(object => formalizeOneInterventionSite(object))
}

function formalizeNotifications(notifications: any[]): Notification[] {
    return notifications.map(object => formalizeOneNotification(object));
}

function formalizeOneNotification(notification: any): Notification {
    return {
        id: notification.id,
        idRdv: Number(notification.appointment.id),
        idUser: Number(notification.user.id),
        nbNotif: notification.nbNotif
    }
}


function formalizeOneInterventionSite(object: any): InterventionSite {
    return {
        id: object.id,
        clientId: object.clientPro.id,
        locationSiteIntervention: object.locationSiteIntervention,
        nomSiteIntervention: object.nomSiteIntervention,
        longitude: object.longitude,
        latitude: object.latitude
    }
}


function formalizeOneService(objet: any): Service {
    const durationHour: number = Math.floor(objet.duration);

    const durationMinute: number = 60 * (objet.duration - durationHour);

    let durationString: string = `${durationHour}h`;

    if (durationMinute !== 0) durationString += durationMinute;

    return {
        idService: objet.id,
        name: objet.name,
        duration: objet.duration,
        description: objet.description,
        price: objet.price,
        status: objet.status,
        durationString: durationString
    }
}

function formalizeOnePicture(objet: any): Picture {
    return {
        id: objet.id,
        imagePath: objet.contentUrl
    }
}

function formalizeOneRdv(objet: any, fromTicket: boolean = false): Appointment {
    return {
        idRdv: objet.id,
        sujet: objet.sujet,
        date: new Date(objet.date),
        horaires: objet.horaires,
        client: objet.client,
        messages: fromTicket ? undefined : formalizeChatMessages(objet.messages),
        horaireDepart: objet.horaires[0],
        horaireFin: Number(objet.horaires[objet.horaires.length - 1]) + 1,
        siteIntervention: objet.siteIntervention,
        signature: formalizeOneSignature(objet.signature),
        chef: objet.client.chef,
        ticket: objet.ticket ? formalizeOneTicket(objet.ticket) : undefined,
        service: objet.service ? formalizeOneService(objet.service) : undefined,
        employe: objet.employe,
        billNumber: objet.billNumber,
        customerPaid: objet.customerPaid
    }
}


function formalizeOneChatMessage(object: any): ChatMessage {
    return {
        id: object.id,
        appointment: {
            id: object.rdv.id
        },
        message: object.message,
        horaire: object.horaire,
        date: new Date(object.date),
        imagePath: object.contentUrl,
        client: object.client
    }
}

function formalizeOneSignature(objet: any): Signature {
    if (!objet) return objet;
    if (!objet.time) return objet;
    const splitedTime = objet.time.split(":");
    return {
        id: objet.id,
        pdf: objet.pdf ? formalizeOnePicture(objet.pdf) : undefined,
        nbHours: objet.nbHours,
        description: objet.description,
        timeString: objet.time,
        hours: Number(splitedTime[0]),
        minute: Number(splitedTime[1]),
        dateSignature: new Date(objet.dateSignature),
        latitude: objet.latitude,
        longitude: objet.longitude,
        nameSignature: objet.nomSignature,
        signatureClient: formalizeOnePicture(objet.signatureClient),
        signatureTechnicien: formalizeOnePicture(objet.signatureTechnicien),
        pictures: objet.pictures ? formalizePictures(objet.pictures) : undefined
    }
}

function formalizePictures(objets: any[]): Picture[] {
    return objets.map(objet => formalizeOnePicture(objet));
}


function formalizeOneUser(objet: any): User {
    return {
        id: objet.id,
        firstName: objet.prenom,
        lastName: objet.nom,
        login: objet.login,
        roles: objet.roles,
        boss: objet.chef,
        isPro: objet.roles.includes("ROLE_CLIENTPRO"),
        isEmploye: objet.roles.includes("ROLE_EMPLOYE"),
        external: objet.external
    }
}

function formalizeAdressFromApi(adress: any[]): Adress[] {
    return adress.map((objet) => ({
        cityName: objet.properties.label,
        coordinates: objet.geometry.coordinates
    }));
}

/**
 * Sorts a table of appointments from most recent to oldest
 * @param rdvs Array containing appointment information from the formalizedRdvs function
 */
function sortRdvs(rdvs: Appointment[]) {
    rdvs.sort(function (a, b) {
        return b.date.getTime() - a.date.getTime();
    });
}

function sortTimeTable(timeTable: number[]): void {
    timeTable.sort(function (a, b) {
        return a-b
    });
}


function filterRdvPostToday(rdvs: Appointment[]) {
    return rdvs.filter(objet => {
        objet.date.setHours(objet.horaireFin);
        if (objet.date >= new Date()) return objet;
    });
}

function filterRdvPastToday(rdvs: Appointment[]) {
    return rdvs.filter(objet => {
        objet.date.setHours(objet.horaireFin);
        if (objet.date <= new Date()) return objet;
    });
}

function getNumberOfPages(arrayOfObject: any[], maxNumberOfObjectByPage: number) {
    const restOfDivision = arrayOfObject.length % maxNumberOfObjectByPage;

    const numberPagination = Math.trunc(arrayOfObject.length / maxNumberOfObjectByPage);

    return restOfDivision !== 0 ? numberPagination + 1 : numberPagination;
}

function compareServices(a: Service, b: Service): boolean {
    return a.duration === b.duration &&
        a.price === b.price &&
        a.description === b.description &&
        a.name === b.name;
}

function getEmptyService(): Service {
    return {
        idService: -1,
        name: "",
        duration: 0,
        durationString: "",
        description: "",
        price: 0,
        status: false
    }
}

function getEmptyUserGestion(): UserGestion {
    return {
        id: -1,
        lastName: "",
        firstName: "",
        boss: undefined,
        isPro: false,
        isEmploye: false,
        login: "",
        pwd: "",
        pwdConfirm: "",
        external: false
    }
}

function getEmptyTicket(): Ticket {
    return {
        id: -1,
        name: "",
        description: "",
        client: undefined,
        appointment: undefined,
        date: new Date(),
        view: false,
        adress: undefined,
        reject: false,
        reasonForReject: ""
    }
}

function getEmptySignature(): Signature {
    return {
        id: -1,
        nbHours: 0,
        description: "",
        timeString: "",
        hours: -1,
        minute: -1,
        dateSignature: new Date(),
        latitude: -1,
        longitude: -1,
        nameSignature: "",
        signatureTechnicien: undefined,
        signatureClient: undefined,
        pictures: undefined,
        pdf: undefined
    }
}

function getEmptyAppointment(): Appointment {
    return {
        idRdv: -1,
        sujet: undefined,
        date: undefined,
        horaires: undefined,
        client: undefined,
        messages: undefined,
        horaireDepart: undefined,
        horaireFin: undefined,
        siteIntervention: undefined,
        signature: undefined,
        chef: undefined,
        employe: undefined,
        billNumber: undefined,
        customerPaid: undefined
    }
}

/**
 * @description transforms a schedule information table with api format into a schedule table with application format
 * @param objects
 */
function formalizeSchedules(objects: any): Schedule[] {
    return objects.map((object: any) => formalizeOneSchedule(object));
}

function formalizeTickets(objects: any): Ticket[] {
    return objects.map((object: any) => formalizeOneTicket((object)));
}

/**
 * @description Creates a schedule with the application's specified format from the api's schedule format
 * @param object
 */
function formalizeOneSchedule(object: any): Schedule {
    const timeTable: number[] = object.timeTable;

    let startHour: number,
        endHour: number;

    if (timeTable.length !== 0) {
        startHour = timeTable[0];
        endHour = timeTable[timeTable.length - 1];
    } else {
        startHour = -1;
        endHour = -1;
    }

    return {
        id: object.id,
        date: new Date(object.date),
        start: startHour,
        end: endHour,
        timeTable: timeTable,
    }
}

function formalizeOneTicket(object: any): Ticket {
    return {
        id: object.id,
        name: object.name,
        description: object.description,
        appointment: object.appointment ? formalizeOneRdv(object.appointment, true) : undefined,
        client: formalizeOneUser(object.client),
        date: new Date(object.date),
        adress: formalizeOneInterventionSite(object.adress),
        view: object.view,
        reject: object.reject,
        reasonForReject: object.reasonForReject
    }
}

function getSegmentedTimeFromHour(hour: number): Time {
    const hourPart = Math.trunc(hour);
    return {
        hour: hourPart,
        minute: 60*(hour - hourPart)
    }
}

/**
 * @description Retrieves a character string corresponding to the time passed in parameter for display
 * @param time
 * @return A character string representing time
 */
function formatDecimalTimeToDisplay(time: number): string {
    const segmentedTime: Time = getSegmentedTimeFromHour(time);

    if (segmentedTime.hour === 0) {
        return `${formatTimeToDisplay(segmentedTime.minute)}m`;
    } else {
        if (segmentedTime.minute === 0) return `${segmentedTime.hour}h`;
        else return `${segmentedTime.hour}h${formatTimeToDisplay(segmentedTime.minute)}m`;
    }
}

/**
 * @description Retrieves a character string corresponding to the time passed in parameter for display
 * @param time
 * @return A character string representing time
 */
function formatTimeToDisplay(time: number): string {
    const timeString = time.toString();
    return timeString.length === 1 ? "0" + timeString : timeString;
}

/**
 * @description Transforms a Date object into a character string
 * @param date
 * @return a character string formatted to be understood by the api
 */
function formatDateToFetchApi(date: Date): string {
    return date.toISOString().slice(0, 10);
}

/**
 * @description Find the date of Monday of the current week
 * @return The current Monday's date
 */
function getMondayOfTheCurrentWeek(): Date {
    const date: Date = new Date();

    const day: number = date.getDay();

    const diff: number = date.getDate() - day + (day === 0 ? -6 : 1);

    return new Date(date.setDate(diff));
}

/**
 * @description Recover the day written in letters in French
 * @param date
 * @param firstLetterUppercase
 * @return a string representing the name of the day in French
 */
function getDayNameFromDate(date: Date, firstLetterUppercase: boolean = false): string {
    const day: string = date.toLocaleDateString('fr-Fr', {weekday: 'long'});
    return firstLetterUppercase ? day.charAt(0).toUpperCase() + day.slice(1) : day;
}

/**
 * @description Recover the month written in letters in French
 * @param date
 * @param firstLetterUppercase
 * @return a string representing the name of the month in French
 */
function getMonthNameFromDate(date: Date, firstLetterUppercase: boolean = false): string {
    const month: string = date.toLocaleDateString('fr-Fr', {month: 'long'});
    return firstLetterUppercase ? month.charAt(0).toUpperCase() + month.slice(1) : month;
}

/**
 * @description Create a random string that can be used as an identifier
 * @return A random character string
 */
function getRandomId(): string {
    return "id" + Math.random().toString(16).slice(2);
}

/**
 * @description Modify a string to capitalize the first letter
 * @param string
 */
function capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * @description Retrieves the number of notifications for a particular appointment
 * @param idRdv the identifier of the appointment whose notifications you want to retrieve
 * @return the number of notifications
 */
function getNbNotif(idRdv: number): number {
    const notif: Notification[] = fetchAPI.authObject.notifications.filter((notification) => notification.idRdv === idRdv);
    if (notif.length > 0) return notif[0].nbNotif;
    else return 0;
}


/**
 * @description Deletes notifications stored in the database for all appointments with the specified identifier
 * @param idRdvs An appointment identifier table
 */
async function eraseStaticNotifications(idRdvs: string[]): Promise<void> {
    const auth: Auth = fetchAPI.authObject;

    for (const idRdv of idRdvs) {
        console.log(idRdv);
        await fetchAPI.delete.notification(idRdv + '-' + auth.identifiant, auth.jwt);
    }
}





export {
    eraseStaticNotifications,
    formalizeOneSignature,
    formalizeOnePicture,
    getNbNotif,
    formalizeNotifications,
    formalizeOneNotification,
    formatDecimalTimeToDisplay,
    formalizeOneInterventionSite,
    getMonthNameFromDate,
    capitalizeFirstLetter,
    formalizeOneSchedule,
    getRandomId,
    getDayNameFromDate,
    getMondayOfTheCurrentWeek,
    formatDateToFetchApi,
    sortTimeTable,
    getSegmentedTimeFromHour,
    getEmptyUserGestion,
    formalizeUsers,
    compareServices,
    formalizeRdvs,
    sortRdvs,
    filterRdvPostToday,
    filterRdvPastToday,
    formalizeServices,
    formalizeAdressFromApi,
    getNumberOfPages,
    formalizeOneRdv,
    formalizeOneService,
    getEmptyService,
    formalizeChatMessages,
    formalizeInterventionSites,
    formalizeOneUser,
    formalizeSchedules,
    formatTimeToDisplay,
    getEmptySignature,
    formalizeTickets,
    formalizeOneTicket,
    getEmptyTicket,
    getEmptyAppointment
}
